import React, { useEffect, useState } from 'react';
import { Text } from 'components';
import { Header } from 'components';
import bookingTransactionService from '../../services/bookingTransactionService';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ListIcon from '@mui/icons-material/List';
import { toggleButton } from '../../utils/utils';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { AccountCircle } from "@material-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import ProfessionalDetailsComponent from '../../components/professionalDetailsComponent';
import clientAction from "../../store/actions/clientAction";
import {InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import NewSales from './newSales';
import { isMobile } from "utils/isMobile";
import './style/index.scss';
import moment from 'moment/moment';
import { useConfig } from '../../context/config.context';
import { getPaymentList, getPaymentListGroupedByPaymentType, postPaymentList } from '../../services/paymentService';
import _ from 'lodash';
import PaymentTypeChip from '../../components/paymentTypeChip';

const SalesHistory = () => {
  const { clients } = useSelector((store) => store.clients);
  const { business } = useSelector((store) => store.business);
  const dispatch = useDispatch();

  const [clientData, setClientData] = useState({
    loading: false,
    clientList: [],
    isFilterOn: false,
    clientSelected: null,
  });

  const [clientText, setClientText] = useState('');
  const [bookingList, setBookingList] = useState([]);
  const [bookingListCompleted, setBookingListCompleted] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [lookForClient, setlookForClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [count, setCount] = useState();
  const [toBeCharged, setToBeCharged] = useState(true);
  const [dateData, setDateData] = useState({ startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')});
  const [salesHistoryData, setSalesHistoryData] = useState({ data: [], loading: false, paymentData: [], dataSelected: [] });
  const [searchClients, setSearchClients] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { handleFeedback } = useConfig();

  useEffect(() => {
		if (business) {
			dispatch(clientAction.getClients(business?.id, "myProfessionalId"));
		}
	}, [business?.id]);

  useEffect(()=> {
    fetchAllBookingsTransactionToBeCharged();
  },[]);
  
  const fetchAllBookingsTransactionToBeCharged = async () => {
    const clientCondition = (i) => {
      if (i) {
        return [i.id];
      } else if (clientData.clientSelected) {
        return [clientData.clientSelected];
      } else {
        return null;
      }
    };

    try {
      setLoadingList(true);
      const response = await bookingTransactionService.getAllBookingTransactionList({
        params: {
          business_id: business?.id,
          page_index: currentPage,
          payment_status: 'pending,partial,refuse,requested',
          'status[]': 'active',
          client_id: clientCondition(),
          search: clientText,
        },
      });

      if (response.status === 200) {
        setBookingList(response.data);
        setCount(response.headers['x-total-pages']);
      }
    } catch (error) {
      console.error('Erro ao buscar transações:', error);
    } finally {
      setLoadingList(false);
    }
  };

  const fetchPaymentsGroups = async (startDate, endDate) => {
    try {
      setLoadingList(true);
      setSalesHistoryData({ data: [], loading: true, paymentData: [], dataSelected: [] });
      const response = await getPaymentListGroupedByPaymentType(
        business.id, moment.utc(startDate).format('YYYY-MM-DD'), moment.utc(endDate).format('YYYY-MM-DD'),
      );
      let paymentList = [];
      if (!_.isEmpty(response.data) && !_.isNil(response.data)) {
        paymentList = response.data.map(element => ({
          paymentType: element.payment_type,
          dataAux: element.booking_transactions,
          data: [],
          showIn: true,
          showItens: false,
          totalPages: Math.round(element.booking_transactions.length / 20),
          currentPage: 1,
          totalValueByPaymentType: element.total_value,
          totalByPaymentType: element.total_payments,
        }));
      }
      setSalesHistoryData({ ...salesHistoryData, data: paymentList, loading: false, paymentData: [] });
    } catch (err) {
      setSalesHistoryData({ ...salesHistoryData, ...salesHistoryData, loading: false });
      handleFeedback('Erro', 'Erro na requisição', 'error');
    } finally {
      setLoadingList(false);
    }
  };

  // const insertPaymentOnBookingTransactions = async (salesHistoryListAux, paymentType) => {
  //   const paymentTypeSelected = _.find(salesHistoryListAux, sale => sale.paymentType === paymentType);
  //   const { currentPage } = paymentTypeSelected;
  //   const paymentSelectedArray = paymentTypeSelected.data.slice(currentPage * 20 - 20, 20 * currentPage);

  //   if (!_.isEmpty(paymentSelectedArray)) {
  //     const bookingTransactionsId = [];

  //     paymentSelectedArray.forEach((item) => {
  //       if (item.bookings.length > 0) { bookingTransactionsId.push(item.id); }
  //     });

  //     try {
  //       setSalesHistoryData({ ...salesHistoryData, loading: true });
  //       const paymentListData =  await getPaymentList(bookingTransactionsId);
  //       const discountData = await postPaymentList(business.id, bookingTransactionsId);

  //       paymentListData.data.forEach((item) => {
  //         const booking = _.find(
  //           paymentTypeSelected.data,
  //           bookingTransactionData => bookingTransactionData.id === item.booking_transaction_id,
  //         );
  //         if (!_.isNil(booking)) {
  //           booking.payment = item;
  //         }
  //       });

  //       setSalesHistoryData({ ...salesHistoryData, data: salesHistoryListAux, paymentData: [...salesHistoryData.paymentData, ...discountData.data], loading: false});
  //     } catch (err) {
  //       setSalesHistoryData({ ...salesHistoryData, loading: false });
  //       handleFeedback('Error', 'Ocorreu um erro inexperado', 'error');
  //     }
  //   }
  // };

  const onChangeDateStart = (day) => {
    const formattedValue = moment(day).format('YYYY-MM-DD');
    setDateData((prev) => {
      const newData = { ...prev, startDate: formattedValue };
      fetchPaymentsGroups(newData.startDate, newData.endDate);
      return newData;
    });
  };
  
  const onChangeDateEnd = (day) => {
    const formattedValue = moment(day).format('YYYY-MM-DD');
    setDateData((prev) => {
      const newData = { ...prev, endDate: formattedValue };
      fetchPaymentsGroups(newData.startDate, newData.endDate);
      return newData;
    });
  };

  const handleCloseNewSale = () => {
    setSelectedBooking(null);
  };

  const fetchAllBookingsCompleted = async ()=> {
    const clientCondition = (i) => {
      if (i) {
        return [i.id];
      } else if (clientData.clientSelected) {
        return [clientData.clientSelected];
      } else {
        return null;
      }
    };

    try {
      setLoadingList(true);
      const response = await bookingTransactionService.getAllBookingTransactionList({
        params: {
          business_id: business?.id,
          page_index: currentPage,
          payment_status: 'completed',
          'status[]': 'active',
          client_id: clientCondition(),
          search: clientText,
        },
      });

      if (response.status === 200) {
        setBookingListCompleted(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        if (currentPage > 1) {
          const newPage = currentPage - 1;
          setCount(newPage);
          setCurrentPage(newPage);
        } else {
          console.error('Nenhuma página válida disponível.');
        }
      } else {
        console.error('Erro ao buscar transações:', error);
      }
    } finally {
      setLoadingList(false);
      handleCloseNewSale();
    }
  };

  const CLIENTS_PER_PAGE = 10;
	const SCROLL_BOTTOM_OFFSET = 100;

  const bookingLenght = () => {
    return (
      <div className='textContainer'>
        <Text variant={'title'} style={{color: "#000957"}}>
          Torne o pagamento de seus clientes infalível.
        </Text>
        <br/>
        <Text style={{color:"#686D76"}}>
          Gerencie suas vendas, visualize o que precisa ser cobrado, 
          acesse o histórico de vendas, obtenha relatórios detalhados 
          sobre cada transação ou período específico com o Prit.
        </Text>
      </div>
    );
  };

  useEffect(() => {
    if (business) {
      if (!toBeCharged) {
        fetchPaymentsGroups(dateData.startDate, dateData.endDate);
      } else {
        fetchAllBookingsTransactionToBeCharged();
      }
    }
  }, [toBeCharged, currentPage]);

  return (
    <>
      <Header title="Histórico de Venda"/>
      <div className='containerDad'>
        <section className='firstContainer'>
        {isMobile() && (selectedBooking || lookForClient) ? null : (
          <header className='headerContainerSalesHistory'>
            <div className = {toBeCharged ? 'btn-actv' : 'btn'} onClick={()=>{toggleButton(setToBeCharged);}}>
              <p>Para ser Cobrado</p>
            </div>
            <div className = {toBeCharged ? 'btn' : 'btn-actv'} onClick={()=>{toggleButton(setToBeCharged);}}>
              <p>Historico de Vendas</p>
            </div>
          </header>
        )}
        {isMobile() && selectedBooking ? null  : (
          <div className={toBeCharged ? 'searchContainer' : 'none'}>
            <TextField
              variant="outlined"
              onClick={()=>setlookForClient(true)}
              onChange={(e) => setSearchClients(e.target.value)}
              placeholder="Buscar clientes"
              InputProps={{
              endAdornment: (
							<InputAdornment position="end">
								<Search style={{ cursor: "pointer" }} />
							</InputAdornment>
              ),
              }}
              style={{ width: "100%" }}
            />
            
            {/* Não vai ser utilizado no momento */}
            {/* <div className='circleIcon' onClick={()=>{toggleButton(setClient);}}>
              {client ? (<ListIcon style={{color: "#F5F5F5"}}/>) : (<PermIdentityIcon style={{color: "#F5F5F5"}}/>)}
            </div> */}
          </div>

        )}

        {!toBeCharged && (
          <div className='intervalContainer'>
            <div className='intervalContainerSelect'>
              <Text variant={'mid'} style={{ textAlign: 'center', color: "#091057"}}>Selecione o intervalo de tempo</Text>
                <div style={{display: 'flex',flexDirection: 'column', gap:'0.5rem'}}>
                  <div className="inputTimeContainer">
                    <TextField
                      className="inputDateHours"
                      variant="outlined"
                      value={dateData.startDate}
                      onChange={(e) => { onChangeDateStart(e.target.value); }}
                      type="date"
                    />
                  </div>
                  <div className="inputTimeContainer">
                    <TextField
                      className="inputDateHours"
                      variant="outlined"
                      value={dateData.endDate}
                      onChange={(e) => { onChangeDateEnd(e.target.value); }}
                      type="date"
                    />
                  </div>
                </div>
            </div>
          </div>
        )}

          <div className='itemContainer'>
            { loadingList ? (

              <div className="loading">
                <AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
              </div>

            ) : lookForClient ? (

              <div>
                {isMobile() && selectedBooking ? null : (
                <>
                  <div className='closeContainer'>
                    <ArrowBackIosIcon style={{color:"#3C3D37", cursor: "pointer"}} onClick={()=>setlookForClient(false)}/>
                  </div>
                <div className='lookForClientContainer'>
                  {clients
                  .filter((client) => client?.name?.toLowerCase().includes(searchClients.toLowerCase()))
                  .map((client) => (
                    <div key={client.id} className="clientItem">
                      <div style={{ display: "flex", alignItems: "center" }} onClick={()=>{setSelectedClient(client);}}>
                        <AccountCircle
                          style={{
                            color: "lightgray",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                        <div className="clientDataContainer">
                          <Text>{client?.name || ''}</Text>
                          <Text className="clientDataPhone">{client.phone}</Text>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                  </>
                )}
                
              </div>

            ) : !toBeCharged ? (
              <section >
                <div className='containerInfoListPayment'>
                  { salesHistoryData.data.length > 0 ? (
                    <div className='listPaymentContainer'>
                      {salesHistoryData.data.map((item, index)=> (
                        <PaymentTypeChip
                          key={index}
                          filterPaymentType={item}
                        />
                      ))}
                    </div>
                  ) : ( bookingLenght()
                  )}
                </div>
              </section>

            ) : (
              <section>
                { bookingList.length > 0 ? (
                  <div>
                    {isMobile() && selectedBooking ? null : (
                      <>
                        <div className='listContainer'>
                          { bookingList.map((booking, index) => (
                            <ProfessionalDetailsComponent
                              key={booking.id || index}
                              sales={true}
                              bookingList={booking}
                              onClick={() => {
                                setSelectedBooking(booking);
                              }}
                            />
                          ))}
                        </div>
                        <footer className='footerContainerSales'>
                          <Stack spacing={2}>
                            <Pagination
                              count={count}
                              variant='outlined'
                              color="primary"
                              page={currentPage}
                              onChange={(event, page) => setCurrentPage(page)}
                            />
                          </Stack>
                        </footer>
                      </>
                    )}
                  </div>
              ) : bookingLenght()}
            </section>            
          )}
          </div>
        </section>
        <section className='secondContainer'>
          {selectedBooking && <NewSales booking={selectedBooking} setSalesHistory={setToBeCharged} close={()=>{handleCloseNewSale();}}/>}
        </section>
      </div>
    </>
  );
};

export default SalesHistory;
