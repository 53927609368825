import React, { useState } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PixIcon from '@mui/icons-material/Pix';
import Text from '../texts';
import pritPay from "assets/images/prit-pay.png";
import pritPayLogo from "assets/images/prit-white.png";
import { moneyMask } from '../../utils/utils';
import { useSelector } from 'react-redux';
import './index.scss';
import { isMobile } from "utils/isMobile";
import { Dialog } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import CompletedBookingComponent from './completedBooking';
import { getAppName } from "utils/validators";

const PaymentTypeChip = ({ filterPaymentType }) => {
  const { business } = useSelector((store) => store.business);
  const [listAllBookingsByPayment, setListAllBookingsByPayment] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [bTransactioId, setBTransactionId] = useState(null);

  const handleOpenDialog = () => {
    setSelectedPayment(filterPaymentType);
    setListAllBookingsByPayment(true);
  };

  const pluralPhrase = (number) => {
    if ( Number(number) <= 1 ) {
      return 'transação';
    } else {
      return 'transações';
    }
  };

  const isDebit = (paymentType) =>
    paymentType === 'debit' || paymentType === 'debitprit';

  const getPaymentIcon = (paymentType) => {
    if (paymentType === 'credit') return <CreditCardIcon />;
    if (isDebit(paymentType)) return <CreditCardIcon />;
    if (paymentType === 'pixprit') return <PixIcon />;
    if (paymentType === 'cash') return <AttachMoneyIcon />;
    return (
      <div className='containerLogo'>
        <img src={pritPayLogo} style={{ width: '2rem' }} alt="" />
        <img src={pritPay} alt="pritPayLogo" style={{ width: '2rem', marginLeft: '0.5rem' }} />
      </div>
    );
  };

  const getPaymentLabel = (paymentType) => {
    if (paymentType === 'credit') return 'Pagos com crédito';
    if(paymentType === 'creditprit') return `Pagos com ${getAppName(true)} cartão`;
    if (isDebit(paymentType)) return 'Pagos com débito';
    if (paymentType === 'pixprit') return 'Pagos com pix';
    if (paymentType === 'cash') return 'Pagos com dinheiro';
    return `Pagos com ${getAppName(true)}`;
  };

  return (
    <div className='wrapComponentContainer'>
      <div className='infoPaymentTypeContainer'>
        <div className='iconContainer'>
          {getPaymentIcon(filterPaymentType.paymentType)}
        </div>
        <div className='bookingPaymentInfo'>
          <Text variant={isMobile() ? 'body1' : ''}>{getPaymentLabel(filterPaymentType.paymentType)}</Text>
          
          <div className='containerTransactions'>
            <Text variant={isMobile() ? 'body1' : ''}>
              {filterPaymentType.totalByPaymentType}
            </Text>
            <Text variant={isMobile() ? 'body1' : ''}>
              {pluralPhrase(filterPaymentType.total_payments)}
            </Text>
          </div>
          
          <div className='containerTransactions'>
            <Text variant= {'body1' } style={{ color: "#BCCCDC" }}>Total: </Text>
            <Text  variant={'body1'}style={{ color: "#3689ea", fontWeight: "bold" }}>
              {moneyMask(filterPaymentType.totalValueByPaymentType, business?.currency)}
            </Text>
          </div>
        </div>
      </div>

      <div>
        <button style={{marginRight: '6px', backgroundColor: '#3689ea', border: 'none', borderRadius: '1rem', padding: '0.3rem 0.5rem'}}  onClick={handleOpenDialog}>
          <Text variant={ 'body1' } style={{ color: "#fff" }}>Ver todas</Text>
        </button>
      </div>

      <Dialog
        open={listAllBookingsByPayment}
        PaperProps={{
          style: { borderRadius: '1rem', width: 400, padding: "1.5rem" },
        }}
      >
        {selectedPayment && (
          <>
            <header>
              <div className='iconBackContainer'>
                <ArrowBackIos
                  onClick={() => { setListAllBookingsByPayment(false); }}
                  style={{ cursor: 'pointer' }}
                />
                <Text>{getPaymentLabel(selectedPayment.paymentType)}</Text>
              </div>

              <div className='containerSelectedPaymentInfo'>
                <div className='containerTransactions' >
                  <Text>
                    {selectedPayment.totalByPaymentType}
                  </Text>
                  <Text>
                    {selectedPayment.total_payments}{' '}
                    {selectedPayment.total_payments > 1 ? 'transações' : 'transação'}
                  </Text>
                </div>
                <div className='containerTransactions'>
                  <Text style={{ color: "#BCCCDC" }}>Total: </Text>
                  <Text style={{ color: "#3689ea", fontWeight: "bold" }} >
                    {moneyMask(selectedPayment.totalValueByPaymentType, business?.currency)}
                  </Text>
                </div>
              </div>
            </header>
            
            <div className='BookingList'>
              {selectedPayment.dataAux?.map((item, indexItem) =>
                item.bookings?.map((booking, indexBooking) => (
                  <CompletedBookingComponent
                    key={booking.id || `${indexItem}-${indexBooking}`}
                    sales={true}
                    data={booking}
                    completedData={item}
                  />
                ))
              )}
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default PaymentTypeChip;
