import { AccessTimeOutlined, AttachMoneyOutlined, GroupOutlined, HomeWorkOutlined, PersonOutlineOutlined, QuestionAnswerOutlined, RedeemOutlined, TodayOutlined, WorkOutlineOutlined } from "@material-ui/icons";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import InstallMobileOutlinedIcon from "@mui/icons-material/InstallMobileOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import AlignVerticalBottomOutlinedIcon from '@mui/icons-material/AlignVerticalBottomOutlined';
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Text } from "components";
import { isMobile } from "utils/isMobile";
import { getAppName } from "utils/validators";
import { mixpanelTrackEvent } from "utils/mixpanel";

const BusinessContext = createContext();

export function BusinessProvider({ children }) {
	const { me } = useSelector((state) => state.me);
	const { business } = useSelector((store) => store.business);
	const [showCalendar, setShowCalendar] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [sharedDate, setSharedDate] = useState(new Date());

	const [date, setDate] = useState(new Date());
	const [hours, setHours] = useState([]);
	const [now, setNow] = useState(new Date(Date.now()));

	const [pageType, setPageType] = useState("root");
	const [subPage, setSubPage] = useState(null);
	const [openMenu, setOpenMenu] = useState(null);

	const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);
	const [isOpenModalChangeBusiness, setOpenModalChangeBusiness] = useState(false);
	const [isSchedule, setIsSchedule] = useState(pageType === "root");
	const [reservationDates, setReservationDates] = useState([]);
	const [scheduleBreakDates, setScheduleBreakDates] = useState([]);
	const [businessData, setBusinessData] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const handleAppDownload = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	const handleSetDate = (e) => {
		setDate(e);
		setPageType("root");
	};

	const handleMenu = (_page, _subPage = null) => {
		setPageType(_page);
		setSubPage(_subPage);
		if (isMobile()) setShowMenu(!showMenu);
	};

	useEffect(() => {
		if (pageType !== "root") {
			setIsSchedule(false);
		} else {
			setIsSchedule(true);
		}
	}, [pageType]);

	const handleOpenAssistent = () => {
		if (business.virtualAssistantLink) {
			window.open(business.virtualAssistantLink);
		}
	};

	const handleSubMenu = (subPage) => {
		setOpenMenu(subPage === openMenu ? null : subPage);
	};

	const Menus = [
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Agenda
				</Text>
			),
			page: "root",
			icon: <TodayOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				setIsSchedule(true);
				handleMenu("root");
			},
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Relatórios
				</Text>
			),
			page: "reports",
			icon: <AlignVerticalBottomOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				handleMenu("reports");
			},
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Assistente Virtual - {getAppName()}
				</Text>
			),
			icon: <SmartToyOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				handleOpenAssistent();
				mixpanelTrackEvent('Assistente virtual IA', me || {}, business || {});
			},
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Perfil da empresa
				</Text>
			),
			page: "businessProfile",
			icon: <HomeWorkOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("businessProfile"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Vendas
				</Text>
			),
			page: "salesHistory",
			icon: <CreditCardIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }}/>,
			action: () => handleMenu("salesHistory"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Horário de funcionamento
				</Text>
			),
			page: "openingHours",
			icon: <AccessTimeOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("openingHours"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Serviços
				</Text>
			),
			page: "services",
			icon: <WorkOutlineOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("services"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Profissionais
				</Text>
			),
			page: "professionals",
			icon: <PersonOutlineOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("professionals"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Clientes
				</Text>
			),
			page: "listClients",
			icon: <GroupOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("listClients"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Assinatura
				</Text>
			),
			page: "profilePritPlan",
			icon: <RedeemOutlined style={{ fontSize: 20, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("profilePritPlan"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					{getAppName(true)}
				</Text>
			),
			page: "pritPay",
			icon: <AttachMoneyOutlined style={{ fontSize: 20, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleSubMenu("pritPay"),
			subPages: [
				{
					title: (
						<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
							Cobrança antecipada
						</Text>
					),
					page: "AdvancePayment",
					icon: <PaymentsOutlinedIcon style={{ fontSize: 20, marginRight: "10px", color: "#B5C0D0" }} />,
					action: () => handleMenu("pritPay", "AdvancePayment"),
				},
				{
					title: (
						<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
							Dados bancários
						</Text>
					),
					page: "BankDetails",
					icon: <CreditCardOutlinedIcon style={{ fontSize: 20, marginRight: "10px", color: "#B5C0D0" }} />,
					action: () => handleMenu("pritPay", "BankDetails"),
				},
				{
					title: (
						<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
							Termos {getAppName(true)}
						</Text>
					),
					page: "PritPayTerms",
					icon: <BorderColorOutlinedIcon style={{ fontSize: 20, marginRight: "10px", color: "#B5C0D0" }} />,
					action: () => handleMenu("pritPay", "PritPayTerms"),
				},
			],
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Preciso de ajuda
				</Text>
			),
			icon: <QuestionAnswerOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				window.open(
					`https://wa.me/${process.env.REACT_APP_WHATSAPP_HELP_NUMBER}/?text=Olá!%20Vim%20do%${getAppName()}%20WEB%20gostaria%20de%20ajuda`,
					"_blank"
				);
			},
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Baixe o nosso App e tenha acesso a todas as funcionalidades!
				</Text>
			),
			icon: <InstallMobileOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: (e) => {
				handleAppDownload(e);
			},
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Sacar saldo
				</Text>
			),
			icon: <AccountBalanceWalletIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("withdrawBalance"),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Trocar estabelecimento
				</Text>
			),
			icon: <CachedOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => setOpenModalChangeBusiness(true),
		},
		{
			title: (
				<Text variant={"sideBar"} style={{ color: "#A9A9A9" }}>
					Sair
				</Text>
			),
			icon: <LogoutOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => setIsOpenModalLogout(true),
		},
	];

	return (
		<BusinessContext.Provider
			value={{
				business,
				showCalendar,
				setShowCalendar,
				date,
				setDate,
				hours,
				setHours,
				now,
				setNow,
				pageType,
				subPage,
				setSubPage,
				openMenu,
				setPageType,
				handleSetDate,
				Menus,
				isOpenModalLogout,
				setIsOpenModalLogout,
				anchorEl,
				setAnchorEl,
				handleClosePopover,
				reservationDates,
				setReservationDates,
				scheduleBreakDates,
				setScheduleBreakDates,
				businessData,
				setBusinessData,
				showMenu,
				toggleMenu,
				sharedDate,
				setSharedDate,
				isSchedule,
				setIsSchedule,
				isOpenModalChangeBusiness,
				setOpenModalChangeBusiness,
				handleMenu,
			}}>
			{children}
		</BusinessContext.Provider>
	);
}

export function useBusiness() {
	const context = useContext(BusinessContext);
	return context;
}
