import { FormLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React from "react";

const InputAutoComplete = ({
	label,
	className,
	onBlur,
	value,
	options = [],
	variant = "outlined",
	handleChange,
	style,
}) => {
	return (
		<>
			{label && <FormLabel className="input-label">{label}</FormLabel>}
			<Autocomplete
				value={value}
				onChange={(event, newValue, reason) => {
					if (reason === "selectOption") {
						handleChange(newValue);
					}
				}}
				onBlur={onBlur}
				className={["input-select", className]}
				style={style}
				variant={variant}
				renderInput={(params) => <TextField {...params} />}
				options={options}
				isOptionEqualToValue={(option, value) => option.value === value.value}
			/>
		</>
	);
};

export default InputAutoComplete;
