import React, { useEffect, useState } from "react";
import { Text } from "components";
import { moneyMask, checkExistAddress } from "../../../utils/utils";
import ProfessionalDetailsComponent from "../../../components/professionalDetailsComponent";
import { Inputs } from "components";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBackIos } from "@material-ui/icons";
import {
	selectPayment,
	initialSelectPaymentDefault,
	paymentTypes,
} from "../../../helpers/schemas/payment-select.schema";
import { Button as SubmitButton } from "components";
import {
	postPaymentAnticipatedAmount,
	postPaymentList,
	getPaymentSubscriptionInfo,
	postPritPayRequest,
	postPaymentBooking,
} from "../../../services/paymentService";
import { getBookingTransactionFindById } from "../../../services/bookingTransactionService";
import bookingTransactionService from "../../../services/bookingTransactionService";
import { useSelector } from "react-redux";
import { useConfig } from "../../../context/config.context";
import _ from "lodash";
import { useBusiness } from "../../../context/business.context";
import { QRCodeSVG } from "qrcode.react";
import { Dialog } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PritMan from '../../../assets/images/pritman_check.png';
import { getAppName } from "utils/validators";
import "../style/index.scss";

const NewSales = ({ booking, close, setSalesHistory }) => {
	const [bookingTData, setBookingTData] = useState({ loading: false, data: {}, requestPaymentAlreadyMade: false });
	const [bookingTPaymentData, setBbookingTPaymentData] = useState({ loading: false, data: {} });
	const [bookingPayedData, setBookingPayedData] = useState({ loading: false, data: [] });
	const [loadingList, setLoadingList] = useState(false);
	
	const [pritPayRequest, setPritPayRequest] = useState("");
	const [pritPayModal, setPritPayModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [addressModal, setAddressModal] = useState(false);
	const [linkPaymentModal, setLinkPaymentPayModal] = useState(false);
	const [isPritPaySelected, setIsPritPaySelecte] = useState(false);
	
	
	const { handleMenu } = useBusiness();
	const [linkPayment, setLinkPayment] = useState("");
	const paymentOptions = paymentTypes();
	const { handleFeedback } = useConfig();
	const { business } = useSelector((store) => store.business);


	const {
		control,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(selectPayment),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialSelectPaymentDefault,
	});

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "#fff",
		borderRadius: "8px",
		boxShadow: 24,
		p: 4,
		maxWidth: "400px",
		width: "90%",
	};

	const paymentData = watch("paymentOptions");

	const handleClosePritPayModal = () => {
		setPritPayModal(false);
	};

	const handleOpenAddressModal = () => {
		setAddressModal(true);
	};

	const handleCloseAddressModal = () => {
		setAddressModal(false);
	};

	const handleCloseConfirmationModal = () => {
		setConfirmationModal(false);
	};

	const handleCloseLinkPaymentModal = () => {
		setLinkPaymentPayModal(false);
	};

	const verifyPaymentType = (type) => {
		if (type === "pritpay") {
			setIsPritPaySelecte(true);
		}
	};

	useEffect(() => {
		verifyPaymentType(paymentData);
	}, [paymentData]);

	const handleAcceptPritPayTerms = () => {
		handleMenu("pritPay", "PritPayTerms");
	};

	const handleFullfillAddress = () => {
		handleMenu("pritPay", "Address");
	};

	const fetchData = (id) => {
		try {
			setBookingTData({ loading: true, data: {} });
			setBbookingTPaymentData({ loading: true, data: {} });
			setBookingPayedData({ loading: true, data: [] });
			getBookingTransactionFindById(id).then((result) => {
				setBookingTData({
					loading: false,
					data: result.data,
					requestPaymentAlreadyMade: result.data.paymentStatus === "requested",
				});
			});

			postPaymentAnticipatedAmount({
				recipient_id: business.id,
				booking_transaction_id: id,
			}).then((result) => {
				setBbookingTPaymentData({ loading: false, data: result.data });
			});

			postPaymentList(business.id, [id]).then((result) => {
				setBookingPayedData({ loading: false, data: result.data });
			});
		} catch (err) {
			setBookingTData({ loading: false, data: {} });
			setBbookingTPaymentData({ loading: false, data: {} });
			setBookingPayedData({ loading: false, data: [] });
			console.error(err);
		}
	};

	useEffect(() => {
		fetchData(booking.id);
	}, [booking]);

	const pritpayRequestPayment = async () => {
		setLoadingList(true);
		try {
			const result = await getPaymentSubscriptionInfo(business.id);
			const { status, contract } = result.data;

			if (status !== "active") {
				handleFeedback("Prit", `Para receber pelo ${getAppName(true)} você precisa primeiro assinar o Prit.`, "warning");
				return;
			}

			if (_.isNil(contract) || _.isEmpty(contract)) {
				setPritPayModal(true);
				return;
			}

			if (!checkExistAddress(business)) {
				handleOpenAddressModal();
				return;
			}

			if (!_.isEmpty(bookingTData.data.bookings) && !_.isEmpty(bookingTData.data.bookings[0].client.userId)) {
				try {
					const booking = bookingTData.data;
					const clientId = booking.bookings[0].client.userId;
					const amount = bookingTPaymentData.data.balance_to_pay / 100;
					const discount =
						!_.isEmpty(bookingPayedData.data) && !_.isEmpty(bookingPayedData.data[0].discount)
							? bookingPayedData.data[0].discount / 100
							: 0;
					const paymentPayload = {
						booking_transaction_ids: [booking.id],
						recipient_id: business.id,
						client_id: clientId,
						amount,
						discount,
					};
					const paymentResult = await postPritPayRequest(paymentPayload);

					setPritPayRequest(paymentResult.data);
					setBookingTData({ ...bookingTData, requestPaymentAlreadyMade: true });
					if (paymentResult.status === 200) {
						handleFeedback("Sucesso", "Cobrança online realizada com sucesso", "success");
					}
				} catch (err) {
					let message = err.message;

					if (err.response?.data?.message) {
						message = err.response.data.message;
						handleFeedback("PritPay", `${message}`, "error");
						if (err.response.data.error_code === 444) {
              console.err(err);
						}
					} else {
						handleFeedback("Prit", "Erro na solicitação", "error");
					}

					const isReceiver = message?.includes("Recebedor não encontrado");
					message = isReceiver
						? "Para Cobrar com Pagamento Online, você precisa cadastrar seus dados bancários."
						: message;

					if (err.request?.status !== 409) {
						console.error(err);
					}
				}
			} else {
				handleFeedback(
					"Solicitação de pagamento",
					`O cliente não é usuário PRIT. Assim, não é possível efetuar a cobrança pelo ${getAppName(true)}. Convide-o para o ${getAppName()}!`,
					"error"
				);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoadingList(false);
		}
	};

	const handleSubmitPaymentNoPritPay = async () => {
		setLoadingList(true);
		const bookingTransactionList = _.isArray(bookingTData.data)
			? bookingTData.data.map((booking) => ({ booking_external_id: booking.id }))
			: [{ booking_external_id: bookingTData.data.id }];

		const selectedPayment = watch("paymentOptions");

		const data = {
			pay_cash_request_booking_value: bookingTransactionList,
			client_id: booking.bookings[0]?.client.id,
			amount: booking.bookings[0]?.price / 100,
			recipient_id: business.id,
			payment_type: selectedPayment === "pritpay" ? undefined : selectedPayment,
		};

		if (isPritPaySelected) {
			if (bookingTData.requestPaymentAlreadyMade) {
				handleFeedback("Atenção", "Solicitação de pagamento já solicitada", "warning");
			} else {
				pritpayRequestPayment();
			}
		} else {
			try {
				const result = await postPaymentBooking(data);
				if (result.status === 200) {
					handleFeedback("Sucesso", "Cobrança realizada com sucesso", "success");
          if (selectedPayment === "pritpay") {
            setLinkPaymentPayModal(true);
            setLoadingList(false);
            return;
          }
					setSalesHistory(false);
					close();
				}
			} catch (error) {
				console.error(error);
				let message = error?.response?.data?.message;
				handleFeedback('Error', `${message}`, 'error');
				
			} finally {
				setLoadingList(false);
			}
		}
	};

	const fetchBookingData = async () => {
		try {
			setLoadingList(true);
			const response = await bookingTransactionService.getBookingTransaction(booking.id);
			if (response.status === 200) {
				setLinkPayment(response.data?.linkPayment ?? "");
				setBookingTData({
					loading: false,
					data: response.data,
					requestPaymentAlreadyMade: response.data.paymentStatus === "requested",
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingList(false);
		}
	};

	const getDiscount = (total, discount) => {
		if (discount){
			const valueWithDiscount = total - discount;
			return valueWithDiscount;
		} else {
			return 0;
		}
	};

	const removeEmoji = (text) => {
		const regex =
			/[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{2B50}-\u{2B55}]|[\u{231A}-\u{231B}]|[\u{23E9}-\u{23EF}]|[\u{23F0}-\u{23F3}]|[\u{25AA}-\u{25AB}]|[\u{25B6}-\u{25C0}]|[\u{25FB}-\u{25FE}]|[\u{2B05}-\u{2B07}]|[\u{2934}-\u{2935}]|[\u{3297}-\u{3299}]|[\u{3030}-\u{303D}]/gu;
		return text.replace(regex, "");
	};

	const sendWhatsApp = () => {
		const clientPhone = bookingTData.data?.bookings[0]?.client.phone
			? bookingTData.data?.bookings[0]?.client.phone  
			: bookingTData.data[0]?.bookings[0]?.client.phone;
	
		if (clientPhone) {
			const text = `O business ${business?.name} solicitou o pagamento do serviço. Confira no ${getAppName()}!\n\n${linkPayment}`;
			const encodedText = encodeURIComponent(removeEmoji(text));
			const link = `https://api.whatsapp.com/send?phone=${clientPhone}&text=${encodedText}`;
			window.open(link, '_blank');
		} else {
			handleFeedback("Prit", "Ops! Ocorreu um erro inesperado ao capturar o link de envio. Por favor, tente novamente.", "error");
		}
	};

	useEffect(() => {
		fetchBookingData();
	}, [booking]);

	return (
		<div>
			<header className="headerContainerNewSale">
				<div>
					<ArrowBackIos style={{ color: "#000957", cursor: "pointer" }} onClick={close} />
				</div>
				<Text variant={"title"} className="textNewSale" style={{ color: "#000957" }}>
					Nova venda
				</Text>
			</header>
			{loadingList ? (
				<div className="loading">
					<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
				</div>
			) : (
				<section className="serviceContainerNewSale">
					<Text variant={"mid"} style={{ color: "#000957" }}>
						Serviço
					</Text>
					<section className="serviceInfoContainer">
						{bookingTData?.data?.bookings && bookingTData.data.bookings.length > 0 && (
							<div>
								<ProfessionalDetailsComponent bookingList={booking} sales paymentProcess />
							</div>
						)}
					</section>
					<section>
						<Text variant={"mid"} style={{ color: "#000957", marginBottom: "1rem" }}>
							Pagamento
						</Text>
						<div style={{ marginBottom: "2.5rem" }}>
							<Inputs type="select" name="paymentOptions" control={control} options={paymentOptions.paymentOptions} />
						</div>
					</section>
					<section className="paymentDetailsContainer">
						<div>
							<Text style={{ marginBottom: "0.5rem" }}>Total dos serviços</Text>
							<Text style={{ marginBottom: "0.5rem" }}>Desconto</Text>
							<Text style={{ color: "#000957" }}>Pagamento antecipado</Text>
						</div>
						{bookingTData?.data?.bookings && bookingTData.data.bookings.length > 0 ? (
							<div>
								<Text style={{ marginBottom: "0.5rem" }}>
									{moneyMask(bookingTPaymentData?.data?.total_value_for_services, business?.currency)}
								</Text>
								<Text style={{ marginBottom: "0.5rem" }}>
									{moneyMask(
										getDiscount(
											bookingTPaymentData?.data?.total_value_for_services,
											bookingTPaymentData?.data?.total_discounts
										),
										business?.currency
									)}
								</Text>
								<Text style={{ color: "#000957" }}>
									{moneyMask(bookingTPaymentData?.data?.anticipation_payment, business?.currency)}
								</Text>
							</div>
						) : (
							<div className="loading">
								<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
							</div>
						)}
					</section>
					{bookingTData?.data?.bookings && bookingTData.data.bookings.length > 0 ? (
						<section className="totalValueContainer">
							<div>
								<Text style={{ color: "#3689ea" }}>Saldo a pagar após o serviço</Text>
							</div>
							<div>
								<Text style={{ color: "#3689ea" }}>
									{moneyMask(bookingTPaymentData?.data?.balance_to_pay, business?.currency)}
								</Text>
							</div>
						</section>
					) : (
						<div className="loading">
							<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
						</div>
					)}
					<footer className="footerContainerNewSale">
						<SubmitButton
							title="Cobrar Saldo"
							type="button"
							className="btn-color-primary"
							variant="contained"
							fullWidth={true}
							handleClick={() => {
								setConfirmationModal(true);
							}}
						/>
					</footer>
				</section>
			)}
			<Modal
				open={pritPayModal}
				onClose={handleClosePritPayModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Text id="modal-modal-title" variant="title">
						Termos do PritPay
					</Text>
					<Text id="modal-modal-description" style={{ marginTop: "1rem" }}>
						Para ter acesso a essa opção de pagamento, você precisa aceitar os termos do ${getAppName(true)}.
					</Text>
					<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1.5rem" }}>
						<button style={{ marginRight: "1rem" }} onClick={handleClosePritPayModal}>
							Cancelar
						</button>
						<button
							onClick={() => {
								handleAcceptPritPayTerms();
							}}>
							Aceitar
						</button>
					</div>
				</Box>
			</Modal>

			<Modal
				open={confirmationModal}
				onClose={handleCloseConfirmationModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<header className="closeIconContainer">
						<CloseIcon onClick={handleCloseConfirmationModal} style={{cursor:"pointer", color: '#9AA6B2'}}/>
					</header>
					<div className="imgContainer">
						<img src={PritMan} alt=""/>
					</div>
					<div>
						<Text style={{color:'#3689ea', marginBottom: '1rem', }} align={'center'}>Você está finalizando uma reserva.</Text>
					</div>
					<Text align={'center'} style={{color: '#9AA6B2'}}>
						Ao clicar em “Ok” essa reserva será movida para o histórico de vendas. Você deve fazer o recebimento no método de pagamento escolhido.
					</Text>
					<div className="buttonContainerPayemnt">
						<div onClick={handleCloseConfirmationModal}>
							<Text style={{color:'#3689ea', cursor: 'pointer'}}>Cancelar</Text>
						</div>
						<div onClick={()=>{handleSubmitPaymentNoPritPay(); handleCloseConfirmationModal();}}>
							<Text style={{color:'#3689ea', cursor: 'pointer'}}>OK</Text>
						</div>
					</div>
				</Box>
			</Modal>

			<Modal
				open={addressModal}
				onClose={handleCloseAddressModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Text id="modal-modal-title" variant="title">
						Atenção
					</Text>
					<Text id="modal-modal-description" style={{ marginTop: "1rem" }}>
						Para ter acesso a essa opção de pagamento, você precisa informar o endereço.
					</Text>
					<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1.5rem" }}>
						<button style={{ marginRight: "1rem" }} onClick={handleCloseAddressModal}>
							Cancelar
						</button>
						<button
							onClick={() => {
								handleFullfillAddress();
							}}>
							Preencher
						</button>
					</div>
				</Box>
			</Modal>
			<Dialog
				open={linkPaymentModal}
				onClose={handleCloseLinkPaymentModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<header style={{padding: '1rem'}}>
					<ArrowBackIos onClick={()=>{handleCloseLinkPaymentModal();}}/>
				</header>
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem", padding: "1rem" }}>
					<QRCodeSVG value={linkPayment} />
					<div style={{ textAlign: "center", width: "100%" }}>
						<Text style={{ marginBottom: "0.5rem" }}>
							Ou então envie o link de pagamento via Whatsapp para o seu cliente.
						</Text>
					</div>
					<SubmitButton
						title="Enviar link por Whatsapp"
						type="button"
						className="btn-color-primary"
            fullWidth={true}
						variant="contained"
						handleClick={() => {
							window.open(sendWhatsApp(), "_blank");
						}}></SubmitButton>
				</div>
        <div style={{ textAlign: "center", width: "100%", paddingBottom: '0.5rem',  cursor: 'pointer'}} onClick={handleCloseLinkPaymentModal}>
						<Text style={{ marginBottom: "0.5rem", FontWeight: "500", color: "#3688EB"}}>
							Cancelar Solicitação
						</Text>
					</div>
			</Dialog>
		</div>
	);
};

export default NewSales;
