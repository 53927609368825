import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

import advancePaymentService from "services/advance-payment.service";
import scheduleService from "services/scheduleService";

import { useConfig } from "./config.context";

const PritPayContext = createContext();

export function PritPayProvider({ children }) {
	const business = useSelector((state) => state.business.business);
	const { loading, handleLoading, handleFeedback } = useConfig();

	const [page, setPage] = useState(null);
	const [nextPage, setNextPage] = useState(null);
	const [cancellationPolicy, setCancellationPolicy] = useState(null);
	const [cancellationScreen, setCancellationScreen] = useState(null);
	const [apiPritData, setApiPritData] = useState(null);
	const [bankAccountStripe, setBankAccountStripe] = useState(null);
	const [bankAccount, setBankAccount] = useState(null);
	const [parameters, setParameters] = useState(null);
	const [bankList, setBankList] = useState([]);
	const [rescheduleList, setRescheduleList] = useState([]);

	const [termsAccepted, setTermsAccepted] = useState(false);
	const [firstLoad, setFirstLoad] = useState(false);
	const [needUpdateAddress, setNeedUpdateAddress] = useState(false);

	const apiRequestData = async () => {
		handleLoading(true);
		try {
			const cancellationPolicy = await getCancellationPolicy(false);

			try {
				const rescheduleList = await scheduleService.rescheduleEarlyBookingAll(business.id);
				setRescheduleList(treatRescheduleList(rescheduleList.data.reschedule));
			} catch (error) {
				console.log(error);
			}

			const apiPritData = await advancePaymentService.rescheduleEarlyBookingAll(business.id);
			setApiPritData(apiPritData);

			const cancellationPolicyScreen = await advancePaymentService.cancellationPolicyScreen(business.id);
			setCancellationScreen(cancellationPolicyScreen);

			const bankAccount = await getBankAccount(false);

			if (
				apiPritData.subscription &&
				apiPritData.subscription[0].status === "active" &&
				cancellationPolicy.contract_availablety &&
				cancellationPolicy.contract_availablety.recipient_id === business.id &&
				cancellationPolicy.contract
			) {
				if (!business.address?.postalCode) {
					setNextPage("Address");
				} else if (!bankAccount?.account) {
					setNextPage("BankDetails");
				} else {
					setTermsAccepted(true);
				}
			} else {
				setNextPage("PritPayTerms");
				setTermsAccepted(false);
			}
		} catch (error) {
			console.log(error);
		}

		handleLoading(false);
		setFirstLoad(true);
	};

	const treatRescheduleList = (rescheduleList) => {
		const list = rescheduleList.map((item) => {
			const minutes = item.minutes / 60;
			let result = "";
			if (minutes === 0) {
				result = "Não há mínimo de antecedência";
			} else if (minutes < 1) {
				result = `${item.minutes}m antes do horário agendado`;
			} else {
				result = `${minutes}h antes do horário agendado`;
			}

			return {
				label: result,
				value: item.minutes,
			};
		});
		return list;
	};

	const getCancellationPolicy = async (_loading = true) => {
		if (_loading) handleLoading(true);
		try {
			const params = await advancePaymentService.getParameters(business.id);
			setParameters(params);

			const cancellationPolicy = await advancePaymentService.cancellationPolicyScreen(business.id);
			setCancellationPolicy(cancellationPolicy);
			return cancellationPolicy;
		} catch (error) {
			console.log(error);
		} finally {
			if (_loading) handleLoading(false);
		}
	};

	const getBankAccount = async (_loading = true) => {
		if (_loading) handleLoading(true);
		let businessBankAccount = null;
		try {
			if (business.phone.startsWith("+55")) {
				businessBankAccount = await advancePaymentService.bankAccount(business.id);
				setBankAccount(businessBankAccount || null);
			} else {
				try {
					businessBankAccount = await advancePaymentService.checkBankAccountStripe(business.id);
					setBankAccount(businessBankAccount);
				} catch (error) {
					console.log(error);
				}
			}

			const bankType = await advancePaymentService.bankTypeAll();
			setBankList(
				bankType.map((bank) => ({
					label: bank.description,
					value: bank.bank_id,
				}))
			);

			return businessBankAccount;
		} catch (error) {
			console.log(error);
		} finally {
			if (_loading) handleLoading(false);
		}
	};
	const handleBiometryStatus = async () => {
		handleLoading(true);
		try {
			const biometry = await advancePaymentService.getBiometry(business.id);
			if (biometry.url) {
				window.open(biometry.url, "_blank");
			} else {
				handleFeedback("Erro de solicitação", "Tente novamente mais tarde.", "error", 5000);
			}
		} catch (error) {
			console.log(error);
		} finally {
			handleLoading(false);
		}

	};

	return (
		<PritPayContext.Provider
			value={{
				page,
				nextPage,
				setNextPage,
				setPage,
				termsAccepted,
				setTermsAccepted,
				cancellationPolicy,
				setCancellationPolicy,
				business,
				bankAccountStripe,
				bankAccount,
				apiPritData,
				cancellationScreen,
				loading,
				parameters,
				firstLoad,
				setFirstLoad,
				bankList,
				apiRequestData,
				getBankAccount,
				getCancellationPolicy,
				rescheduleList,
				needUpdateAddress,
				setNeedUpdateAddress,
				handleBiometryStatus
			}}>
			{children}
		</PritPayContext.Provider>
	);
}

export function usePritPay() {
	const context = useContext(PritPayContext);
	return context;
}
