import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Button, Dialog, Inputs, Text } from "components";
import { useBusiness } from "context/business.context";
import { useConfig } from "context/config.context";
import { usePritPay } from "context/pritpay.context";
import { AdvancePaymentSchema } from "helpers/schemas";
import advancePaymentService from "services/advance-payment.service";
import { maskCNPJ, maskCPF } from "utils/masks";

import useStyles from "./styles";

export default function BankDetails({ pritPayFlow = false }) {
	const [openValidationModal, setOpenValidationModal] = useState(false);

	const classes = useStyles();
	const { handleFeedback, handleLoading, loading } = useConfig();
	const {
		setPage,
		business,
		bankList,
		getBankAccount,
		bankAccount,
		apiRequestData,
		setNeedUpdateAddress,
		handleBiometryStatus,
	} = usePritPay();
	const { setSubPage } = useBusiness();

	const me = useSelector((state) => state.me.me);

	const containerRef = useRef(null);
	const documentRef = useRef(null);
	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const bankNameRef = useRef(null);
	const agencyRef = useRef(null);
	const accountRef = useRef(null);
	const accountDigitRef = useRef(null);
	const accountTypeRef = useRef(null);
	const birthDateRef = useRef(null);
	const jobRef = useRef(null);
	const monthlyIncomeRef = useRef(null);

	const {
		control,
		watch,
		handleSubmit,
		setValue,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(
			bankAccount?.account ? AdvancePaymentSchema.bankDetailsSchema : AdvancePaymentSchema.newBankDetailsSchema
		),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: AdvancePaymentSchema.initialBankDetailsForm,
	});

	const accountTypes = [{ value: 0, label: "Selecione o tipo de conta" }, ...AdvancePaymentSchema.typesAccount];

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				handleSave();
			}
		}
	};

	const handleSave = async (data) => {
		handleLoading(true);
		try {
			const documentNumber = data.document.replace(/[^\d]+/g, "");
			const updateBankData = {
				recipient_id: business.id,
				bank_id: data.bankName.value,
				account_type: data.accountType,
				name: !data.documentType ? data.name : null,
				legal_number: documentNumber,
				email: emailRef.current ? data.email : me.email,
				phone: business.phone,
				agency: data.agency,
				account: data.accountNumber,
				account_digit: data.accountDigit,
				company_name: data.documentType ? data.name : null,
				managing_partners: [
					{
						name: business.name,
						document_number: documentNumber,
						email: emailRef.current ? data.email : me.email,
					},
				],
				address: {
					street: business.address.street,
					neighborhood: business.address.neighborhood,
					city: business.address.city,
					state: business.address.state,
					country: business.address.country,
					reference_point: business.address.referencePoint,
					zipcode: business.address.postalCode,
					street_number: business.address.number,
					complementary: business.address.unit,
				},
				birthdate: data.birthDate,
				monthly_income: parseFloat(data.monthlyIncome.replace("R$", "").replace(".", "").replace(",", ".")),
				professional_occupation: data.job,
				trading_name: data.name,
				annual_revenue: parseFloat(data.monthlyIncome.replace("R$", "").replace(".", "").replace(",", ".")) * 12,
				site_url: business.social.desktop,
			};
			await advancePaymentService.updateBankAccount(updateBankData);
			setOpenValidationModal(true);
			apiRequestData();
		} catch (error) {
			console.log(error);
			handleFeedback("Ops!", "Não foi possível atualizar os dados bancários. Por favor, tente novamente.", "warning");
		} finally {
			handleLoading(false);
		}
	};

	const handleClose = () => {
		setOpenValidationModal(false);
		setPage(null);
	};

	useEffect(() => {
		if (!business.address.postalCode && !pritPayFlow) {
			setNeedUpdateAddress(true);
			setSubPage("Address");
		}
		if (!bankList.length) getBankAccount();
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	useEffect(() => {
		if (bankAccount) {
			setValue("bankName", { value: bankAccount.bank.bank_id, label: bankAccount.bank.description });
			setValue("agency", bankAccount.agency);
			setValue("accountNumber", bankAccount.account);
			setValue("accountDigit", bankAccount.account_digit);

			const type_account = accountTypes.find((type) => type.convert === bankAccount.account_type);
			setValue("accountType", type_account.value);
			let document;
			if (bankAccount.company_name) {
				document = maskCNPJ(bankAccount.legal_number);
			} else {
				document = maskCPF(bankAccount.legal_number);
			}
			setValue("document", document);
			setValue("name", bankAccount.name || bankAccount.company_name);
			setValue("email", me?.email);
			setValue("documentType", bankAccount.company_name ? true : false, { shouldValidate: true });
		}
	}, [bankAccount]);

	const getBiometryStatus = () => {
		let message =
			"Seus dados bancários estão sendo processados, aguarde alguns segundos para a liberação da biometria.";
		let status = "info";
		let type = "button";

		if (bankAccount?.status === "ACTIVE") {
			status = "success";
			message = "Biometria feita com sucesso, processo de criação da conta de pagamento finalizado.";
			type = "div";
		}

		if (bankAccount?.status === "RECUSED") {
			status = "error";
			message = "Criação da conta de pagemento recusada, entre em contato com nosso suporte.";
			type = "div";
		}

		if (bankAccount?.status === "AFFILIATION") {
			status = "warning";
			message =
				"Faça a biometria para finalizar o processo de criação da conta de pagamento. Caso tenha feito, aguarde alguns segundos para o processamento.";
			type = "div";
		}

		if (type === "button") {
			return <button className={`${classes.biometryStatus} biometry-${status}`}>{message}</button>;
		} else {
			return (
				<div style={{ cursor: "default" }} className={`${classes.biometryStatus} biometry-${status}`}>
					{message}
				</div>
			);
		}
	};

	return (
		<>
			<div className="box-content" ref={containerRef}>
				<Text variant="mid" align="left" style={{ padding: "0 0 1rem", color: "#393762" }}>
					Dados Bancários
				</Text>
				<br />
				{!loading && getBiometryStatus()}
				{bankAccount?.status === "AFFILIATION" && (
					<Button
						type="button"
						variant="outlined"
						title="Ativar Biometria"
						fullWidth={true}
						className="btn-default"
						style={{ marginBottom: "1rem" }}
						handleClick={handleBiometryStatus}
					/>
				)}

				<form className={classes.form}>
					<Inputs
						control={control}
						name="documentType"
						label="Utilizar CNPJ"
						type="switch"
						className="input-form-checkbox"
					/>

					<Inputs
						control={control}
						name="document"
						mask={watch("documentType") ? "cnpj" : "cpf"}
						type="text"
						label={`Insira o seu ${watch("documentType") ? "CNPJ" : "CPF"}`}
						placeholder={watch("documentType") ? "00.000.000/0000-00" : "000.000.000-00"}
						inputRef={documentRef}
						handleKeyDown={(e) => handleKeyDown(e, fullNameRef)}
						inputProps={{
							maxLength: watch("documentType") ? 18 : 14,
							pattern: "[0-9]*",
							inputMode: "numeric",
						}}
					/>

					<Inputs
						control={control}
						name="name"
						type="text"
						label={watch("documentType") ? "Razão Social" : "Titular da conta"}
						inputRef={fullNameRef}
						handleKeyDown={(e) => {
							if (emailRef.current) {
								handleKeyDown(e, emailRef);
							} else {
								handleKeyDown(e, bankNameRef);
							}
						}}
					/>

					{me?.email === null ||
						me?.email === "" ||
						(me?.email === undefined && (
							<Inputs
								control={control}
								name="email"
								type="email"
								label="Email"
								inputRef={emailRef}
								handleKeyDown={(e) => handleKeyDown(e, bankNameRef)}
							/>
						))}

					<Inputs
						control={control}
						type="autocomplete"
						name="bankName"
						label="Nome do banco"
						options={[
							{
								label: "Selecione o banco",
								value: "0",
							},
							...bankList,
						]}
						className="input-bg-white"
						inputRef={bankNameRef}
						handleKeyDown={(e) => handleKeyDown(e, agencyRef)}
					/>

					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<Inputs
								control={control}
								name="agency"
								type="text"
								label="Agência"
								inputRef={agencyRef}
								handleKeyDown={(e) => handleKeyDown(e, accountRef)}
								inputProps={{
									maxLength: 4,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
							/>
						</Grid>
						<Grid item xs={8} md={6}>
							<Inputs
								control={control}
								name="accountNumber"
								type="text"
								label="Conta"
								inputRef={accountRef}
								handleKeyDown={(e) => handleKeyDown(e, accountDigitRef)}
								inputProps={{
									maxLength: 8,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
							/>
						</Grid>
						<Grid item xs={4} md={3}>
							<Inputs
								control={control}
								name="accountDigit"
								type="text"
								label="Dígito"
								inputRef={accountDigitRef}
								handleKeyDown={(e) => handleKeyDown(e, null)}
								inputProps={{
									maxLength: 1,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
							/>
						</Grid>
					</Grid>

					<Inputs
						control={control}
						type="select"
						name="accountType"
						label="Tipo de conta"
						options={accountTypes}
						className="input-bg-white"
						inputRef={accountTypeRef}
						handleKeyDown={(e) => handleKeyDown(e, agencyRef)}
					/>

					{!bankAccount?.account && (
						<>
							<Inputs
								control={control}
								name="birthDate"
								type="text"
								label="Data de nascimento"
								className="input-bg-white"
								inputRef={birthDateRef}
								placeholder="DD/MM/AAAA"
								handleKeyDown={(e) => handleKeyDown(e, jobRef)}
								inputProps={{
									maxLength: 10,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
							/>

							<Inputs
								control={control}
								name="job"
								type="text"
								label="Ocupação (profissão)"
								className="input-bg-white"
								inputRef={jobRef}
								handleKeyDown={(e) => handleKeyDown(e, monthlyIncomeRef)}
							/>

							<Inputs
								control={control}
								name="monthlyIncome"
								type="money"
								label="Rendimento mensal"
								className="input-bg-white"
								inputRef={monthlyIncomeRef}
								handleKeyDown={(e) => handleKeyDown(e, null)}
								placeholder="Digite apenas números"
								inputProps={{
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
							/>
						</>
					)}

					<Button
						type="button"
						variant="contained"
						title="Salvar"
						fullWidth={true}
						className="btn-color-primary btn-default"
						disabled={!isValid || loading}
						handleClick={handleSubmit(handleSave)}
					/>
				</form>
			</div>

			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Dados bancários</strong>}
				content={<Text>Seus dados bancários foram atualizados com sucesso!</Text>}
				isOpen={openValidationModal}
				handleFirstBtn={handleClose}
				lblBtnFirst="OK"
			/>
		</>
	);
}
