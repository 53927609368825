import { moneyRender } from "./utils";

export const maskCPF = (value) => {
	if (!value) return value;
	const cpf = value.replace(/\D/g, "");
	return cpf
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
};

export const maskCNPJ = (value) => {
	if (!value) return value;
	const cnpj = value.replace(/\D/g, "");
	return cnpj
		.replace(/(\d{2})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1/$2")
		.replace(/(\d{4})(\d)/, "$1-$2");
};

export const maskCreditCard = (value) => {
	if (!value) return value;
	const creditCard = value.replace(/\D/g, "");
	return creditCard
		.replace(/(\d{4})(\d)/, "$1 $2")
		.replace(/(\d{4})(\d)/, "$1 $2")
		.replace(/(\d{4})(\d)/, "$1 $2");
};

export const maskZipCode = (value) => {
	if (!value) return value;
	const zipCode = value.replace(/\D/g, "");
	return zipCode.replace(/(\d{5})(\d)/, "$1-$2");
};

export const maskExpirationDate = (value) => {
	if (!value) return value;
	const expiry = value.replace(/\D/g, "");
	return expiry.replace(/(\d{2})(\d)/, "$1/$2");
};

export const maskBirthDate = (value) => {
	if (!value) return value;
	const birthDate = value.replace(/\D/g, "");
	return birthDate
		.replace(/(\d{2})(\d)/, "$1/$2")
		.replace(/(\d{2})(\d)/, "$1/$2")
		.replace(/(\d{4})(\d)/, "$1");
};

export const maskNumbers = (value) => {
	if (!value) return value;
	return value.replace(/\D/g, "");
};

export const maskAccount = (value) => {
	if (!value) return value;
	const account = value.replace(/\D/g, "");
	return account.replace(/(\d{5})(\d)/, "$1-$2");
};

export const maskMoney = (value, business) => {
	if (!value) return value;
	const numericValue = parseInt(value.replace(/[^0-9]/g, ""));
	return moneyRender(business, numericValue / 100);
};
