import React from "react";
import { useNavigate } from "react-router";
import { Close } from "@material-ui/icons";
import { Dialog } from "@mui/material";

import { Button, Container, Text } from "components";

import { auth } from "services/firebase";
import { logoutUser } from "services/loginService";
import { useDispatch } from "react-redux";
import businessAction from "store/actions/businessAction";

const LogoutDialog = ({ isOpen, handleClose }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleConfirmLogout = () => {
		dispatch(businessAction.logoutBusiness());
		logoutUser(auth);
		localStorage.clear();
		navigate("/welcome");
	};

	return (
		<Dialog
			onClose={handleClose}
			open={isOpen}
			fullScreen
			PaperProps={{
				style: { backgroundColor: "transparent" },
			}}>
			<Container style={{ gap: "16px" }} transparent noHeight>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
					<Text style={{ color: "#616161" }}>Deseja sair da sua conta?</Text>
					<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={() => handleClose()}></Close>
				</div>
				<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
					<Button
						type="button"
						variant="contained"
						className="btn-color-primary btn-default"
						title="Sim"
						handleClick={() => handleConfirmLogout()}
						fullWidth
					/>

					<Button
						type="button"
						variant="outlined"
						className="btn-outlined-secondary btn-default"
						title="Não"
						handleClick={() => handleClose()}
						fullWidth
					/>
				</div>
			</Container>
		</Dialog>
	);
};

export default LogoutDialog;
