import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Button, Dialog, Inputs, Text } from "components";
import { useBusiness } from "context/business.context";
import { useConfig } from "context/config.context";
import { usePritPay } from "context/pritpay.context";
import { AddressSchema } from "helpers/schemas";
import businessAction from "store/actions/businessAction";

import useStyles from "./styles";

const Address = ({ pritPayFlow = false }) => {
	const [openValidationModal, setOpenValidationModal] = useState(false);
	const [openModalUpdateAddress, setOpenModalUpdateAddress] = useState(false);

	const classes = useStyles();
	const dispatch = useDispatch();
	const { handleFeedback, handleLoading, loading } = useConfig();
	const { setPage, business, bankAccount, needUpdateAddress, setNeedUpdateAddress } = usePritPay();
	const { setSubPage } = useBusiness();

	const containerRef = useRef(null);

	const {
		control,
		watch,
		setValue,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(AddressSchema.addressSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: AddressSchema.initialAddressForm,
	});

	useEffect(() => {
		if (watch("zipcode")?.length === 9) {
			handleZipCode();
		}
	}, [watch("zipcode")]);

	const updateAddress = () => {
		if (business && business.id && business.address) {
			setValue("zipcode", business.address.postalCode);
			setValue("address", business.address.street);
			setValue("number", business.address.number);
			setValue("complement", business.address.unit);
			setValue("neighborhood", business.address.neighborhood);
			setValue("uf", business.address.state);
			setValue("city", business.address.city);
		}
	};

	const handleZipCode = () => {
		handleLoading(true);
		try {
			const zipcode = watch("zipcode").replace("-", "");
			axios
				.get(`https://viacep.com.br/ws/${zipcode}/json/`)
				.then((response) => {
					const data = response.data;
					setValue("address", data.logradouro);
					setValue("uf", data.uf);
					setValue("city", data.localidade);
					setValue("neighborhood", data.bairro, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				})
				.catch((error) => {
					console.error("Erro ao buscar o CEP:", error);
				});
		} catch (error) {
			console.error("Erro ao buscar o CEP:", error);
		} finally {
			handleLoading(false);
		}
	};

	const handleClose = () => {
		if (!bankAccount) {
			setNeedUpdateAddress(false);
			setSubPage("BankDetails");
		} else {
			setOpenValidationModal(false);
			setPage(null);
		}
	};

	const handleSave = async (data) => {
		handleLoading(true);
		const address = {
			address_postal_code: data.zipcode,
			address_street: data.address,
			address_number: data.number,
			address_unit: data.complement,
			address_neighborhood: data.neighborhood,
			address_state: data.uf,
			address_city: data.city,
			address_country: "Brasil",
		};

		if (business && business.id) {
			try {
				await dispatch(businessAction.updateBusinessAddress(business.id, address));
				if (!bankAccount && pritPayFlow) {
					setPage("BankDetails");
				} else {
					if (needUpdateAddress) {
						setNeedUpdateAddress(false);
						setSubPage("BankDetails");
					} else {
						console.log("🚀 ~ ELSE");
						setOpenValidationModal(true);
					}
				}
			} catch (error) {
				let errorMessage = "Ocorreu um erro desconhecido. Por favor, tente novamente.";

				if (error.response) {
					if (error.response.data && error.response.data.message) {
						errorMessage = error.response.data.message;
					} else {
						errorMessage = "Erro ao acessar a página. Verifique suas permissões.";
					}
				} else if (error.request) {
					errorMessage = "Não foi possível conectar ao servidor. Por favor, verifique sua conexão de internet.";
				} else {
					errorMessage = error.message;
				}
				handleFeedback("Erro", `Erro ao atualizar o endereço: ${errorMessage}`, "error");
			} finally {
				handleLoading(false);
			}
		}
	};

	const cepRef = useRef(null);
	const addressRef = useRef(null);
	const numberRef = useRef(null);
	const complementRef = useRef(null);
	const referencePointRef = useRef(null);
	const ufRef = useRef(null);
	const cityRef = useRef(null);
	const neighborhoodRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				if (isValid) handleSave();
			}
		}
	};

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth" });
			updateAddress();
		}
	}, []);

	useEffect(() => {
		if (needUpdateAddress && !loading) {
			setOpenModalUpdateAddress(true);
		}

		return () => {
			setNeedUpdateAddress(false);
		};
	}, [needUpdateAddress, loading]);

	return (
		<>
			<div className="box-content" ref={containerRef}>
				<Text variant="mid" align="left" style={{ padding: "0 0 1rem", color: "#393762" }}>
					Atualize seu endereço
				</Text>
				<br />
				<form className={classes.form}>
					<Inputs
						control={control}
						name="zipcode"
						type="text"
						label="CEP"
						placeholder="Digite o CEP: 00000-000"
						inputRef={cepRef}
						handleKeyDown={(e) => handleKeyDown(e, addressRef)}
						inputProps={{
							maxLength: 9,
						}}
					/>
					<Inputs
						control={control}
						name="address"
						type="text"
						label="Logradouro"
						inputRef={addressRef}
						handleKeyDown={(e) => handleKeyDown(e, numberRef)}
					/>
					<Inputs
						control={control}
						name="number"
						type="text"
						label="Número"
						inputRef={numberRef}
						handleKeyDown={(e) => handleKeyDown(e, complementRef)}
					/>
					<Inputs
						control={control}
						name="complement"
						type="text"
						label="Complemento"
						helper='Caso não tenha, escreva "sem complemento"'
						inputRef={complementRef}
						handleKeyDown={(e) => handleKeyDown(e, referencePointRef)}
					/>
					<Inputs
						control={control}
						name="referencePoint"
						type="text"
						label="Ponto de Referência"
						placeholder="Digite o ponto de referência"
						inputRef={referencePointRef}
						handleKeyDown={(e) => handleKeyDown(e, ufRef)}
					/>
					<Inputs
						control={control}
						name="uf"
						type="text"
						label="UF"
						inputRef={ufRef}
						handleKeyDown={(e) => handleKeyDown(e, cityRef)}
					/>
					<Inputs
						control={control}
						name="city"
						type="text"
						label="Cidade"
						inputRef={cityRef}
						handleKeyDown={(e) => handleKeyDown(e, neighborhoodRef)}
					/>
					<Inputs
						control={control}
						name="neighborhood"
						type="text"
						label="Bairro"
						inputRef={neighborhoodRef}
						handleKeyDown={(e) => handleKeyDown(e, null)}
					/>
					<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "2rem" }}>
						<Button
							type="button"
							variant="contained"
							title="Salvar"
							fullWidth={true}
							className="btn-color-primary btn-default"
							disabled={!isValid || loading}
							handleClick={handleSubmit(handleSave)}
						/>
					</div>
				</form>
			</div>
			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Atenção</strong>}
				content={<Text>Atualize seu endereço primeiro para que possa atualizar seus dados bancários.</Text>}
				isOpen={openModalUpdateAddress}
				handleFirstBtn={() => setOpenModalUpdateAddress(false)}
				lblBtnFirst="OK"
			/>
			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Dados atualizados</strong>}
				content={<Text>Seu endereço foi atualizado com sucesso!</Text>}
				isOpen={openValidationModal}
				handleFirstBtn={handleClose}
				lblBtnFirst="OK"
			/>
		</>
	);
};

export default Address;
