import { Divider } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { Header } from "components";
import { useBusiness } from "context/business.context";
import { usePritPay } from "context/pritpay.context";
import { getAppName } from "utils/validators";

import AdvancePaymentView from "./advance-payment-view";
import Address from "./content/address";
import BankDetails from "./content/bank-details";
import PritPayTerms from "./content/prit-pay-terms";
import StripeAccount from "./content/stripe-account";

import "./style.scss";

const PritPayView = () => {
	const { subPage } = useBusiness();
	const { business } = usePritPay();

	const renderPage = () => {
		switch (subPage) {
			case "PritPayTerms":
				return <PritPayTerms />;
			case "Address":
				return <Address />;
			case "BankDetails":
				if (business.phone.startsWith("+55")) {
					return <BankDetails />;
				}
				return <StripeAccount />;
			case "AdvancePayment":
				return <AdvancePaymentView />;
			default:
				return null;
		}
	};

	const renderTitle = () => {
		switch (subPage) {
			case "PritPayTerms":
				return `Termos ${getAppName(true)}`;
			case "Address":
				return "Endereço";
			case "BankDetails":
				return "Dados bancários";
			case "AdvancePayment":
				return "Cobrança antecipada";
			default:
				return null;
		}
	};

	return (
		<>
			<Header title={renderTitle()} showNotifications />
			<Divider />
			<div className="box">
				{subPage !== "AdvancePayment" && (
					<>
						<div className={clsx("side-left")}>{renderPage()}</div>
						<div className="side-right"></div>
					</>
				)}
				{subPage === "AdvancePayment" && <AdvancePaymentView />}
			</div>
		</>
	);
};

export default PritPayView;
