import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Button, Dialog, Inputs, Text } from "components";
import { useBusiness } from "context/business.context";
import { useConfig } from "context/config.context";
import { usePritPay } from "context/pritpay.context";
import { AdvancePaymentSchema } from "helpers/schemas";
import advancePaymentService from "services/advance-payment.service";

import useStyles from "./styles";

export default function StripeAccount({ pritPayFlow = false }) {
	const [openValidationModal, setOpenValidationModal] = useState(false);
	const [stripeUrl, setStripeUrl] = useState("");

	const me = useSelector((state) => state.me.me);

	const classes = useStyles();
	const { handleFeedback, handleLoading, loading } = useConfig();
	const { setPage, business, bankList, getBankAccount, bankAccount, apiRequestData, setNeedUpdateAddress } =
		usePritPay();
	const { setSubPage } = useBusiness();

	const containerRef = useRef(null);
	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const companyNameRef = useRef(null);

	const {
		control,
		handleSubmit,
		setValue,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(AdvancePaymentSchema.stripeAccountSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: AdvancePaymentSchema.initialStripeAccountForm,
	});

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				handleSave();
			}
		}
	};

	const handleSave = async (data) => {
		handleLoading(true);
		try {
			const updateBank = {
				recipient_id: business.id,
				name: data.name,
				email: data.email,
				company_name: data.companyName,
				currency: business.currency,
			};
			const response = await advancePaymentService.updateBankAccountStripe(updateBank);
			setStripeUrl(response.url);
			setOpenValidationModal(true);
			apiRequestData();
		} catch (error) {
			console.log(error);
			handleFeedback("Ops!", "Não foi possível atualizar os dados bancários. Por favor, tente novamente.", "warning");
		} finally {
			handleLoading(false);
		}
	};

	const openStripeUrl = async () => {
		window.open(stripeUrl, "_blank");
		setOpenValidationModal(false);
		setPage(null);
	};

	useEffect(() => {
		if (!business.address.postalCode && !pritPayFlow) {
			setNeedUpdateAddress(true);
			setSubPage("Address");
		}
		if (!bankList.length) getBankAccount();
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	useEffect(() => {
		if (bankAccount) {
			setValue("name", bankAccount.name ?? me.name);
			setValue("email", bankAccount.email ?? me.email);
			setValue("companyName", bankAccount.company_name ?? business.name, { shouldValidate: true });

			if (bankAccount && bankAccount?.status === "Active") {
				handleFeedback("Sua conta no Stripe já foi criada, agora é só começar a receber por seus serviços");
			} else if (bankAccount && bankAccount?.status === "Pending") {
				handleFeedback(
					"Atenção",
					"Você precisa terminar seu cadastro na Stripe! Confirme os dados e clique em SALVAR para continuar com seu cadastro",
					"warning",
					10000
				);
			} else {
				handleFeedback(
					"Atenção",
					"Agora você criará uma conta gratuita com o Stripe, um parceiro de pagamentos pelo mundo",
					"info",
					5000
				);
			}
		}
	}, [bankAccount]);

	return (
		<>
			<div className="box-content" ref={containerRef}>
				<Text variant="mid" align="left" style={{ padding: "0 0 1rem", color: "#393762" }}>
					Dados Bancários
				</Text>
				<br />
				<form className={classes.form}>
					<Inputs
						control={control}
						name="name"
						type="text"
						label="Nome"
						inputRef={fullNameRef}
						handleKeyDown={(e) => handleKeyDown(e, emailRef)}
					/>
					<Inputs
						control={control}
						name="email"
						type="text"
						label="Email"
						inputRef={emailRef}
						handleKeyDown={(e) => handleKeyDown(e, companyNameRef)}
					/>
					<Inputs
						control={control}
						name="companyName"
						type="text"
						label="Nome da empresa"
						inputRef={companyNameRef}
						handleKeyDown={(e) => handleKeyDown(e, null)}
					/>
					<Button
						type="button"
						variant="contained"
						title="Salvar"
						fullWidth={true}
						className="btn-color-primary btn-default"
						disabled={!isValid || loading}
						handleClick={handleSubmit(handleSave)}
					/>
				</form>
			</div>

			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Você será encaminhado para o Stripe</strong>}
				content={
					<Text>
						O Stripe é nosso parceiro de pagamentos pelo mundo, dessa forma poderá receber online pelos seus serviços.
					</Text>
				}
				isOpen={openValidationModal}
				handleFirstBtn={openStripeUrl}
				lblBtnFirst="OK"
			/>
		</>
	);
}
