import { useEffect, useState } from "react";
import "./list-balance-style.scss";

import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { WithdrawStatus, Text } from "components";

import { moneyMask } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import { getAppName } from "utils/validators";

export default function ListBalance({ userInfo, handleBookingId = () => {} }) {
	const { business } = useSelector((store) => store.business);
	const [listBalance, setListBalance] = useState([]);

	useEffect(() => {
		setListBalance(userInfo);
	}, [userInfo]);

	const formatData = (service) => {
		const startDate = parseISO(service.date_service);
		const endDate = parseISO(service.end_time);

		const day = format(startDate, "EEEE", { locale: ptBR });
		const month = format(startDate, "dd/MM", { locale: ptBR });
		const startTime = format(startDate, "HH:mm");
		const endTime = format(endDate, "HH:mm");

		return {
			day: day,
			month: month,
			startTime: startTime,
			endTime: endTime,
		};
	};

	const translatePaymentType = (type) => {
		const translate = {
			cash: 'Dinheiro',
      credit: 'Cartão de crédito',
      creditprit: 'Cartão de crédito',
      debit: 'Cartão de débito',
      debitprit: 'Cartão de débito',
      pixprit: 'Pix',
      mbwayprit: 'Mb-Way',
      undefined: getAppName(true),
		};

		if (type) {
			return translate[type];
		}
	};

	return (
		<div className="containerList">
			{listBalance.map((item) => (
				<div className="cursor" key={item.booking_transaction_id} onClick={() => handleBookingId(item.booking_transaction_id)}>
					{item.services.map((service) => {
						const formattedService = formatData(service); // Formata cada serviço
						return (
							<div key={service.service_name} className="blockCustom">
								<div className="containerData">
									{" "}
									<Text className="font">{formattedService.day.substring(0, 3)}</Text>
									<Text className="font size">{formattedService.month}</Text>
									<Text className="sizeSmall">
										{formattedService.startTime}-{formattedService.endTime}
									</Text>
								</div>
								<div className="containerInfo">
									<div className="infoClient">
										<Text className="font size">{item.client_name} Ferreira</Text>
										<Text className="sizeSmall">{service.service_name}</Text>
										<Text className="colorTypePayment">{translatePaymentType(item.payment_type)}</Text>
									</div>
									<div className="info">
										<WithdrawStatus statusType={item.withdraw_status} />
										<Text className="colorTypePayment">
											{moneyMask(item.paid_values, business.currency)}
										</Text>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			))}
		</div>
	);
}
