import api from "./api";

export const updateUser = (id, data) => {
	return api.put(`user/${id}`, data);
};

const userService = {
	updateUser,
};

export default userService;