import clsx from "clsx";
import React, { useEffect } from "react";

import { usePritPay } from "context/pritpay.context";
import { isMobile } from "utils/isMobile";

import Address from "./content/address";
import AdvancePayment from "./content/advance-payment";
import BankDetails from "./content/bank-details";
import PritPayTerms from "./content/prit-pay-terms";
import StripeAccount from "./content/stripe-account";

import "./style.scss";

const AdvancePaymentView = () => {
	const { page, apiRequestData, setPage, business } = usePritPay();

	const renderPage = () => {
		switch (page) {
			case "PritPayTerms":
				return <PritPayTerms pritPayFlow={true} />;
			case "Address":
				return <Address pritPayFlow={true} />;
			case "BankDetails":
				if (business.phone.startsWith("+55")) {
					return <BankDetails pritPayFlow={true} />;
				}
				return <StripeAccount pritPayFlow={true} />;
			default:
				return null;
		}
	};

	useEffect(() => {
		apiRequestData();

		return () => {
			setPage(null);
		};
	}, [business]);

	return (
		<>
			<div className={clsx("side-left", { hidden: isMobile() && page !== null })}>
				<AdvancePayment />
			</div>
			<div className="side-right">{renderPage()}</div>
		</>
	);
};

export default AdvancePaymentView;
