import React from "react";
import { Checkbox } from "@material-ui/core";

const InputCheckbox = ({ defaultChecked, checked, handleChange, name, className, disabled = false }) => {
	return (
		<Checkbox
			defaultChecked={defaultChecked}
			checked={checked}
			onChange={handleChange}
			name={name}
			disabled={disabled}
			className={["input-checkbox", className]}
		/>
	);
};

export default InputCheckbox;
