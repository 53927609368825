import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Dialog, Inputs, Text } from "components";
import { useConfig } from "context/config.context";
import { usePritPay } from "context/pritpay.context";
import * as Schemas from "helpers/schemas";
import advancePaymentService from "services/advance-payment.service";
import { getAppName } from "utils/validators";

const AdvancePayment = () => {
	const [openValidationModal, setOpenValidationModal] = useState(false);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const {
		setPage,
		page,
		nextPage,
		setNextPage,
		termsAccepted,
		firstLoad,
		loading,
		cancellationScreen,
		setFirstLoad,
		rescheduleList,
		business,
		cancellationPolicy,
	} = usePritPay();

	const { handleLoading, handleFeedback } = useConfig();

	const containerRef = useRef(null);

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(Schemas.AdvancePaymentSchema.advancePaymentSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: Schemas.AdvancePaymentSchema.initialAdvancePaymentForm,
	});

	const watchEnabledDays = watch("cancellation_policy_enabled_days") ?? [];
	const watchReschedulingPolicy = watch("rescheduling_policy");
	const watchAutomaticallyBooking = watch("automatically_booking");
	const watchFixedCancellation = watch("fixed_cancellation");
	const watchAnticipatedPayment = watch("anticipated_payment");

	const onSubmit = async (data) => {
		handleLoading(true);
		try {
			const policy = {
				automatically_booking: data.automatically_booking,
				maximum_months_future_booking: 0,
				minimum_hours_before_booking: 0,
				percentage_cancellation: data.automatically_booking
					? {
							percentage_cancellation_fee: data.percentage_cancellation,
							hours_before_booking_expiration: data.minimum_hours_before_booking,
					  }
					: null,
				fixed_cancellation: data.fixed_cancellation
					? {
							cancellation_fee: data.cancellation_fee,
							hours_before_booking_expiration: data.minimum_hours_before_booking,
					  }
					: null,
				anticipated_payment: data.anticipated_payment
					? {
							discount: data.discount,
							hours_before_booking_expiration: data.minimum_hours_before_booking,
					  }
					: null,
				cancellation_policy_enabled_days: treatListDays(data.cancellation_policy_enabled_days),
				rescheduling_policy: {
					enable: data.rescheduling_policy.enabled,
					hours_before_booking_expiration: data.rescheduling_policy.hours_before_booking_expiration,
				},
				recipient_id: business.id,
			};
			await advancePaymentService.updatePolicy(policy);
			setOpenSuccessModal(true);
		} catch (error) {
			handleFeedback("Erro", "Erro ao atualizar a política de cancelamento", "error");
			console.log(error);
		} finally {
			handleLoading(false);
		}
	};

	const treatListDays = (data) => {
		const listDays = [];
		Object.keys(data).forEach((key) => {
			if (data[key].active)
				listDays.push({ day: key, start: data[key].start, end: data[key].end, active: data[key].active });
		});
		return listDays;
	};

	useEffect(() => {
		disableOtherOptions();
	}, [watchAutomaticallyBooking, watchAnticipatedPayment, watchFixedCancellation]);

	const disableOtherOptions = (name, value) => {
		if (name === "automatically_booking") {
			setValue("automatically_booking", value);
			setValue("fixed_cancellation", false);
			setValue("anticipated_payment", false);
		} else if (name === "fixed_cancellation") {
			setValue("automatically_booking", false);
			setValue("fixed_cancellation", value);
			setValue("anticipated_payment", false);
		} else if (name === "anticipated_payment") {
			setValue("automatically_booking", false);
			setValue("fixed_cancellation", false);
			setValue("anticipated_payment", value);
		}
	};

	const handleModalOk = () => {
		if (page === null) {
			setTimeout(() => {
				setPage(nextPage);
				setNextPage(null);
			}, 500);
		}
		setOpenValidationModal(false);
	};

	useEffect(() => {
		if (firstLoad && !termsAccepted && !openValidationModal && !loading && page === null) {
			switch (nextPage) {
				case "PritPayTerms":
					setModalMessage(
						`Para modificar as políticas de cancelamento, você precisa aceitar os termos do 
						${getAppName(true)} e atualizar seus dados.`
					);
					break;
				case "Address":
					setModalMessage(`Para modificar as políticas de cancelamento, você precisa atualizar seu endereço.`);
					break;
				default:
					setModalMessage(`Para modificar as políticas de cancelamento, você precisa atualizar seus dados bancários.`);
			}
			setOpenValidationModal(true);
		}

		return () => {
			setFirstLoad(false);
		};
	}, [loading, firstLoad]);

	useEffect(() => {
		if (cancellationScreen) {
			reset();
			const percentage_to_be_charged = cancellationScreen.registration_data.percentage_to_be_charged[0].value;
			setValue("percentage_cancellation", percentage_to_be_charged);
			const cancellation_fee = cancellationScreen.registration_data.minimum_fare_amount[0].value;
			setValue("cancellation_fee", cancellation_fee);
			const cancellation_with_less_than = cancellationScreen.registration_data.cancellation_with_less_than[0].value;
			setValue("minimum_hours_before_booking", cancellation_with_less_than);
			const discount_percentage = cancellationScreen.registration_data.discount_percentage[0].value;
			setValue("discount", discount_percentage);
			const reschedule_id = rescheduleList[0].value;
			setValue("hours_before_booking_expiration", reschedule_id);

			const cancellation_policy = cancellationScreen.cancellation_policy;
			if (cancellation_policy) {
				if (cancellation_policy.automatically_booking) {
					setValue("automatically_booking", cancellation_policy.automatically_booking, {
						shouldValidate: true,
					});
					setValue(
						"percentage_cancellation",
						cancellation_policy.percentage_cancellation.percentage_cancellation_fee.toString()
					);
					setValue(
						"minimum_hours_before_booking",
						cancellation_policy.percentage_cancellation.hours_before_booking_expiration
					);
				}
				if (cancellation_policy.fixed_cancellation !== null) {
					setValue("fixed_cancellation", cancellation_policy.fixed_cancellation !== null, {
						shouldValidate: true,
					});
					setValue("cancellation_fee", cancellation_policy.fixed_cancellation.cancellation_fee);
					setValue(
						"minimum_hours_before_booking",
						cancellation_policy.fixed_cancellation.hours_before_booking_expiration
					);
				}
				if (cancellation_policy.anticipated_payment !== null) {
					setValue("anticipated_payment", cancellation_policy.anticipated_payment !== null, {
						shouldValidate: true,
					});
					setValue("discount", cancellation_policy.anticipated_payment.discount);
				}
				if (cancellation_policy.cancellation_policy_enabled_days) {
					cancellation_policy.cancellation_policy_enabled_days.forEach((item) => {
						const day = Object.keys(Schemas.AdvancePaymentSchema.listDays).find((day) => day === item.day);
						setValue(`cancellation_policy_enabled_days.${day}.active`, true, {
							shouldValidate: true,
						});
						setValue(`cancellation_policy_enabled_days.${day}.start`, item.start);
						setValue(`cancellation_policy_enabled_days.${day}.end`, item.end);
					});
				}
				if (cancellation_policy.rescheduling_policy) {
					setValue("rescheduling_policy.enable", cancellation_policy.rescheduling_policy.enable);
					setValue(
						"rescheduling_policy.hours_before_booking_expiration",
						cancellation_policy.rescheduling_policy.hours_before_booking_expiration
					);
				}
			}
		}
	}, [cancellationScreen]);

	const formIsInvalid = () => {
		if (watchAutomaticallyBooking || watchFixedCancellation || watchAnticipatedPayment) {
			const days = Object.values(watchEnabledDays || {}).some((day) => day?.active);
			return !days;
		}
		return true;
	};

	const getNameButton = () => {
		if (!cancellationPolicy?.contract) {
			return `Termos ${getAppName(true)}`;
		}
		if (!business.address.postalCode) {
			return "Atualizar endereço";
		}
		return "Dados bancários";
	};

	return (
		<div className="box-content" ref={containerRef}>
			<Text variant="mid" align="left" style={{ padding: "0 0 2rem", color: "#393762" }}>
				Cobrança antecipada
			</Text>

			<form
				className="form"
				style={{
					opacity: !termsAccepted ? 0.5 : 1,
				}}>
				<Inputs
					control={control}
					name="automatically_booking"
					label="Cobrar antecipado uma porcentagem do preço dos serviços."
					type="switch"
					checked={watchAutomaticallyBooking}
					getChange={disableOtherOptions}
					disabled={!termsAccepted}
				/>
				{watchAutomaticallyBooking && (
					<Grid container spacing={2} style={{ alignItems: "end" }}>
						<Grid item xs={12} md={6}>
							<Inputs
								control={control}
								name="percentage_cancellation"
								label="Porcentagem a ser cobrada:"
								type="select"
								options={cancellationScreen.registration_data.percentage_to_be_charged.map((item) => ({
									label: item.text,
									value: item.value,
								}))}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Inputs
								control={control}
								name="minimum_hours_before_booking"
								label="Não serão reembolsados cancelamentos com menos de"
								type="select"
								options={cancellationScreen.registration_data.cancellation_with_less_than.map((item) => ({
									label: item.text,
									value: item.value,
								}))}
							/>
						</Grid>
					</Grid>
				)}
				<Inputs
					control={control}
					name="fixed_cancellation"
					label="Cobrar antecipado uma tarifa mínima."
					type="switch"
					checked={watchFixedCancellation}
					getChange={disableOtherOptions}
					disabled={!termsAccepted}
				/>
				{watchFixedCancellation && (
					<Grid container spacing={2} style={{ alignItems: "end" }}>
						<Grid item xs={12} md={6}>
							<Inputs
								control={control}
								name="cancellation_fee"
								label="Valor a ser cobrado:"
								type="select"
								options={cancellationScreen.registration_data.minimum_fare_amount.map((item) => ({
									label: item.text,
									value: item.value,
								}))}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Inputs
								control={control}
								name="minimum_hours_before_booking"
								label="Não serão reembolsados cancelamentos com menos de"
								type="select"
								options={cancellationScreen.registration_data.cancellation_with_less_than.map((item) => ({
									label: item.text,
									value: item.value,
								}))}
							/>
						</Grid>
					</Grid>
				)}
				<Inputs
					control={control}
					name="anticipated_payment"
					label="Cobrar antecipado 100% do preço sem reembolso oferecendo um desconto."
					type="switch"
					checked={watchAnticipatedPayment}
					getChange={disableOtherOptions}
					disabled={!termsAccepted}
				/>
				{watchAnticipatedPayment && (
					<Inputs
						control={control}
						name="discount"
						label="Percentual de desconto"
						type="select"
						options={cancellationScreen.registration_data.discount_percentage.map((item) => ({
							label: item.text,
							value: item.value,
						}))}
					/>
				)}

				{errors.cancellation_policy_enabled_days && (
					<Text color="error" align="left" style={{ marginTop: "0.5rem" }}>
						{errors.cancellation_policy_enabled_days.message}
					</Text>
				)}

				{errors.root && (
					<Text color="error" align="left" style={{ marginTop: "0.5rem" }}>
						{errors.root.message}
					</Text>
				)}

				<Text align="left" style={{ margin: "1rem 0" }}>
					Escolha os dias e o intervalo de horário em que a cobrança antecipada é aplicável.
				</Text>

				<div className="advance-payment-days">
					<div className="days-list">
						{Object.keys(Schemas.AdvancePaymentSchema.listDays).map((item) => {
							const day = watchEnabledDays[item];
							return (
								<div className="day-item" key={item}>
									<div className="day-item-name">
										<Inputs
											control={control}
											name={`cancellation_policy_enabled_days.${item}.active`}
											type="checkbox"
											className="input-form-checkbox"
											disabled={!termsAccepted}
											checked={day.active}
										/>
										<Text>{day.nameTranslate}</Text>
									</div>

									<div className="day-item-time">
										<Inputs
											control={control}
											name={`cancellation_policy_enabled_days.${item}.start`}
											type="time"
											className="input-form-text-interval"
											value={"05:00"}
											disabled={!day.active}
										/>
										<Inputs
											control={control}
											name={`cancellation_policy_enabled_days.${item}.end`}
											type="time"
											className="input-form-text-interval"
											value={"23:00"}
											disabled={!day.active}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<Text variant="mid" align="left" style={{ padding: "5% 0 2% 0", color: "#393762" }}>
					Política de reagendamento
				</Text>

				<Inputs
					control={control}
					name="rescheduling_policy.enable"
					label="Permite fazer reagendamentos algumas horas antes do horário agendado."
					type="switch"
					checked={watchReschedulingPolicy.enable}
					disabled={!termsAccepted}
				/>

				{watchReschedulingPolicy.enable && (
					<Inputs
						control={control}
						name="rescheduling_policy.hours_before_booking_expiration"
						label="É permitido reagendar com:"
						type="select"
						options={rescheduleList}
					/>
				)}

				<Button
					type="button"
					title="Salvar"
					variant="contained"
					handleClick={handleSubmit(onSubmit)}
					className="btn-color-primary btn-default btn-save"
					fullWidth={true}
					disabled={formIsInvalid()}
				/>
			</form>
			<div className="form">
				{!termsAccepted && (
					<Button
						type="button"
						title={getNameButton()}
						variant="contained"
						handleClick={handleModalOk}
						className="btn-color-primary btn-default btn-save"
						fullWidth={true}
					/>
				)}
			</div>

			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Atenção</strong>}
				content={
					<Text>
						{modalMessage}
						<br />
						<br />
						Finalize o preenchimento
					</Text>
				}
				isOpen={openValidationModal}
				handleFirstBtn={() => handleModalOk()}
				handleSecondBtn={() => setOpenValidationModal(false)}
				lblBtnFirst="OK"
				lblBtnSecond="Cancelar"
			/>

			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Cobrança antecipada</strong>}
				content={<Text>As configurações de cobrança antecipada foram atualizadas com sucesso!</Text>}
				isOpen={openSuccessModal}
				handleFirstBtn={() => setOpenSuccessModal(false)}
				lblBtnFirst="OK"
			/>
		</div>
	);
};

export default AdvancePayment;
