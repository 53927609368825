import clsx from "clsx";
import React from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import { handlePhoneChange } from "utils";
import { maskBirthDate, maskCNPJ, maskCPF, maskCreditCard, maskExpirationDate, maskMoney, maskNumbers, maskZipCode } from "utils/masks";

import InputAutoComplete from "./input-auto-complete";
import InputCheckbox from "./input-checkbox";
import InputSelect from "./input-select";
import InputSwitch from "./input-switch";
import InputText from "./input-text";
import InputTextArea from "./input-textarea";
import SelectDDI from "./select-ddi";

import "./style.scss";

const Inputs = (
	props = {
		control: null,
		type: "text",
		inputRef: null,
		nextRef: null,
		value: "",
		variant: "outlined",
		placeholder: "Placeholder Text",
		label: "Input Text",
		ddi: null,
		handleKeyDown: () => {},
		style: {},
		inputProps: {},
		error: true,
		className: "",
		required: false,
		name: "input",
		helper: null,
		minRows: 4,
		options: [],
		checked: false,
		mask: null,
		disabled: false,
		getChange: () => {},
		handleBlur: () => {},
	},
	error = true
) => {
	const { business } = useSelector((store) => store.business);

	const [showError, setShowError] = React.useState(null);
	const handleChange = (onChange) => (e) => {
		let inputValue = e.target.value;
		if (props.type === "tel") {
			inputValue = handlePhoneChange(e, Number(props.ddi) ?? 1);
		}

		if (props.type === "money") {
			e.target.value = maskMoney(inputValue, business);
		}

		switch (props.name) {
			case "expiry":
				e.target.value = maskExpirationDate(inputValue);
				break;
			case "cpf":
				e.target.value = maskCPF(inputValue);
				break;
			case "creditCard":
				e.target.value = maskCreditCard(inputValue);
				break;
			case "zipcode":
				e.target.value = maskZipCode(inputValue);
				break;
			case "cnpj":
				e.target.value = maskCNPJ(inputValue);
				break;
			case "document":
				if (props.mask === "cnpj") {
					e.target.value = maskCNPJ(inputValue);
				} else {
					e.target.value = maskCPF(inputValue);
				}
				break;
			case "agency":
				e.target.value = maskNumbers(inputValue);
				break;
			case "account":
				e.target.value = maskNumbers(inputValue);
				break;
			case "birthDate":
				e.target.value = maskBirthDate(inputValue);
				break;
			default:
				break;
		}

		if (props.type === "checkbox" || props.type === "switch") {
			if (props.getChange) props.getChange(props.name, e.target.checked);
			return onChange(e.target.checked);
		}
		onChange(e.target.value);
		if (props.getChange) props.getChange(props.name, e.target.value);
	};

	const handleChangeAutoComplete = (onChange) => (option) => {
		onChange(option);
	};

	const selectTypeInput = (properties) => {
		if (props.type === "ddi") {
			return (
				<SelectDDI
					value={properties.value}
					handleChange={properties.handleChange}
					className={properties.className}
					style={props.style}
				/>
			);
		}
		if (props.type === "textarea") {
			return <InputTextArea {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		if (props.type === "checkbox") {
			return <InputCheckbox {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		if (props.type === "switch") {
			return <InputSwitch {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		if (props.type === "select") {
			return <InputSelect {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		if (props.type === "autocomplete") {
			return (
				<InputAutoComplete {...properties} className={clsx(props.className, showError && error && "input-error")} />
			);
		}
		return <InputText {...properties} className={clsx(props.className, showError && error && "input-error")} />;
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", width: "auto", gap: "2px", ...props.style }}>
			<Controller
				control={props.control}
				name={props.name}
				rules={props.required}
				defaultValue={props.value}
				render={({ field: { onChange, value }, fieldState: { isTouched, error } }) => {
					if (isTouched) {
						setShowError(error);
					}
					return selectTypeInput({
						label: props.label,
						value,
						variant: props.variant,
						type: props.type,
						placeholder: props.placeholder,
						inputRef: props.inputRef,
						handleChange: props.type === "autocomplete" ? handleChangeAutoComplete(onChange) : handleChange(onChange),
						handleKeyDown: props.handleKeyDown,
						style: props.style,
						inputProps: props.inputProps,
						className: props.className,
						showError: showError,
						mask: props.mask,
						options: props.options,
						defaultChecked: props.checked,
						checked: props.checked,
						onBlur: () => {
							if (props.handleBlur) props.handleBlur();
							setShowError(error);
						},
						disabled: props.disabled,
					});
				}}
			/>
			{props.helper && <small className="input-helper">{props.helper}</small>}
			{showError && error && <div className="label-error">{showError?.message}</div>}
		</div>
	);
};

export default Inputs;
