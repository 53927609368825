import payout from "./payout";

export const getPaymentList = (bookingTransactionId) => {
	return payout.get("payment/list/grouped/payments/" + bookingTransactionId);
};

export const getPaymentSubscriptionInfo = (recipientId) => {
	return payout.get(`payment/subscription/prit/${recipientId}`);
};

export const postPritPayRequest = (body) => {
	return payout.post("payment/request", body);
};

export const postPaymentBooking = (data) => {
	return payout.post("payment/pay/cash", data);
};

export const postPaymentAnticipatedAmount = (data, config) => {
	return payout.post("payment/anticipated-amount", data, config);
};

export const postPaymentList = (recipentId, data) => {
	payout.post(`payment/list/${recipentId}`, data);
};

export const getPaymentListGroupedByPaymentType = (recipientId, startDate, endDate) => {
	return payout.get(`payment/list/grouped/${recipientId}?startDate=${startDate}&endDate=${endDate}`);
};

export const postBookingsListByClientMenu = (data) => {
	return payout.post("payment/list-client", data);
};

export const postCancellationBooking = (data) => {
	return payout.post("payment/cancellation/values/business", data);
};

export const postRefundBusiness = (data) => {
	return payout.post("payment/refund/business", data);
};

export const postPaySubscription = (data) => {
	return payout.post("payment/pay-subscription", data);
};

export const postPaySubscriptionCredit = (data) => {
	return payout.post("payment/recurrence/subscription/prit", data);
};

export const putPaySubscriptionCredit = (data) => {
	return payout.put("payment/recurrence/subscription/prit", data);
};

export const getPaySubscriptionCredit = (recipientId) => {
	return payout.get(`payment/subscription/prit/${recipientId}`);
};

export const postRecurrenceTransactions = (data) => {
	return payout.post("payment/recurrence/subscription/prit/transactions", data);
};

export const postRecurrenceCancel = (businessId) => {
	return payout.post(`payment/recurrence/subscription/prit/cancel/${businessId}`);
};

export const fetchPaymentWithdrawInfo = (data) => {
	return payout.post("/payment/future-payments", data);
};

export const paymentWithdraw = (recipientId) => {
	return payout.get(`/transfer/generate/${recipientId}`);
};

const paymentService = {
	getPaymentList,
	postCancellationBooking,
	postRefundBusiness,
	postPaySubscription,
	postPaySubscriptionCredit,
	putPaySubscriptionCredit,
	postRecurrenceTransactions,
	postRecurrenceCancel,
	getPaySubscriptionCredit,
	fetchPaymentWithdrawInfo,
	paymentWithdraw,
};

export default paymentService;
