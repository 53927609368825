import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Text from '../texts';
import EditBooking from '../../views/editBooking';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dialog } from '@material-ui/core';
import { moneyMask } from '../../utils/utils';
import {postPaymentAnticipatedAmount} from "../../services/paymentService";
import './style.scss';

const ProfessionalDetailsComponent = ({  booking, onDelete, onUpdate, sales, paymentProcess, bookingList, onClick }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [bookingPaymentData, setbookingPaymentData] = useState({ data: {} });
  const [loadingList, setLoadingList] = useState(false);

  const bookingInfo = bookingList?.bookings[0];
  const bookingPayedData = bookingList;
  const { business } = useSelector((store)=> store.business);

  useEffect(() => {
    // if (bookingPayedData?.paymentStatus === 'partial' || bookingPayedData?.paymentStatus === 'requested' ) {
      setLoadingList(true);
      postPaymentAnticipatedAmount({
        recipient_id: business.id,
        booking_transaction_id: bookingPayedData.id,
      })
        .then((result) => {
          setLoadingList(false);
          setbookingPaymentData({ loading: false, data: result.data });
        })
        .catch(() => {
          setLoadingList(false);
        });
    
  }, [bookingPayedData?.paymentStatus, bookingPayedData?.id, business.id]);


  const formatDateTime = (start, end) => {
    if (start === null || end === null) {
      return {
        monthAbbrev: '--',
        dateFormatted: '--',
        timeFormatted: '--'
      };
    }
  
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    const monthNames = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
    const monthAbbrev = monthNames[startDate.getMonth()];
  
    const day = startDate.getDate().toString().padStart(2, '0');
    const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const dateFormatted = `${day}/${month}`;
  
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    const startTimeFormatted = startDate.toLocaleTimeString('pt-BR', timeOptions);
    const endTimeFormatted = endDate.toLocaleTimeString('pt-BR', timeOptions);
    const timeFormatted = `${startTimeFormatted} - ${endTimeFormatted}`;
  
    return {
      monthAbbrev,
      dateFormatted,
      timeFormatted,
    };
  };

  const serviceDurationCalculator = (startTime, endTime) => {
    if (!startTime || !endTime) return null;

    const start = new Date(startTime);
    const end = new Date(endTime);

    const serviceDuration = Math.round((end - start) / (1000 * 60));

    return serviceDuration;
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const { monthAbbrev, dateFormatted, timeFormatted } = sales ? 
  formatDateTime(bookingInfo?.startTime, bookingInfo?.endTime) : formatDateTime(booking.start_time, booking.end_time);

    return (
      sales ? (
        <div className='componentContainerSales' onClick={onClick}>
        
          <div className='serviceData'>
            <Text  variant={'body1'}>{monthAbbrev}</Text>
            <Text  variant={'body1'}>{dateFormatted}</Text>
            <Text  variant={'body2'}>{timeFormatted}</Text>
          </div>

          <div className='serviceDetails'>
            <Text style={{color: "#0B192C"}} >{bookingInfo?.client?.name}</Text>
            <Text variant={'body2'}>{truncateText(bookingInfo?.service?.name,25)}</Text>
            
            { paymentProcess ? (
              <div className='servicePaymentProcessContainer'>
                <div className='timePaymentProcessInfo'>
                  <AccessTimeIcon style={{width:"16px", color: "#616161"}}/>
                  <Text variant={'body1'}>{serviceDurationCalculator(bookingInfo?.startTime, bookingInfo?.endTime)} min</Text>
                </div>
                <div className='serviceValueContainer'>
                  <Text style={{color:"#3688EB", fontWeight: "bold"}}variant={'body1'}>{moneyMask(bookingInfo?.price, business?.currency)}</Text>
                </div>
              </div>
            ) : ( 
              <div className='serviceInfo'>
                <div className='paid'>
                  <Text variant={'body2'}  style={{marginRight: '0.2rem', color: "#9AA6B2"}}>A pagar:</Text>
                  {bookingPayedData?.paymentStatus === 'partial' || bookingPayedData?.paymentStatus === 'requested' &&
                    <Text variant={'body2'} style={{ marginRight: '0.2rem',  color: "#9AA6B2" }}>Pago:</Text>
                  }
                </div>
                  <div className='paid'> 
                    <Text style={{color:"#3688EB", fontWeight: "bold"}}variant={'body2'}> {moneyMask(bookingInfo?.price, business?.currency)}</Text>
                    {bookingPayedData?.paymentStatus === 'partial' || bookingPayedData?.paymentStatus === 'requested' &&
                      <Text style={{ color: "#3688EB", fontWeight: "bold" }}variant={'body2'}>
                        {moneyMask(bookingPaymentData?.data?.anticipation_payment, business?.currency)}
                      </Text>
                    }
                  </div>
              </div>
            )}
          </div>
        </div>

      ) : (
            <div className='componentContainer'>
              <div className='serviceData'>
                <Text  variant={'body1'} style={{color:"#fff"}}>{monthAbbrev}</Text>
                <Text  variant={'body1'} style={{color:"#fff"}}>{dateFormatted}</Text>
                <Text  variant={'body1'} style={{color:"#fff"}}>{timeFormatted}</Text>
              </div>

              <div className='serviceDetails'>
                <Text style={{color: "#0B192C"}}>{booking?.service?.service?.name}</Text>
                <Text variant={'body1'}>com {booking?.professional?.name}</Text>

                <div className='serviceInfo'>
                  <div>
                    <AccessTimeIcon style={{width:"16px", color: "#616161"}}/>
                    <Text variant={'body1'}>{booking.service.duration} min</Text>
                  </div>
                  <Text style={{color:"#3688EB", fontWeight: "bold"}}variant={'body1'}>{moneyMask(booking?.service?.price, business?.currency)}</Text>
                </div>

              </div>

              <section className='actionContainer'>
                <Text style={{color: '#3688EB'}} onClick ={()=> setIsEditing(true)}>Editar</Text>
                <Text style={{color: '#8E1616'}} onClick = {onDelete} >Excluir</Text>
              </section>

              <Dialog
              open={isEditing}
              fullScreen
              PaperProps={{
                style: { backgroundColor: "transparent" },
              }}
              >
                <EditBooking 
                  onClose={()=> setIsEditing(false)}  
                  booking={booking}
                  onSave={(updatedBooking) => {
                    onUpdate(updatedBooking);
                    setIsEditing(false);
                  }}
                />
              </Dialog>
            </div>
        )
  );
};

export default ProfessionalDetailsComponent;
