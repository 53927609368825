import React from "react";
import { TextField } from "@material-ui/core";

const InputText = ({
	label,
	value,
	variant = "outlined",
	type,
	placeholder,
	inputRef,
	handleChange,
	handleKeyDown,
	inputProps,
	onBlur,
	className,
	disabled,
}) => {
	return (
		<TextField
			inputRef={inputRef}
			value={value}
			variant={variant}
			type={type}
			placeholder={placeholder}
			label={label}
			onChange={handleChange}
			onBlur={onBlur}
			onKeyDown={handleKeyDown}
			inputProps={inputProps}
			className={className}
			disabled={disabled}
		/>
	);
};

export default InputText;
