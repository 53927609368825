import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ListClientSchema } from "helpers/schemas";
import { postClient } from "services/clientService";

import { isMobile } from "utils/isMobile";
import { useConfig } from "context/config.context";

import { getDdiNumber, trimTelephoneNumber } from "utils";
import { Button, Inputs } from "components";

import "./style.css";

export default function ClientForm({ handleUpdate, closeForm }) {
	const [loading, setLoading] = useState(false);

	const { handleFeedback } = useConfig();
	const { business } = useSelector((store) => store.business);

	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const businessNameRef = useRef(null);
	const telephoneRef = useRef(null);

	const {
		handleSubmit,
		control,
		watch,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(ListClientSchema.newClientSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: ListClientSchema.initialNewClientForm,
	});

	const onSubmit = async (data) => {
		setLoading(true);
		const formatPhone = `+${getDdiNumber(watch("ddi")) + trimTelephoneNumber(watch("phone"))}`;

		postClient({
			name: data.name,
			phone: formatPhone,
			email: data.email,
			birthday: null,
			addressCity: null,
			addressNeighborhood: null,
			addressNumber: null,
			addressPostalCode: null,
			addressState: null,
			addressStreet: null,
			addressUnit: null,
			businessId: business.id,
			avatarUrl: null,
			observation: null,
			active: true,
		})
			.then(() => {
				handleUpdate();
				setLoading(false);
				handleFeedback("Sucesso", "Cliente cadastrado com sucesso!", "success");
			})
			.catch((error) => {
				handleFeedback("Erro", error.response.data.message, "error");
				setLoading(false);
			});
	};

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				handleSubmit(onSubmit)();
			}
		}
	};

	return (
		<div className="side-client">
			<div className="box-client">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignContent: "stretch",
						width: isMobile() ? "100%" : "60%",
						gap: "16px",
					}}>
					<Inputs
						control={control}
						name="name"
						type="text"
						label="Nome completo"
						className="input-bg-white"
						inputRef={fullNameRef}
						handleKeyDown={(e) => handleKeyDown(e, emailRef)}
					/>

					<Inputs
						control={control}
						name="email"
						type="email"
						label="E-mail"
						className="input-bg-white"
						inputRef={emailRef}
						handleKeyDown={(e) => handleKeyDown(e, businessNameRef)}
					/>
					<div style={{ display: "flex", gap: "16px", width: "100%" }}>
						<Inputs control={control} name="ddi" type="ddi" className="selectFlag" />
						<Inputs
							control={control}
							name="phone"
							type="tel"
							label="Telefone"
							inputRef={telephoneRef}
							handleKeyDown={(e) => handleKeyDown(e, null)}
							placeholder="Ex: (11) 9 9300-0000"
							ddi={watch("ddi")}
							style={{ flex: 1, fontFamily: "Poppins" }}
							className="input-bg-white"
							inputProps={{
								pattern: "[0-9]*",
								inputMode: "numeric",
							}}
						/>
					</div>
					<div className="button-client-container">
						<Button
							type="button"
							title="Salvar"
							disabled={loading || !isValid}
							className={`btn-default ${loading || !isValid ? "btn-color-secondary" : "btn-color-primary"}`}
							handleClick={handleSubmit(onSubmit)}
						/>
						<Button
							type="button"
							title="Cancelar"
							disabled={loading}
							className={`btn-default ${loading ? "btn-color-secondary" : "btn-color-cancel"}`}
							handleClick={closeForm}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
