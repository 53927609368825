import api from "./api";

export const getDashboard = async ({ business_id, from_date, to_date, professional_id = null }) => {
	const result = await api.get("reports/dashboard", {
		params: {
			business_id,
			from_date,
			to_date,
			professional_id,
		},
	});

	return result.data;
};
