import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../store";
import Router from "../routes/routes";
import { mixpanelInitialize } from '../utils/mixpanel';
import "../style.css";
import "../styles/global/default.scss";

const App = () =>  {
	useEffect(() => {
		mixpanelInitialize();
	}, []);
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<div className="App">
					<Router />
				</div>
			</PersistGate>
		</Provider>
	);
}

export default App;
