import React from "react";

import { Dialog, Text } from "components";
import { getAppName } from "utils/validators";

const ModalUseTerms = ({ open = false, onClose, percentage }) => {
	return (
		<Dialog
			title={<strong style={{ fontSize: "1.5rem" }}>Termos de uso e credenciamento de pagamentos online</strong>}
			noHeight={false}
			content={
				<Text style={{ textAlign: "justify", lineHeight: "1.3rem" }}>
					Estas regras de uso aplicam-se à utilização do meio de pagamento do aplicativo {getAppName()} (adiante denominado{" "}
					<span className="text-uppercase">{getAppName(true)}</span>) oferecido pela PRIT Gestão de Negócios Ltda, a
					qualquer pessoa que atenda todos os requisitos destas regras de uso (adiante denominado “Usuário” ou no plural
					&quot;Usuários&quot;). O Usuário deverá ler, certificar-se de haver entendido e aceitar todas as condições
					estabelecidas nestas regras de uso, assim como nos demais documentos incorporados por referência.
					<br />
					<br />
					<strong>1. Objeto</strong>
					<br />
					<br />
					1.1. O {getAppName()} oferece aos Usuários, por si ou terceiros, o meio de pagamento online (
					<span className="text-uppercase">{getAppName(true)}</span>) pelo aplicativo {getAppName(false, true)} que processa
					pagamentos das principais bandeiras de cartão, através da transmissão e processamento das transações.
					<br />
					<br />
					<strong>2. Adesão</strong>
					<br />
					<br />
					2.1. O início do processo de adesão do Usuário ao <span className="text-uppercase">
						{getAppName(true)}
					</span>{" "}
					ocorre meio de auto credenciamento via aplicativo {getAppName(false, true)}, ou ainda, por outros canais que vierem a ser
					disponibilizados.
					<br />
					2.1.1. As informações fornecidas pelo Usuário no processo de compra serão consideradas para definição do
					cadastro principal de credenciamento, assim como o domicílio bancário indicado para o recebimento das
					transações.
					<br />
					2.2. O efetivo credenciamento e a adesão do Usuário poderá ser condicionado à aceitação prévia do{" "}
					{getAppName()}, mediante apresentação pelo Usuário, quando necessário, de documentos solicitados, que serão
					utilizados para análise das atividades por ele desenvolvidas, da sua saúde financeira e de seus
					sócios/representantes/proprietários/acionistas, dentre outros critérios de análise cadastral e financeira que
					venham a ser adotados, a qualquer tempo durante a vigência do presente Termo.
					<br />
					2.3. Caso o {getAppName()} avalie que os critérios de análise cadastral e financeira não foram atingidos ou
					completos, tais como, informações incorretas, incompletas e/ou inverídicas, não envio de forma satisfatória de
					documentos e esclarecimentos solicitados, poderão bloquear, suspender, cancelar e/ou revogar o credenciamento
					do Usuário ou a aprovação e a realização de quaisquer transações ou movimentações, sem prejuízo de outras
					medidas de acordo com o previsto no presente Termo, e eventual responsabilidade por perdas e danos.
					<br />
					2.4. O Usuário se compromete a não efetuar transações em segmentos ou ramos de atividades diversos daqueles
					informados pelo Usuário no momento de seu credenciamento, mesmo que tais atividades constem de seu objeto
					social. <br />
					2.5. Determinados Usuários não serão credenciados ou serão descredenciados por exercerem atividades
					consideradas ilegais ou indesejáveis.
					<br />
					<br />
					3. Domicílio Bancário
					<br />
					<br />
					3.1. O Usuário indicará no processo de credenciamento um domicílio bancário de mesma titularidade para o
					recebimento do valor líquido das transações.
					<br />
					3.1.1. Os valores das transações com o <span className="text-uppercase">{getAppName(true)}</span> realizadas
					por meio de cartão de crédito serão repassados pelo {getAppName()} por transferência bancária ao domicílio
					bancário informado na sexta-feira posterior ao prazo D+30 da referida transação.
					<br />
					3.2. O Usuário se obriga a zelar pela regularidade do domicílio bancário, como também, pela retificação das
					informações prestadas ao {getAppName()}. Caso o banco depositário do domicílio bancário declare-se impedido,
					por qualquer motivo, de dar cumprimento às ordens de crédito emitidas pelo {getAppName()}, o usuário ficará
					encarregado de providenciar a regularização do domicílio bancário ou, ainda, indicar novo domicílio,
					informando tal providência ao {getAppName()}, que estarão autorizados a reter o pagamento dos créditos até a
					regularização do domicílio bancário, sem quaisquer ônus, penalidades ou encargos e à providenciar o bloqueio
					do equipamento, caso o domicílio bancário não seja regularizado pelo usuário, no prazo concedido pelo{" "}
					{getAppName()}.
					<br />
					3.3. Em caso de término da relação estabelecida entre a {getAppName()} e o Usuário por qualquer motivo, o
					Usuário compromete-se a manter ativo seu domicílio bancário até que todas as transações sejam quitadas,
					incluindo transações de crédito parceladas.
					<br />
					3.4. Caso o banco depositário do domicílio bancário se declare impedido, por qualquer motivo, de dar
					cumprimento às ordens de crédito emitidas pelo {getAppName()}, deverá o Usuário providenciar a regularização
					do domicílio bancário ou ainda, indicar novo domicílio bancário. Neste caso, o Usuário deverá informar tal
					providência ao {getAppName()}, que estarão autorizados a reter o pagamento dos créditos até o recebimento do
					pedido de mudança e regularização do domicílio bancário pelo Usuário ou o respectivo processamento, sem
					quaisquer ônus, penalidades ou encargos.
					<br />
					<br />
					<strong>
						4. Condições para o uso <span className="text-uppercase">{getAppName(true)}</span>
					</strong>
					<br />
					<br />
					4.1. O <span className="text-uppercase">{getAppName(true)}</span> poderá ser utilizado pelos Usuários que
					possuam uma conta no aplicativo {getAppName(false, true)}, pelo qual serão registrados os recebíveis provenientes das
					transações de pagamento.
					<br />
					4.2. A qualquer momento, o {getAppName()} poderá alterar a relação dos cartões aceitos.
					<br />
					4.3. O usuário reconhece e concorda que o {getAppName()} poderá a qualquer momento atualizar, aprimorar,
					adicionar ou corrigir recursos tecnológicos, incluindo, mas não se limitando à atualização, limitação ou
					alteração do software. <br />
					4.4. O {getAppName()} poderá suspender, bloquear e/ou cancelar, temporária ou definitivamente, qualquer
					usuário, independente de notificação prévia, caso conclua, a seu exclusivo critério, que alguma das transações
					realizadas pelo usuário com a utilização dos meios de pagamento oferecidos (i) caracterize alguma atividade
					proibida por lei ou por regulamentação aplicável; (ii) seja contrária ao disposto nos Termos de Uso do{" "}
					{getAppName()}, ou ainda, caso o {getAppName()} tome conhecimento ou suspeite de qualquer ilegitimidade,
					fraude ou qualquer outro ato que possa atentar contra a imagem da empresa, sem prejuízo de que sejam
					eventualmente adotadas as medidas judiciais ou extrajudiciais pertinentes. <br />
					<br />
					<strong>5. Preço, pagamento e tarifas</strong>
					<br />
					<br />
					5.1. Os custos, tarifas e formas de pagamentos das transações com uso do{" "}
					<span className="text-uppercase">{getAppName(true)}</span> serão de {percentage / 100}% sobre o valor da
					transação realizada por cartão de crédito pelo pagador, ou 1% sobre o valor da transação caso o pagamento seja
					realizado por meio de transação pix. O Usuário está ciente que aderirá às condições comerciais dispostas a
					partir da primeira transação com o <span className="text-uppercase">{getAppName(true)}</span>, e serão
					cobradas conforme condições publicadas neste termo. Fica facultada ao {getAppName()} alterar as condições a
					qualquer tempo mediante simples comunicação pelos canais de atendimento.
					<br />
					5.2. O Usuário se obriga a remunerar o {getAppName()} pelo uso do{" "}
					<span className="text-uppercase">{getAppName(true)}</span>. Desta forma, o {getAppName()} está autorizado a
					debitar dos recebíveis os custos e tarifas oriundos de cada movimentação.
					<br />
					5.3. O Usuário reconhece e concorda que os pagamentos efetuados por meio do{" "}
					<span className="text-uppercase">{getAppName(true)}</span> serão disponibilizados no domicílio bancário
					indicado pelo Usuário no momento do cadastro no Portal.
					<br />
					5.4. Se a data prevista para o pagamento das transações recair em feriado, seja nacional ou regional, em dia
					de não funcionamento bancário na praça da sede do {getAppName()}, ou na praça de compensação do domicílio
					bancário do Usuário, o pagamento será realizado no primeiro dia útil subsequente.
					<br />
					5.5. O {getAppName()} poderá enviar periodicamente, à instituição financeira do domicílio bancário do Usuário,
					a agenda contendo a previsão de recebíveis decorrentes de transações do Usuário.
					<br />
					5.6. Nos casos em que se verificar a iliquidez, insolvência, pedido de recuperação judicial ou extrajudicial,
					estado pré-falimentar, encerramento de atividades ou qualquer outra hipótese em que ficar caracterizada a
					dificuldade do Usuário em cumprir suas obrigações contratuais e/ou legais, o {getAppName()} reserva-se o
					direito de reter os créditos a ele devidos, a fim de garantir o cumprimento de suas obrigações previstas no
					presente Termo e a segurança do mercado de meios de pagamentos.
					<br />
					5.7. O Usuário terá o prazo máximo de 30 (trinta) dias, a contar da data prevista para a realização do
					pagamento pelo {getAppName()}, para apontar qualquer eventual divergência em relação aos valores pagos e/ou
					debitados. Findo tal prazo, não caberá nenhuma outra reclamação por parte do Usuário, ocorrendo a quitação
					automática e definitiva quanto aos referidos valores.
					<br />
					5.7.1 Em casos excepcionais, o {getAppName()}, a seu exclusivo critério, findo o prazo estipulado acima e
					respeitados os prazos de cobrança estipulados em Lei, verificar a existência de divergência nos créditos ou
					débitos apontados pelo Usuário e, conforme o caso, efetuar os créditos ou débitos em seu domicílio bancário.
					<br />
					<br />
					<strong>6. Obrigações do Usuário</strong>
					<br />
					<br />
					6.1. O usuário não poderá:
					<br />
					a) Usar o <span className="text-uppercase">{getAppName(true)}</span> para finalidade diversa da descrita
					nestes Termos de Uso;
					<br />
					b) Violar estes Termos de uso e qualquer outro documento a ele vinculado disponibilizado pelo {getAppName()},
					bem como qualquer legislação municipal, estadual ou federal aplicável;
					<br />
					c) Disseminar ou tentar enviar vírus de computador ou qualquer outro código ou programa invasivo ou malicioso;
					<br />
					d) Copiar ou adaptar os códigos-fonte e objeto do <span className="text-uppercase">
						{getAppName(true)}
					</span>{" "}
					ou de qualquer outro sistema disponibilizado pelo {getAppName()} ou qualquer software a ele relacionado;
					<br />
					e) Fazer ou tentar fazer engenharia reversa, decompilação, modificação ou qualquer alteração nos códigos-fonte
					e objeto do <span className="text-uppercase">{getAppName(true)}</span> ou qualquer software relacionado;
					<br />
					f) Interferir ou quebrar qualquer sistema de segurança do{" "}
					<span className="text-uppercase">{getAppName(true)}</span>, especialmente aqueles com a finalidade de limitar
					e controlar o seu acesso e uso;
					<br />
					g) Utilizar o <span className="text-uppercase">{getAppName(true)}</span> para aceitar pagamentos que infrinjam
					leis e regulamentos aplicáveis, bem como utilizem frames ou métodos para copiar (mirror) qualquer conteúdo que
					faça parte do <span className="text-uppercase">{getAppName(true)}</span>, seja para utilização em sua
					intranet, para fins comerciais, internos ou qualquer outra utilização;
					<br />
					h) Utilizar o <span className="text-uppercase">{getAppName(true)}</span> para aceitar transações de pagamento
					com cartões, cujo titular seja o próprio usuário ou sua empresa;
					<br />
					i) Armazenar, reproduzir, copiar dados fornecidos relativos ao cartão utilizado para processar pagamentos
					realizados no <span className="text-uppercase">{getAppName(true)}</span>;
					<br />
					j) Armazenar, usar ou transmitir informação pessoal de terceiros obtida por meio das operações processadas com
					o <span className="text-uppercase">{getAppName(true)}</span>.
					<br />
					6.2. O usuário deverá:
					<br />
					a) Cumprir as leis e regulamentações aplicáveis, além de observar integralmente os Termos de Uso vigentes do
					<span className="text-uppercase">{getAppName(true)}</span>;
					<br />
					b) Responsabilizar-se integralmente pelo uso do <span className="text-uppercase">{getAppName(true)}</span>,
					bem como por sua atividade comercial, independentemente dos bens ou serviços oferecidos;
					<br />
					c) Utilizar o <span className="text-uppercase">{getAppName(true)}</span> de acordo com as regras do cartão,
					devendo saber que o {getAppName()} não armazena os dados dos cartões dos clientes, exceto o dado básico para
					vinculação do cliente ao cartão;
					<br />
					d) Observar as leis e normativas aplicáveis a respeito de toda informação que receba em virtude do uso do{" "}
					<span className="text-uppercase">{getAppName(true)}</span>, incluindo a observância, quando aplicável, das
					normas previstas no PCI-DSS, além do tomar todas as medidas de segurança necessárias para evitar ações
					fraudulentas;
					<br />
					e) Comunicar imediatamente o {getAppName()} na hipótese de suspeita de fraude ou lavagem de dinheiro;
					<br />
					f) Estar ciente que o uso do <span className="text-uppercase">{getAppName(true)}</span> pelo Usuário implica o
					cumprimento das regras e determinações pelas credenciadoras, bem como autorização automática para que o {getAppName()},
					sempre que julgarem necessário e inclusive por meio de participantes da indústria de cartões ou terceiros por
					eles credenciados: (i) verifiquem a regularidade da sua constituição societária, podendo para tanto solicitar
					documentos adicionais; e/ou (ii) avaliem o cumprimento das práticas de aceitação do {getAppName()}.
					<br />
					<br />
					<strong>7. Limitação de responsabilidade</strong>
					<br />
					<br />
					7.1. O {getAppName()} não tem e não terá qualquer responsabilidade pela exclusão, perda, erro de armazenamento,
					interrupções ou falha de transmissão. O Usuário declara que concorda em isentar o {getAppName()}, suas empresas
					controladas, controladoras ou coligadas, bem como seus respectivos administradores, prepostos, funcionários e
					consultores de quaisquer responsabilidades, demandas, ações judiciais ou extrajudiciais, pedidos ou
					solicitações de terceiros decorrentes da utilização <span className="text-uppercase">{getAppName(true)}</span>
					, incluindo despesas processuais e honorários advocatícios. Declara ainda concordar com o desconto em seus
					recebíveis para a reparação de eventuais prejuízos ou danos que venha causar ao {getAppName()} e/ou terceiros.
					<br />
					7.2. Observada a limitação de responsabilidade prevista neste instrumento, com o qual o Usuário concorda em
					aceitá-la, a responsabilidade do {getAppName()} por outros atos ou fatos causadores de danos limitar-se-ão aos danos
					diretos comprovadamente sofridos pelos Usuários por ato ilícito exclusivo do {getAppName()}. O {getAppName()} não poderá ser
					responsabilizados por danos indiretos, lucros cessantes, perda de dados ou de receitas decorrentes de falhas
					do <span className="text-uppercase">{getAppName(true)}</span>.
					<br />
					<br />
					<strong>8. Confidencialidade</strong>
					<br />
					<br />
					8.1. O Usuário compromete-se a manter em absoluto sigilo e confidencialidade todas as Informações
					Confidenciais. No caso de sua eventual violação ou divulgação, inclusive por atos de seus funcionários ou
					terceiros, o Usuário será responsável pelo ressarcimento das perdas e danos ocasionados, incluindo danos
					emergentes, lucros cessantes, custas judiciais e honorários advocatícios.
					<br />
					8.2. O {getAppName()} compromete-se a manter a confidencialidade de dados das transações efetuadas pelo Usuário, exceto
					quando solicitados por ordem judicial, exigidos por lei e/ou prestadores de serviço do {getAppName()}. O {getAppName()} poderá
					prestar às autoridades competentes, tais como o Ministério da Fazenda, o BACEN, a Receita Federal, as
					Secretarias da Fazenda Estaduais e Municipais, Comissões Parlamentares de Inquérito, Órgãos de Controle de
					Atividades Financeiras, Polícia Federal etc., todas as informações que forem solicitadas em relação ao Usuário
					ou quaisquer dados relativos às transações efetuadas pelo Usuário.
					<br />
					8.3. O Usuário, de forma irrevogável e irretratável, autoriza a {getAppName()} e/ou as empresas pertencentes ao seu
					conglomerado a:
					<br />
					(I) trocarem entre si as informações confidenciais e demais informações, assim como consultar e/ou confirmar a
					exatidão das mesmas em websites e bancos de dados em geral;
					<br />
					(II) compartilhar as informações confidenciais e demais informações com os emissores, com o banco depositário
					do domicílio bancário e os instituidores de arranjo de pagamento;
					<br />
					(III) compartilhar informações confidenciais e demais informações com seus parceiros comerciais e prestadores
					de serviços, no brasil ou no exterior, para fins de avaliação de crédito, verificação e gestão de risco e
					fraude;
					<br />
					(IV) utilizar suas informações confidenciais e demais informações para formação de banco de dados, bem como
					sua divulgação a qualquer título, desde que de forma generalizada, agrupada com informações de outros Usuários
					e não individualizada; <br />
					(V) compartilhar as informações confidenciais e demais informações com as autoridades reguladoras da indústria
					de meios de pagamento, com autoridades tributárias federais, estaduais e municipais, com agências de crédito
					oficiais ou privadas, por determinação em legislação, regulamentação de mercado, em medidas judiciais, entre
					outras; <br />
					(VI) comunicar ao BACEN e ao COAF – Conselho de Controle de Atividades Financeiras as transações que possam
					estar configuradas no disposto na lei 9.613/98 e demais normas relativas à lavagem de dinheiro, incluindo as
					normas e políticas internas do {getAppName()} nesse sentido; e<br />
					(VII) informar a órgãos de proteção de crédito, tais como SERASA e SPC, os dados relativos à falta de
					pagamento de obrigações assumidas pelo Usuário junto ao {getAppName()}.
					<br />
					<br />
					<strong>9. Prazo</strong>
					<br />
					<br />
					9.1. Este Termo vigorará por tempo indeterminado, a contar da aceitação e inclusão do Usuário ao Sistema do
					<span className="text-uppercase">{getAppName(true)}</span> por meio do credenciamento.
					<br />
					<br />
					<strong>10. Disposições gerais</strong>
					<br />
					<br />
					10.1. O credenciamento do Usuário acarreta aos Estabelecimentos a aceitação total e irrestrita deste Termo.
					<br />
					10.2. Direitos de Propriedade Intelectual do {getAppName()}. Todos e quaisquer direitos de propriedade intelectual ou
					industrial decorrentes e/ou relativos aos serviços descritos nestes Termos, ao Site, ao Aplicativo, incluindo,
					sem limitação, direitos autorais patrimoniais, pertencem única e exclusivamente ao {getAppName()}. Em nenhuma hipótese o
					estes Termos implicam transferência, no todo ou em parte, de qualquer direito de propriedade intelectual ou
					industrial pelo {getAppName()} para o Estabelecimento. O Usuário expressamente reconhece e concorda que as regras serão
					aplicáveis, mutatis mutandis, a todo e qualquer software cujo uso venha a ser licenciado pelo {getAppName()} ao
					Estabelecimento no âmbito da prestação dos serviços descritos nestes Termos, incluindo, sem limitação, o
					Portal.
					<br />
					10.3. Partes Autônomas e Independentes. A relação jurídica estabelecida entre as Partes é de prestação de
					serviços, de modo que estes Termos não estabelecem relação de consumo, trabalho, terceirização de trabalho,
					representação comercial ou de qualquer outra natureza entre as Partes e entre as pessoas que colaboram com as
					Partes, sendo certo que as Partes são e permanecerão a todo tempo autônomas e independentes entre si.
					<br />
					10.4. Proibição de Cessão. Os direitos e as obrigações do Usuário previstos nestes Termos não poderão ser
					cedidos ou de qualquer outra forma transferidos, no todo em parte, pelo Usuário a quaisquer terceiros sem a
					prévia concordância por escrito do {getAppName()}.
					<br />
					10.5. Irrevogabilidade e Tolerância. As relações jurídicas estabelecidas por estes Termos são celebradas em
					caráter irrevogável e irretratável, obrigando as Partes e seus sucessores, seja qual for o título da sucessão.
					A eventual tolerância por qualquer das Partes quanto ao inexato ou impontual cumprimento das obrigações da
					outra Parte valerá tão-somente de forma isolada, não constituindo renúncia ou novação de qualquer espécie.
					<br />
					10.6. Nulidade ou Ineficácia. Caso qualquer disposição destes Termos se torne nula ou ineficaz, a validade ou
					eficácia das disposições restantes não será afetada, permanecendo em pleno vigor e efeito e, em tal caso, as
					Partes entrarão em negociações de boa-fé visando a substituir a disposição ineficaz por outra que, tanto
					quanto possível e de forma razoável, atinja a finalidade e os efeitos desejados.
					<br />
					10.7. Totalidade dos Entendimentos. As disposições contidas no Contrato e nestes Termos e Condições
					representam a totalidade dos entendimentos mantidos entre as Partes relativamente aos assuntos de que ele
					trata, superando todos e quaisquer entendimentos anteriores, verbais ou escritos, havidos entre as Partes,
					consubstanciando-se na declaração final de suas vontades.
					<br />
					10.8. Comunicações. As comunicações citadas nestes Termos ou feitas com relação a eles deverão ser feitas por
					escrito em qualquer canal disponibilizado pelo {getAppName()}, inclusive por e-mail.
					<br />
					10.9. Demais condições operacionais e/ou cadastrais para execução do objeto do presente Termo estarão
					disponíveis no Portal e serão aplicáveis a partir da adesão ao presente Termo.
					<br />
					10.10. Este instrumento será regido e interpretado de acordo com a legislação brasileira, sendo eleito o Foro
					da Comarca de Ribeirão Preto, Estado de São Paulo, para questões a ele relativas, com renúncia expressa a
					qualquer outro.
					<br />
					10.11. O Usuário concorda que o {getAppName()} poderá alterar estes Termos de Uso e demais documentos a ele atrelados
					unilateralmente com simples notificação no site do {getAppName()}. Cabe ao Usuário visitar estes Termos de Uso de tempos
					em tempos e, se não concordar com alguma disposição, poderá, a seu critério, deixar de usar o{" "}
					<span className="text-uppercase">{getAppName(true)}</span>.
				</Text>
			}
			isOpen={open}
			handleFirstBtn={onClose}
			handleSecondBtn={onClose}
			lblBtnFirst="Fechar"
		/>
	);
};

export default ModalUseTerms;
