import React, { useState } from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";

import { getAuth } from "firebase/auth";
import clientAction from "../../store/actions/clientAction";
import clientService from "../../services/clientService";

import { ButtonCore, Container } from "components";
import { useConfig } from "context/config.context";

const NewClientDialog = (props) => {
	const auth = getAuth();
	const dispatch = useDispatch();

	const { me } = useSelector((state) => state.me);
	const { businessId, myProfessionalId, onClose, selectClient } = props;

	const [, setOpenMessage] = useState(false);
	const [, setMessage] = useState("Erro desconhicido, tente novamente...");

	const [newClient, setNewClient] = useState({});
	const {  handleLoading, loading } = useConfig();
	const [error, setError] = useState(false);

	const telephoneNumberFormat = (number) => {
		let formated = "";

		for (var i = 3; i < number?.length; i++) {
			if (i === 3) {
				formated += "(";
			}
			if (i === 5) {
				formated += ")";
			}
			if (i === 10) {
				formated += "-";
			}
			formated += number.charAt(i);
		}

		return formated;
	};

	const saveClient = async () => {
		if (!businessId || !auth?.currentUser) return;
		handleLoading(true);
	
		const body = { ...newClient, businessId };
	
		const handleSuccess = async (client) => {
			if (businessId && myProfessionalId) {
				dispatch(clientAction.getClients(businessId, myProfessionalId));
			}
		
			selectClient(client);
			handleLoading(false);
			onClose();
		};
		
		const handleError = (errorMessage) => {
			setMessage(errorMessage || 'Erro ao salvar cliente');
			setOpenMessage(true);
			handleLoading(false);
		};
	
		try {
			const response = await clientService.postClient(body, myProfessionalId);
			if (response.status === 200) {
				handleSuccess(response.data);
			}
		} catch (error) {
			const errorMessage = error?.data?.message || 'Erro inesperado';
			handleError(errorMessage);
		}
	};

	return (
		<Container style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex" }}>
				<ArrowBackIos style={{ width: "30px", color: "#616161", cursor: 'pointer' }} onClick={props.onClose} />
				<Typography style={{ color: "#3E3E3E", fontSize: "18px", fontWeight: "bold", marginBottom: "8px" }}>
					Criar novo cliente
				</Typography>
			</div>
			<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}>Nome</Typography>
			<TextField
				value={newClient.name}
				onChange={(e) => {
					setNewClient({ ...newClient, name: e.target.value });
				}}
				style={{ width: "100%" }}
				error={error && (!newClient?.name || newClient?.name?.length === 0)}
				helperText={error && (!newClient?.name || newClient?.name?.length === 0) ? "Este campo é obrigatório" : ""}
				required
				variant="outlined"></TextField>
			<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}>
				Telefone
			</Typography>
			<TextField
				value={telephoneNumberFormat(newClient?.phone)}
				onChange={(e) => {
					e.target.maxLength = 14;
					setNewClient({ ...newClient, phone: "+55" + e.target.value.replace(/\D/g, "") });
				}}
				InputProps={{
					startAdornment: newClient?.phone && <InputAdornment position="start">+55 </InputAdornment>,
				}}
				error={error && (!newClient?.phone || newClient?.phone?.length === 0)}
				helperText={error && (!newClient?.phone || newClient?.phone?.length === 0) ? "Este campo é obrigatório" : ""}
				required
				variant="outlined"
				style={{ width: "100%" }}></TextField>
			<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}>
				E-mail (opcional)
			</Typography>
			<TextField
				value={newClient.email}
				onChange={(e) => {
					setNewClient({ ...newClient, email: e.target.value });
				}}
				variant="outlined"
				style={{ width: "100%" }}></TextField>

			<div style={{ flex: 1 }}></div>

			<ButtonCore
				onClick={() => {
					if (!newClient?.name || !newClient?.phone || newClient?.name?.length === 0 || newClient?.phone?.length === 0) {
						setError(true);
					} else {
						saveClient();
					}
				}}
				color={"#3688EB"}
				text="Salvar"></ButtonCore>
		</Container>
	);
};

export default NewClientDialog;
