import * as Yup from 'yup';

export const paymentTypes = () =>{
	const paymentOptions = [
		{ label: 'Cartão de Crédito', value: 'creditprit'},
		{ label: 'Cartão de débito', value: 'debitprit'},
		{ label: 'Pix', value: 'pixprit', },
		{ label: 'Dinheiro', value: 'cash'},
		{ label: 'Prit Pay', value:'pritpay'}
	];

	const data = {};
	data.paymentOptions = paymentOptions;

	return data;
};

export const selectPayment = Yup.object({
  paymentOptions: Yup.string().required("Este campo é obrigatório.").oneOf(['creditprit', 'debitprit', 'cash', 'pritpay','pixprit'],"Selecione um valor válido.")
});

export const initialSelectPaymentDefault = {
  paymentOptions: 'pixprit'
};
