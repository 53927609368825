import * as Yup from "yup";

import { validaCNPJ, validateBirthDate, validateCPF, validateMonthlyIncome } from "utils/validators";

export const listDays = {
	Sunday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Domingo" },
	Monday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Segunda" },
	Tuesday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Terça" },
	Thursday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Quarta" },
	Wednesday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Quinta" },
	Friday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Sexta" },
	Saturday: { active: false, start: "05:00:00", end: "23:00:00", nameTranslate: "Sábado" },
};

export const pritPayTermsSchema = Yup.object().shape({
	enablePix: Yup.boolean().required("O PIX é obrigatório"),
	enableCreditCard: Yup.boolean().required("O cartão de crédito é obrigatório"),
	termsAccepted: Yup.boolean().test(
		"terms-accepted",
		"Você precisa aceitar os termos de uso e habilitar ao menos uma forma de pagamento",
		(value, context) => {
			let result = false;
			if (!value) return false;
			if (context.parent.enablePix || context.parent.enableCreditCard) {
				result = true;
			}
			return result;
		}
	),
});

export const initialPritPayTermsForm = {
	enablePix: true,
	enableCreditCard: true,
	termsAccepted: false,
};

export const stripeAccountSchema = Yup.object().shape({
	name: Yup.string().required("O nome é obrigatório"),
	companyName: Yup.string().required("O nome da empresa é obrigatório"),
	email: Yup.string().required("O email é obrigatório"),
});

export const initialStripeAccountForm = {
	name: "",
	companyName: "",
	recipient_id: "",
	email: "",
	stripeLink: "",
	currency: "",
};

export const advancePaymentSchema = Yup.object()
	.shape({
		automatically_booking: Yup.boolean(),
		fixed_cancellation: Yup.boolean(),
		anticipated_payment: Yup.boolean(),
		percentage_cancellation: Yup.string().when("automatically_booking", {
			is: true,
			then: () => Yup.string().required(),
		}),
		cancellation_fee: Yup.string().when("fixed_cancellation", {
			is: true,
			then: () => Yup.string().required(),
		}),
		discount: Yup.string().when("anticipated_payment", {
			is: true,
			then: () => Yup.string().required(),
		}),
		minimum_hours_before_booking: Yup.string(),
		cancellation_policy_enabled_days: Yup.object().test(
			"at-least-one-day",
			"Selecione pelo menos um dia da semana",
			function (value) {
				if (!this.parent.automatically_booking && !this.parent.fixed_cancellation && !this.parent.anticipated_payment)
					return true;
				return Object.values(value || {}).some((day) => day?.active);
			}
		),
		rescheduling_policy: Yup.object().shape({
			enable: Yup.boolean(),
			hours_before_booking_expiration: Yup.string().when("enable", {
				is: true,
				then: () => Yup.string().required(),
			}),
		}),
	})
	.test("options-validation", "Selecione ao menos uma das opções de cobrança.", function (value) {
		return value.automatically_booking || value.fixed_cancellation || value.anticipated_payment;
	});

export const initialAdvancePaymentForm = {
	automatically_booking: false,
	maximum_months_future_booking: 0,
	minimum_hours_before_booking: 0,
	percentage_cancellation: null,
	fixed_cancellation: null,
	anticipated_payment: null,
	cancellation_policy_enabled_days: listDays,
	rescheduling_policy: {
		enable: false,
		hours_before_booking_expiration: 0,
	},
};

export const newBankDetailsSchema = Yup.object().shape({
	documentType: Yup.boolean().required("O tipo de documento é obrigatório"),
	document: Yup.string()
		.required("O CPF/CNPJ é obrigatório")
		.test("validate-document", "CPF/CNPJ inválido", (value, context) => {
			const { documentType } = context.parent;
			if (!documentType) {
				return validateCPF(value);
			}
			return validaCNPJ(value);
		}),
	name: Yup.string().required("O nome do titular é obrigatório"),
	bankName: Yup.object()
		.required("O nome do banco é obrigatório")
		.test("validate-bank-name", "Selecione um banco", (option) => {
			return option.value !== "0";
		}),
	agency: Yup.string().required("Campo obrigatório"),
	accountType: Yup.string().required("Campo obrigatório"),
	accountNumber: Yup.string().required("Campo obrigatório"),
	accountDigit: Yup.string().required("Campo obrigatório"),
	birthDate: Yup.string()
		.required("A data de nascimento é obrigatória")
		.test("validate-birth-date", "A data de nascimento deve ser anterior a data atual e maior que 18 anos", (value) =>
			validateBirthDate(value)
		),
	job: Yup.string().required("Sua ocupação é obrigatória"),
	monthlyIncome: Yup.string()
		.required("O rendimento mensal é obrigatório")
		.test("validate-monthly-income", "Preencha um valor válido", (value) => validateMonthlyIncome(value)),
});

export const bankDetailsSchema = Yup.object().shape({
	documentType: Yup.boolean().required("O tipo de documento é obrigatório"),
	document: Yup.string()
		.required("O CPF/CNPJ é obrigatório")
		.test("validate-document", "CPF/CNPJ inválido", (value, context) => {
			const { documentType } = context.parent;
			if (!documentType) {
				return validateCPF(value);
			}
			return validaCNPJ(value);
		}),
	name: Yup.string().required("O nome do titular é obrigatório"),
	bankName: Yup.object()
		.required("O nome do banco é obrigatório")
		.test("validate-bank-name", "Selecione um banco", (option) => {
			return option.value !== "0";
		}),
	agency: Yup.string().required("Campo obrigatório"),
	accountType: Yup.string().required("Campo obrigatório"),
	accountNumber: Yup.string().required("Campo obrigatório"),
	accountDigit: Yup.string().required("Campo obrigatório"),
});

export const initialBankDetailsForm = {
	document: "",
	documentType: false,
	name: "",
	email: "",
	bankName: { value: 0, label: "Selecione o banco" },
	agency: "",
	accountType: 0,
	accountNumber: "",
	accountDigit: "",
	birthDate: "",
	job: "",
	monthlyIncome: "",
};

export const typesAccount = [
	{ label: "Conta Corrente", value: 1, convert: "corrente" },
	{ label: "Conta Corrente Conjunta", value: 11, convert: "corrente_conjunta" },
	{ label: "Conta Poupança", value: 2, convert: "poupanca" },
	{ label: "Conta Poupança Conjunta", value: 22, convert: "poupanca_conjunta" },
];

export const bankListFake = [
	{ value: 0, label: "Selecione o banco" },
	{ value: 1, label: "Banco do Brasil" },
	{ value: 2, label: "Banco Itaú" },
	{ value: 3, label: "Nubank" },
];
