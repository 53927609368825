import _ from "lodash";

import { getAppName } from "./validators";

export const telephoneNumberFormat = (number) => {
	let formated = "";

	if (!number) {
		return number;
	}

	for (var i = 3; i < number.length; i++) {
		if (i === 3) {
			formated += "(";
		}
		if (i === 5) {
			formated += ") ";
		}
		if (i === 10) {
			formated += "-";
		}
		formated += number.charAt(i);
	}
	return formated;
};

export const toggleButton = (setState) => {
	setState((prev) => !prev);
};

export const checkExistAddress = (business) => {
	if (business?.phone && !business.phone.startsWith("+55")) {
		return (
			verifyValueIsNotEmpty(business?.address?.street) ||
			verifyValueIsNotEmpty(business?.address?.neighborhood) ||
			verifyValueIsNotEmpty(business?.address?.number) ||
			verifyValueIsNotEmpty(business?.address?.postalCode) ||
			verifyValueIsNotEmpty(business?.address?.city) ||
			verifyValueIsNotEmpty(business?.address?.state)
		);
	}
	return (
		verifyValueIsNotEmpty(business?.address?.street) &&
		verifyValueIsNotEmpty(business?.address?.neighborhood) &&
		verifyValueIsNotEmpty(business?.address?.number) &&
		verifyValueIsNotEmpty(business?.address?.postalCode) &&
		verifyValueIsNotEmpty(business?.address?.city) &&
		verifyValueIsNotEmpty(business?.address?.state)
	);
};

const verifyValueIsNotEmpty = (value) => {
	return !_.isEmpty(_.trim(_.defaultTo(value, "")));
};

export const frequencyTypesAndRules = () => {
	const frequencyTypes = [
		{ label: "Diariamente", value: "1", key: 1, count: 1 },
		{ label: "Semanalmente", value: "7", key: 3, count: 7 },
		{ label: "A cada duas semanas", value: "14", key: 6, count: 14 },
		{ label: `A cada 15 Dias`, value: "15", key: 2, count: 15 },
		{ label: `A cada 20 Dias`, value: "20", key: 5, count: 20 },
		{ label: `A cada 21 Dias`, value: "21", key: 7, count: 21 },
		{ label: "Mensalmente", value: "Mensalmente", key: 4 },
	];

	const untilRuleOne = [
		{ label: "Depois de", value: "Depois de", key: 1 },
		{ label: "Na data selecionada", value: "Na data selecionada", key: 2 },
	];

	const untilRuleTwo = new Array(40).fill(null).map((_, index) => ({
		label: index + 1 === 1 ? `${index + 1} reserva` : `${index + 1} reservas`,
		count: index + 1,
		value: (index + 1).toString(),
		key: index + 1,
	}));

	const data = {};
	data.frequencyTypes = frequencyTypes;
	data.untilRuleOne = untilRuleOne;
	data.untilRuleTwo = untilRuleTwo;

	return data;
};

export const formatDate = (dateString) => {
	if (!dateString) return "";
	const date = new Date(dateString);
	return date.toISOString().split("T")[0];
};

export const treatDate = (date, booking) => {
	if (date === null || date === undefined) return null;
	let day, month, year;
	if (booking?.repeat) {
		const _date = new Date(booking?.start_time);
		day = _date.getDate();
		month = _date.getMonth() + 1;
		year = _date.getFullYear();
	} else {
		[year, month, day] = date.split("-");
	}
	return `${day < 10 ? `0${Number(day)}` : day}/${month < 10 ? `0${Number(month)}` : month}/${year}`;
};

export const treatTime = (time, booking) => {
	let hours, minutes;
	if (time === null || time === undefined) return null;
	if (booking?.repeat) {
		const _time = new Date(time);
		hours = _time.getHours();
		minutes = _time.getMinutes();
	} else {
		[hours, minutes] = time.split(":");
	}
	return `${hours < 10 ? `0${Number(hours)}` : hours}:${minutes < 10 ? `0${Number(minutes)}` : minutes}`;
};

export const prepareDate = (date, type = "date") => {
	if (date.toString().indexOf("T") > -1 && type === "date") {
		const _date = new Date(date);
		const year = _date.getFullYear();
		const month = _date.getMonth() + 1;
		const day = _date.getDate();
		return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
	}
	if (date.toString().indexOf("T") > -1 && type === "time") {
		const _time = new Date(date);
		const hours = _time.getHours();
		const minutes = _time.getMinutes();
		return `${hours < 10 ? `0${Number(hours)}` : hours}:${minutes < 10 ? `0${Number(minutes)}` : minutes}`;
	}
	return date;
};

export const convertToDate = (date, time) => {
	const newDate = `${date}T${time}:00.000Z`;
	return newDate;
};

export const convertMonth = (month) => {
	switch (month) {
		case 0:
			return "Janeiro";
		case 1:
			return "Fevereiro";
		case 2:
			return "Março";
		case 3:
			return "Abril";
		case 4:
			return "Maio";
		case 5:
			return "Junho";
		case 6:
			return "Julho";
		case 7:
			return "Agosto";
		case 8:
			return "Setembro";
		case 9:
			return "Outubro";
		case 10:
			return "Novembro";
		case 11:
			return "Dezembro";
		default:
			return "Mês inválido";
	}
};

export const convertDayOfWeek = (day) => {
	switch (day) {
		case 0:
			return "Domingo";
		case 1:
			return "Segunda-feira";
		case 2:
			return "Terça-feira";
		case 3:
			return "Quarta-feira";
		case 4:
			return "Quinta-feira";
		case 5:
			return "Sexta-feira";
		case 6:
			return "Sábado";
		default:
			return "Dia inválido";
	}
};

export const moneyMask = (value, currence) => {
	return (
		(currence == "BRL" ? "R$" : "") +
		(currence == "EUR" ? "€" : "") +
		" " +
		Math.floor(value / 100) +
		"," +
		(value % 100 < 10 ? "0" + (value % 100) : value % 100)
	);
};

export const moneyToLocale = (phone) => {
	if (phone.startsWith("+1")) {
		return "en-US"; // Estados Unidos e Canadá
	} else if (phone.startsWith("+33")) {
		return "fr-FR"; // França
	} else if (phone.startsWith("+44")) {
		return "en-GB"; // Reino Unido
	} else if (phone.startsWith("+351")) {
		return "pt-PT"; // Portugal
	} else if (phone.startsWith("+244")) {
		return "pt-AO"; // Angola
	} else if (phone.startsWith("+245")) {
		return "pt-GW"; // Guiné-Bissau
	} else if (phone.startsWith("+39")) {
		return "it-IT"; // Itália
	} else if (phone.startsWith("+31")) {
		return "nl-NL"; // Holanda
	} else if (phone.startsWith("+34")) {
		return "es-ES"; // Espanha
	} else {
		return "pt-BR"; // Brasil
	}
};

export const moneyRender = (business, price) => {
	const formattedValue = parseFloat(price).toLocaleString(moneyToLocale(business.phone), {
		minimumFractionDigits: 2,
		style: "currency",
		currency: business.currency,
	});

	if (business.currency === "AOA") {
		return formattedValue.replace(/[A-Z]{3}/, "Kz").trim();
	}

	return formattedValue;
};

export const PrivacyPolicy = `Termo de serviço

1. Os termos de serviço se referem ao conjunto de práticas que regem o acesso e a utilização dos serviços oferecidos pelo aplicativo ${getAppName()} e mídias correlatas (blogs, sites, redes sociais), propriedades da FG/A Investimentos, situada na cidade de Ribeirão Preto, SP, Brasil. O principal objetivo do aplicativo é promover o agendamento e pagamento online de serviços, tornando o dia-a-dia do usuário mais cômodo e simples. Ao acessar ou utilizar nossos serviços, você concorda com os termos de serviço. Estes termos de serviço serão regidos, interpretados e sujeitar-se-ão às leis brasileiras e, o “Usuário” e o aplicativo ${getAppName()} desde logo elegem, de forma irrevogável e irretratável, o foro da Comarca da Cidade de Ribeirão Preto, Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias oriundas destes termos.

2. Definições
i. Fica definida a utilização dos termos “Usuário”, “seu(s)”, “sua(s)” a quem acessa e/ou utiliza os serviços; os termos “Nós”, “nosso(s)”, nossa(s)” se referem ao intermediador (${getAppName()}).
ii. “Conteúdo” refere-se a todo o conjunto de dados ou comunicações (texto, foto, imagens, vídeos, etc.) contidos na utilização do site ou aplicativo.

3. Termos Gerais

A. Conta:
i. Ao criar sua conta, o “Usuário” fica responsável por fornecer e garantir a legitimidade das informações, sob pena de ter sua conta excluída e responder às devidas ações legais.
ii. A conta do “Usuário” é pessoal e intransferível, sendo contraindicado o compartilhamento de suas informações com terceiros. Caso sua conta seja acessada por terceiros, o “Usuário” se responsabiliza pelas ações realizadas, devendo informar imediatamente caso isso ocorra, para o bloqueio ou exclusão da conta. O “Usuário” isenta o ${getAppName()} da responsabilidade das ações realizadas com a sua conta.
iii. A conta para o “Usuário” do tipo consumidor (aquele que busca os serviços para agendar) terá custo zero. A conta para o “Usuário” do tipo estabelecimento (aquele que oferece os serviços de agendamento) terá um custo por agendamento bem-sucedido, não passando, entretanto, de um valor fixo por mês.

B. Conteúdo:
i. O “Conteúdo” do “Usuário” é de total responsabilidade do mesmo, não refletindo necessariamente a opinião do ${getAppName()}.
ii. O usuário permite o compartilhamento do seu “Conteúdo” de acordo com as diretrizes estabelecidas em Configuração de Conta. Quando em modo público, seus check-ins (Mostre aos seus amigos seus lugares favoritos!), suas fotos (Compartilhe o novo corte de cabelo ou aquele prato bonito), suas avaliações e comentários serão compartilhados com seus amigos, com os estabelecimentos e com o público conectado a ele. Esse compartilhamento permite que os usuários saibam quais os melhores estabelecimentos, e estes recebem o reconhecimento devido pelo esforço em fornecer sempre o melhor serviço.
iii. O “Usuário” aceita o recebimento de mensagens, notificações, publicidade ou qualquer tipo de material idealizado pelo ${getAppName()}, podendo restringir esses recebimentos na Configuração de Conta.  Porém notificações relacionadas ao agendamento, cancelamento e pagamento de serviços, ou quaisquer outras que interfiram no funcionamento adequado do aplicativo, são de recebimento obrigatório.

C. Responsabilidades e Disposições Legais:
i. O “Usuário” se responsabiliza a manter um comportamento adequado ao utilizar o aplicativo ${getAppName()} e suas mídias correlatas, sendo proibido conteúdo impróprio, informações falsas, uso de má-fé, calúnias, difamações, discriminação de qualquer natureza (religiosa, política, étnica, orientação sexual), cobrança por avaliação positiva, etc., sujeito à exclusão da conta e responsabilidade legal.
ii. O “Usuário” declara estar ciente de que eventuais falhas nos serviços podem acontecer, e o ${getAppName()} se responsabiliza por garantir as correções necessárias quando da ocorrência de “bugs”, falhas operacionais (excetuadas as falhas decorrentes de uso inadequado).
iii. O ${getAppName()} funciona como intermediador entre os prestadores de serviços e os usuários. A responsabilidade pelas informações fornecidas por ambos (dados pessoais, valores de serviços, taxas de reserva/cancelamento, cumprimento dos agendamentos, etc.) é exclusiva de quem ofereceu ou solicitou o serviço. Além disso, o cumprimento e a qualidade dos serviços prestados são de responsabilidade do estabelecimento que o ofereceu, a quem deverão ser direcionadas quaisquer reclamações acerca dos problemas decorrentes (mau atendimento, serviço impreciso ou de má qualidade, etc), isentando o ${getAppName()}.\niv. É de responsabilidade do “Usuário” ainda qualquer tipo descumprimento da lei, seja desrespeito às leis de propriedade industrial (patentes, marcas, registros, etc.), copyright (ou qualquer tipo de direito autoral), fraudes ou qualquer outra prática criminosa. Em casos como esses, a conta do “Usuário” será imediatamente excluída e as providências legais serão devidamente tomadas. Constituem práticas criminosas: não realizar o pagamento pelo serviço utilizado, realizar cobrança indevida ou abusiva, oferecer benefícios de qualquer tipo em troca de avaliações e/ou comentários positivos, realizar qualquer ação com o intuito de burlar o funcionamento do aplicativo.

D. Propriedade Intelectual:
i. A marca ${getAppName()}® e mídias correlatas são propriedades da FG/A Investimentos. A autorização para a utilização não transfere ao “Usuário” direito à propriedade. A reprodução não autorizada é proibida e está sujeita às medidas legais cabíveis.
ii. É proibida a utilização de engenharia inversa ou qualquer outro método de pesquisa/captura de informação de como o aplicativo e mídias correlatas são desenvolvidos (códigos, organização, estrutura).

4. Trabalhamos sempre pensando no usuário
i. Estamos sendo aprimorando os Termos de Serviços de forma a garantir a melhor experiência do usuário de forma personalizada e segura. Algumas mudanças podem acontecer, e serão indicadas através de notificações. Ao continuar a utilização do serviço após as mudanças, você concorda com os termos nele expressos.
ii. Caso perceba que algum termo de serviço está sendo desrespeitado, entre em contato com a nossa equipe.

Política de Privacidade

Podemos coletar e armazenar qualquer informação que você forneça e compartilhe utilizando o serviço. Dessa forma, cumprimos a funcionalidade do aplicativo, permitindo que seus dados fiquem salvos (para não ter que reescrever a cada novo serviço), personalizando sua experiência de acordo com as suas preferências.

1. Como obtemos e utilizamos as informações
i. Informações como o seu nome, e-mail, telefone e CPF serão solicitados de modo a garantir a segurança dentro do aplicativo. Outras informações poderão ser adicionadas depois, tornando sua experiência mais completa, como fotos, data de nascimento (imagina receber um cupom de desconto no dia do seu aniversário!). Seu nome e sua foto aparecerão em modo público, permitindo que seus amigos possam te encontrar.
ii. Outras informações não pessoais podem ser obtidas (idade, idioma, CEP, preferências individuais, etc.), e nos ajudam a tornar sua experiência personalizada. Informações compartilhadas pelo usuário em redes sociais, por exemplo, podem ser coletadas e armazenadas. Em configurações de conta será possível alterar a privacidade de algumas informações.
iii. As informações coletadas e armazenadas não serão utilizadas de forma inapropriada nem vendida a terceiros. Elas são utilizadas para que o usuário experimente todas as funcionalidades do aplicativo e mídias correlatas, obtendo uma experiência personalizada de acordo com os seus gostos.
iv. Algumas informações podem ser repassadas a terceiros, desde que cumpram unicamente o intuito de manter a funcionalidade do aplicativo (passar seu nome e telefone para o estabelecimento no qual você solicitou agendamento, por exemplo). Elas podem ser utilizadas também para determinar em/ou melhorar estratégias de marketing.
v. As informações podem ainda ser repassadas a terceiros quando por exigência da lei (suspeita de crimes, incumbências legais, etc.).
vi. Informações de uso como avaliações, comentários, check-ins, etc., serão publicadas em modo público de acordo com as preferências do usuário. Em configurações de conta é possível determinar quais informações serão compartilhadas e com quem elas serão compartilhadas.
vii. É possível convidar outras pessoas para utilizarem o ${getAppName()}, para fazer parte da sua rede de amigos e muito mais! Para isso, você nos oferece suas informações de contato e nos permite acessar a sua lista de contatos do celular, das redes sociais, etc. Essas informações são transferidas aos nossos servidores por tempo suficiente para processar seus convites e adicioná-los a sua lista.

2. Cookies e outras tecnologias
i. O aplicativo ${getAppName()} e mídias correlatas podem fazer o uso de tecnologias tais como cookies, web beacons e pixel tags. Essas ferramentas nos ajudam a entender melhor o comportamento do usuário, armazenando informações tais como dispositivo que o usuário está utilizando, IP, plataforma, sistema operacional, quais páginas o usuário visitou, etc. Todas essas informações são utilizadas para melhorar sua navegação pelo aplicativo, personalizando sua experiência e fazendo com que você receba sempre as informações que mais se encaixam ao seu perfil.
ii. O usuário pode bloquear determinados cookies, de acordo com a plataforma utilizada. Porém é importante ressaltar que esse bloqueio pode interferir na experiência, fazendo com que algumas funcionalidades sejam prejudicadas. Defeitos ou possíveis falhas originadas pelo bloqueio dessas tecnologias por parte do usuário são de total responsabilidade do mesmo.

3. Segurança
i. Suas informações coletadas e armazenadas são protegidas através de medidas de segurança técnicas e organizacionais contra perda, destruição acidental, destruição ilegal, distribuição, acesso não-autorizado e alteração. Porém, nenhum método é 100% isento de falhas, e por mais que utilizemos a tecnologia mais confiável do mercado, não podemos garantir segurança absoluta.

4. Idade mínima de utilização
i. O aplicativo e suas mídias correlatas são direcionadas para o público em geral, não sendo indicadas para menores de 16 anos. De forma geral, é importante que crianças e adolescentes menores de 18 anos contem sempre com a supervisão dos pais ou adultos responsáveis ao utilizar mídias digitais.

5. Trabalhamos sempre pensando no usuário
i. Estamos sendo aprimorando a Política de Privacidade de forma a garantir a melhor experiência do usuário de forma personalizada e segura. Algumas mudanças podem acontecer, e serão indicadas através de notificações. Ao continuar a utilização do serviço após as mudanças, você concorda com os termos nela expressos.
ii. Caso perceba que algum termo da política de privacidade está sendo desrespeitada, entre em contato com a nossa equipe.`;

export const states = [
	"AC",
	"AL",
	"AP",
	"AM",
	"BA",
	"CE",
	"DF",
	"ES",
	"GO",
	"MA",
	"MT",
	"MS",
	"MG",
	"PA",
	"PB",
	"PR",
	"PE",
	"PI",
	"RJ",
	"RN",
	"RS",
	"RO",
	"RR",
	"SC",
	"SP",
	"SE",
	"TO",
];
