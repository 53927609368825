import api, { apiPayment } from "./api";

export const cancellationPolicyScreen = async (recipentId) => {
	const response = await apiPayment.get(`recipient/cancellation-policy-screen/${recipentId}`);
	return response.data;
};

export const getPolicy = async (recipentId, clientId) => {
	const response = await apiPayment.get(`recipient/${recipentId}/cancellation-policy-client/${clientId}`);
	return response.data;
};

export const deletePolicy = async (recipentId, clientId) => {
	const response = await apiPayment.delete(`recipient/${recipentId}/cancellation-policy-client/${clientId}`);
	return response.data;
};

export const policySave = async (data) => {
	const response = await apiPayment.post("recipient/cancellation-policy-client", data);
	return response.data;
};

export const bankAccount = async (recipentId) => {
	const response = await apiPayment.get(`recipient/bank-account/${recipentId}`);
	return response.data;
};

export const bankTypeAll = async () => {
	const response = await apiPayment.get("bank/all");
	return response.data;
};

export const updateBankAccount = async (data) => {
	const response = await apiPayment.post("recipient/bank-account/save", data);
	return response.data;
};

export const cancellationPolicyData = async () => {
	const response = await apiPayment.get("recipient/cancellation-policy/get-registration-data");
	return response.data;
};

export const rescheduleEarlyBookingAll = async (businessId) => {
	const response = await api.get(`reschedule/early_booking/${businessId}/subscription`);
	return response.data;
};

export const checkBankAccountStripe = async (businessId) => {
	const response = await apiPayment.get(`recipient/stripe/connect-account/${businessId}`);
	return response.data;
};

export const updateBankAccountStripe = async (data) => {
	try {
		const response = await apiPayment.post("recipient/stripe/connect-account/save", data);
		return response.data;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const getParameters = async () => {
	const response = await api.get(`parameter`);
	return response.data;
};

export const getContract = async (businessId) => {
	const response = await apiPayment.get(`recipient/contract/${businessId}`);
	return response.data;
};

export const checkAvailabletyContract = async (businessId) => {
	const response = await apiPayment.get(`recipient/availablety/${businessId}`);
	return response.data;
};

export const saveContract = async (recipientId, card, pix) => {
	const response = await apiPayment.get(`recipient/contract/save/${recipientId}/${card}/${pix}`);
	return response.data;
};

export const checkAvailableContract = async (recipientId) => {
	const response = await apiPayment.get(`recipient/contract/availablety/${recipientId}`);
	return response.data;
};

export const updateContractPayTypes = async (data) => {
	const response = await apiPayment.post("recipient/contract/update/paytypes", data);
	return response.data;
};

export const getPolicyById = async (recipentId) => {
	const response = await apiPayment.get(`recipient/cancellation-policy/${recipentId}`);
	return response.data;
};

export const updatePolicy = async (data) => {
	const response = await apiPayment.post("recipient/cancellation-policy/save", data);
	return response.data;
};

export const getBiometry = async (recipientId) => {
	const response = await apiPayment.get(`recipient/create-kyc-link/${recipientId}`);
	return response.data;
};

const advancePaymentService = {
	getPolicy,
	deletePolicy,
	policySave,
	bankAccount,
	bankTypeAll,
	updateBankAccount,
	cancellationPolicyData,
	cancellationPolicyScreen,
	rescheduleEarlyBookingAll,
	checkBankAccountStripe,
	updateBankAccountStripe,
	getParameters,
	getContract,
	checkAvailabletyContract,
	saveContract,
	checkAvailableContract,
	updateContractPayTypes,
	getPolicyById,
	updatePolicy,
	getBiometry,
};

export default advancePaymentService;
