import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import pritPay from "assets/images/prit-pay.png";
import pritPayLogo from "assets/images/prit-white.png";
import { Button, Dialog, Inputs, Text } from "components";
import { useConfig } from "context/config.context";
import { usePritPay } from "context/pritpay.context";
import * as Schemas from "helpers/schemas";
import advancePaymentService from "services/advance-payment.service";
import { getAppName } from "utils/validators";

import ModalUseTerms from "./modal-use-terms";

const PritPayTerms = ({ pritPayFlow = false }) => {
	const [openValidationModal, setOpenValidationModal] = useState(false);
	const [openModalUseTerms, setOpenModalUseTerms] = useState(false);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const {
		setPage,
		parameters,
		business,
		bankAccount,
		getCancellationPolicy,
		setCancellationPolicy,
		cancellationPolicy,
		apiRequestData,
	} = usePritPay();
	const { handleFeedback, handleLoading, loading } = useConfig();
	const [modalMessage, setModalMessage] = useState(null);

	const {
		control,
		setError,
		watch,
		setValue,
		formState: { isValid, errors },
	} = useForm({
		resolver: yupResolver(Schemas.AdvancePaymentSchema.pritPayTermsSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: Schemas.AdvancePaymentSchema.initialPritPayTermsForm,
	});

	const watchEnablePix = watch("enablePix");
	const watchEnableCreditCard = watch("enableCreditCard");
	const watchTermsAccepted = watch("termsAccepted");

	const handleCancel = () => {
		setOpenValidationModal(false);
		setPage(null);
	};

	const handleContinue = () => {
		if (pritPayFlow) {
			setOpenValidationModal(false);
			if (!business?.address?.postalCode) {
				setPage("Address");
			} else if (!bankAccount?.bank_id) {
				setPage("BankDetails");
			} else {
				apiRequestData();
				setPage(null);
			}
		} else {
			setPage(null);
		}
	};

	const handleTermsAccepted = async () => {
		handleLoading(true);
		try {
			if (cancellationPolicy.contract !== null) {
				await advancePaymentService.updateContractPayTypes({
					recipient_id: business.id,
					card_enabled: watchEnableCreditCard,
					pix_enabled: watchEnablePix,
				});
			} else {
				await advancePaymentService.saveContract(business.id, watchEnableCreditCard, watchEnablePix);
			}
			setCancellationPolicy({
				...cancellationPolicy,
				contract: {
					...cancellationPolicy.contract,
					transaction_percentage: watchEnableCreditCard ? parameters?.pritPayPercentage : null,
					pix_transaction_percentage: watchEnablePix ? parameters?.pritPayPercentage : null,
				},
			});
			updateModalMessage();
			if (pritPayFlow) {
				setOpenValidationModal(true);
			} else {
				setOpenModalSuccess(true);
			}
		} catch (error) {
			console.log(error);
			handleFeedback("Ops!", "Não foi possível concluir a assinatura. Por favor, tente novamente.", "warning");
		} finally {
			handleLoading(false);
		}
	};

	const updateModalMessage = () => {
		if (pritPayFlow) {
			if (!business?.address?.postalCode) {
				setModalMessage(
					"Agora, precisamos das informações de endereço e dados bancários para o crédito dos valores recebidos."
				);
			} else if (!bankAccount?.bank_id) {
				setModalMessage("Agora, precisamos das informações de dados bancários para o crédito dos valores recebidos.");
			} else {
				setModalMessage("Termos atualizados com sucesso!");
			}
		} else {
			setModalMessage("Termos atualizados com sucesso!");
		}
	};

	useEffect(() => {
		if ((watchEnablePix || watchEnableCreditCard) && watchTermsAccepted) {
			setError("termsAccepted", null);
		} else {
			setError("termsAccepted", {
				message: "Você precisa aceitar os termos de uso e habilitar ao menos uma forma de pagamento",
			});
		}
	}, [watchEnablePix, watchEnableCreditCard]);

	useEffect(() => {
		if (!cancellationPolicy) getCancellationPolicy();
	}, []);

	useEffect(() => {
		if (cancellationPolicy && cancellationPolicy.contract && parameters) {
			setValue("enablePix", cancellationPolicy.contract.pix_transaction_percentage !== null);
			setValue("enableCreditCard", cancellationPolicy.contract.transaction_percentage !== null);
			setValue("termsAccepted", true, { shouldValidate: true });
		}
	}, [cancellationPolicy, parameters]);

	return (
		<>
			<div className="box-content">
				<Text variant="mid" align="left" style={{ padding: "0 0 2rem", color: "#393762" }}>
					Deseja oferecer que seus clientes paguem online?
				</Text>

				<div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
					<div>
						<Text variant="mid" align="center" style={{ padding: "0 0 0.5rem", color: "#393762" }}>
							PIX
						</Text>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "1rem",
								backgroundColor: "white",
								borderRadius: "1rem",
								border: "1px solid #393762",
							}}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									gap: "0.5rem",
									backgroundColor: "#393762",
									padding: "1rem",
									borderRadius: "1rem",
									borderTopRightRadius: "0",
									borderBottomRightRadius: "0",
								}}>
								<img src={pritPayLogo} alt={getAppName(true)} style={{ width: "96px" }} />
								<img src={pritPay} alt={getAppName(true)} style={{ width: "48px" }} />
							</div>

							<div style={{ flex: 1, textAlign: "right", padding: "1rem 2rem" }}>
								<Text variant="caption" color="textSecondary">
									{parameters?.pritPayPixPercentage / 100}% <br /> sobre as transações
								</Text>
							</div>
						</div>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem" }}>
							<Inputs checked={watchEnablePix} control={control} name="enablePix" type="checkbox" />
							<Text style={{ color: "black" }}>Habilitar pix para meus clientes</Text>
						</div>
					</div>

					<div>
						<Text variant="mid" align="center" style={{ padding: "0 0 0.5rem", color: "#393762" }}>
							Cartões de Crédito
						</Text>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "1rem",
								backgroundColor: "white",
								borderRadius: "1rem",
								border: "1px solid #393762",
							}}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									gap: "0.5rem",
									backgroundColor: "#393762",
									padding: "1rem",
									borderRadius: "1rem",
									borderTopRightRadius: "0",
									borderBottomRightRadius: "0",
								}}>
								<img src={pritPayLogo} alt={getAppName(true)} style={{ width: "96px" }} />
								<img src={pritPay} alt={getAppName(true)} style={{ width: "48px" }} />
							</div>

							<div style={{ flex: 1, textAlign: "right", padding: "1rem 2rem" }}>
								<Text variant="caption" color="textSecondary">
									{parameters?.pritPayPercentage / 100}% <br /> sobre as transações
								</Text>
							</div>
						</div>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem" }}>
							<Inputs checked={watchEnableCreditCard} control={control} name="enableCreditCard" type="checkbox" />
							<Text style={{ color: "black" }}>Habilitar cartão para meus clientes</Text>
						</div>
					</div>

					<div>
						<Text align="justify" variant="body2" style={{ marginBottom: "1rem", color: "#757575" }}>
							Pagamento por PIX: Saques solicitados antes das 16h serão pagos no mesmo dia. Solicitações após esse
							horário são realizadas no próximo dia útil.
						</Text>
						<Text align="justify" variant="body2" style={{ marginBottom: "2rem", color: "#757575" }}>
							Pagamento por cartão: Saques poderão ser solicitados após 30 dias corridos.
						</Text>

						<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
							<Inputs checked={watchTermsAccepted} control={control} name="termsAccepted" type="checkbox" />
							<Text style={{ color: "black" }}>
								Eu concordo com os{" "}
								<a href="#" onClick={() => setOpenModalUseTerms(true)}>
									Termos de uso
								</a>
							</Text>
						</div>
					</div>
				</div>
				{errors?.termsAccepted?.message && <div className="label-error">{errors?.termsAccepted?.message}</div>}
				<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "2rem" }}>
					<Button
						type="button"
						variant="contained"
						title="Salvar"
						fullWidth={true}
						className="btn-color-primary btn-default"
						disabled={!isValid || loading}
						handleClick={() => {
							handleTermsAccepted();
						}}
					/>
				</div>
			</div>
			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Assinatura concluída com sucesso!</strong>}
				content={<Text>{modalMessage}</Text>}
				isOpen={openValidationModal}
				handleFirstBtn={handleContinue}
				handleSecondBtn={handleCancel}
				lblBtnFirst="Continuar"
				lblBtnSecond="Cancelar"
			/>
			<Dialog
				title={<strong style={{ fontSize: "1.5rem" }}>Assinatura atualizada com sucesso!</strong>}
				content={<Text>{modalMessage}</Text>}
				isOpen={openModalSuccess}
				handleFirstBtn={() => {
					setOpenModalSuccess(false);
					setPage(null);
				}}
				lblBtnFirst="OK"
			/>
			{parameters && (
				<ModalUseTerms
					open={openModalUseTerms}
					onClose={() => setOpenModalUseTerms(false)}
					percentage={parameters?.pritPayPercentage}
				/>
			)}
		</>
	);
};

export default PritPayTerms;
