import { Container, Divider, Grid } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ApexCharts from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { Button, Header, HeaderWelcome, Inputs, Text } from "components";
import { useConfig } from "context/config.context";
import { businessProfessionals, getBusiness } from "services/businessService";
import { getMe } from "services/meService";
import { getDashboard } from "services/reports.service";
import businessAction from "store/actions/businessAction";
import { isMobile } from "utils/isMobile";
import { getAppSecondColorDashboard } from "utils/validators";

import "../style.scss";

let timerNextFind = null;

const Reports = ({ inside = false }) => {
	const {
		control,
		watch,
		setValue,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		reValidateMode: "onChange",
		mode: "onChange",
	});

	const [dateError, setDateError] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [professionals, setProfessionals] = useState([]);
	const [data, setData] = useState({
		sales_total: 0,
		manualBookings_total: 0,
		onlineBookings_total: 0,
		clients_total: 0,
	});

	const fromDateRef = useRef(null);
	const toDateRef = useRef(null);
	const professionalRef = useRef(null);

	const chartSalesRef = useRef(null);
	const chartNumbersRef = useRef(null);
	const chartClientsRef = useRef(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const business = useSelector((state) => state.business.business);
	const { handleLoading, handleFeedback, setTypeAccess } = useConfig();

	const { token, business_id } = useParams();

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			nextFieldRef.current.focus();
		}
	};

	const onSubmit = async (data, _business = business) => {
		if (chartSalesRef.current) {
			chartSalesRef.current.destroy();
		}
		if (chartNumbersRef.current) {
			chartNumbersRef.current.destroy();
		}
		if (chartClientsRef.current) {
			chartClientsRef.current.destroy();
		}

		handleLoading(true);
		const values = {
			business_id: business_id ?? _business.id,
			from_date: treatDate(data.from_date),
			to_date: treatDate(data.to_date),
			professional_id: data.professional_id === "todos" ? null : data.professional_id,
		};

		try {
			const result = await getDashboard(values);

			// Ordenar o array result pelo campo competence
			result.sort((a, b) => new Date(a.competence) - new Date(b.competence));

			setData({
				sales_total: result.reduce((acc, curr) => acc + curr.income, 0) / 100,
				manualBookings_total: result.reduce((acc, curr) => acc + curr.manualBookings, 0),
				onlineBookings_total: result.reduce((acc, curr) => acc + curr.onlineBookings, 0),
				clients_total: result.reduce((acc, curr) => acc + curr.clients, 0),
			});
			handleLoading(false);
			updateChart({
				sales: result.map((item) => item.income / 100),
				manualBookings: result.map((item) => item.manualBookings),
				onlineBookings: result.map((item) => item.onlineBookings),
				clients: result.map((item) => item.clients),
				months: result.map((item) => formatDate(item.competence)),
			});
		} catch (err) {
			console.log("🚀 ~ onSubmit ~ err:", err);
			setTypeAccess("reports");
			handleLoading(false);
			navigate("/login");
		}
	};

	const treatDate = (date) => {
		const dateObject = date.split("-");
		const day = dateObject[2];
		const month = dateObject[1];
		const year = dateObject[0];
		return `${year}-${month}-${day}`;
	};

	const formatDate = (date) => {
		const dateObject = date.split("-");
		const month = dateObject[1];
		let monthName = "";
		const year = dateObject[0];

		switch (Number(month)) {
			case 1:
				monthName = "Janeiro";
				break;
			case 2:
				monthName = "Fevereiro";
				break;
			case 3:
				monthName = "Março";
				break;
			case 4:
				monthName = "Abril";
				break;
			case 5:
				monthName = "Maio";
				break;
			case 6:
				monthName = "Junho";
				break;
			case 7:
				monthName = "Julho";
				break;
			case 8:
				monthName = "Agosto";
				break;
			case 9:
				monthName = "Setembro";
				break;
			case 10:
				monthName = "Outubro";
				break;
			case 11:
				monthName = "Novembro";
				break;
			case 12:
				monthName = "Dezembro";
				break;
		}

		return `${monthName.substring(0, 3)}/${year}`;
	};

	const chartOptions = {
		chart: {
			type: "bar",
			toolbar: {
				show: false,
			},
		},
		series: [
			{
				name: "Receita",
				data: data.sales,
			},
		],
		title: {
			text: "Receita",
			align: "center",
			offsetY: 25,
			style: {
				fontSize: "1.2rem",
				fontWeight: "bold",
				fontFamily: undefined,
				color: "#263238",
			},
		},
		fill: {
			opacity: 1,
		},
		colors: ["#008FFB", getAppSecondColorDashboard()],
		plotOptions: {
			bar: {
				dataLabels: {
					position: "top",
				},
			},
		},
		dataLabels: {
			enabled: true,
			offsetY: -20,
			style: {
				fontSize: "12px",
				colors: ["#304758"],
			},
		},
	};

	const updateChart = (_data) => {
		const sales_doc = document.getElementById("sales-chart");
		if (sales_doc) {
			chartSalesRef.current = new ApexCharts(sales_doc, {
				...chartOptions,
				xaxis: {
					categories: _data.months,
				},
				series: [
					{
						name: "Receita (R$)",
						data: _data.sales,
					},
				],
				title: {
					...chartOptions.title,
					text: "Receita (R$)",
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toLocaleString("pt-BR", {
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							});
						},
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return (
								"R$ " +
								val.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})
							);
						},
					},
					style: {
						fontSize: "12px",
						color: "#111",
					},
				},
				dataLabels: {
					...chartOptions.dataLabels,
					enabled: false,
					style: {
						colors: ["#000"],
					},
				},
			});
			chartSalesRef.current.render();
		}
		const numbers_doc = document.getElementById("numbers-chart");
		if (numbers_doc) {
			chartNumbersRef.current = new ApexCharts(numbers_doc, {
				...chartOptions,
				xaxis: {
					categories: _data.months,
				},
				series: [
					{
						name: "Agendamentos online",
						data: _data.onlineBookings,
					},
					{
						name: "Agendamentos manuais",
						data: _data.manualBookings,
					},
				],
				title: {
					...chartOptions.title,
					text: "Agendamentos",
				},
			});
			chartNumbersRef.current.render();
		}

		const clients_doc = document.getElementById("clients-chart");
		if (clients_doc) {
			chartClientsRef.current = new ApexCharts(clients_doc, {
				...chartOptions,
				xaxis: {
					categories: _data.months,
				},
				series: [
					{
						name: "Clientes atendidos",
						data: _data.clients,
					},
				],
				title: {
					...chartOptions.title,
					text: "Clientes atendidos",
				},
			});
			chartClientsRef.current.render();
		}
	};

	const updateFields = (_business = business) => {
		const list = [];

		if (_business?.professionals.length > 1) {
			list.push({
				label: "Todos",
				value: "todos",
			});
		}

		_business?.professionals.forEach((professional) => {
			list.push({
				label: professional.name,
				value: professional.id,
			});
		});

		setProfessionals(list);
		setValue("professional_id", list[0].value);

		onSubmit(
			{
				professional_id: list[0].value === "todos" ? null : list[0].value,
				from_date: new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split("T")[0],
				to_date: new Date().toISOString().split("T")[0],
			},
			_business
		);
	};

	const handleCompareDates = () => {
		const fromDate = new Date(watch("from_date"));
		const toDate = new Date(watch("to_date"));
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		if (diffDays > 365 || diffDays < 0) {
			setDateError(true);
			handleFeedback("Atenção", "Período selecionado excede o limite de 12 meses", "warning", 5000);
		} else {
			if (fromDate > toDate) {
				setDateError(true);
				handleFeedback("Atenção", "A data de início não pode ser maior que a data de término", "warning", 5000);
				setValue("from_date", watch("to_date"));
			} else {
				setDateError(false);
			}
		}
	};

	useEffect(() => {
		handleCompareDates();
	}, [watch("from_date"), watch("to_date")]);

	useEffect(() => {
		handleLoading(true);
		const loadBusiness = setTimeout(() => {
			if (!business && !inside && !token) {
				needLoginMessage();
				return;
			}
			if (business !== null && !token) {
				updateFields();
			}
		}, 1000);

		if (token && !loaded) {
			if (!business_id) {
				needLoginMessage();
				return;
			}
			validateProfessional();
		}

		return () => clearTimeout(loadBusiness);
	}, [business, token, business_id]);

	const validateProfessional = async () => {
		handleLoading(true);

		localStorage.setItem("accessToken", token);

		try {
			const _me = await getMe();
			const _businessProfessionals = await businessProfessionals();

			let professional = null;

			_businessProfessionals.data.find((business) => {
				if (business.id === business_id) {
					professional = business.professionals.find((professional) => professional.phone === _me.data.phone);
				}
			});

			if (professional) {
				setLoaded(true);
				await dispatch(businessAction.updateBusiness(_businessProfessionals.data));
				updateFields(_businessProfessionals.data);
			}
		} catch (err) {
			console.log("🚀 ~ validateToken ~ err:", err);
			validateBusiness();
		}
	};

	const validateBusiness = async () => {
		handleLoading(true);
		getBusiness()
			.then(async (res) => {
				if (res.data.length > 0) {
					setLoaded(true);
					const business = res.data.find((business) => business.id === business_id);
					if (business) {
						await dispatch(businessAction.updateBusiness(business));
						updateFields(business);
					} else {
						needLoginMessage();
					}
				} else {
					needLoginMessage();
				}
			})
			.catch((err) => {
				console.log("🚀 ~ validateToken ~ err:", err);
				needLoginMessage();
			});
	};

	const needLoginMessage = () => {
		handleLoading(false);
		handleFeedback("Atenção", "Você precisa estar logado para acessar o relatório", "warning", 5000);
		setTypeAccess("reports");
		navigate("/login");
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const updateReport = () => {
		clearTimeout(timerNextFind);
		timerNextFind = setTimeout(() => {
			onSubmit({
				professional_id: watch("professional_id"),
				from_date: watch("from_date"),
				to_date: watch("to_date"),
			});
		}, 1500);
	};

	return (
		<>
			{inside && (
				<>
					<Header title="Relatório" showNotifications />
					<Divider />
				</>
			)}
			<Container
				maxWidth="sm"
				className="box-dashboard"
				style={{
					marginLeft: inside ? "0px" : "auto",
					width: isMobile() ? "100%" : "50%",
					maxWidth: inside ? "100%" : "600px",
				}}>
				{!inside && <HeaderWelcome showMessage={false} message="Relatório" />}
				<div className="content-dashboard">
					<div className="box-reports">
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Inputs
									control={control}
									name="from_date"
									type="date"
									label="De"
									inputRef={fromDateRef}
									handleKeyDown={(e) => handleKeyDown(e, toDateRef)}
									value={new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split("T")[0]}
									getChange={() => updateReport()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Inputs
									control={control}
									name="to_date"
									type="date"
									label="Até"
									inputRef={toDateRef}
									handleKeyDown={(e) => handleKeyDown(e, professionalRef)}
									value={new Date().toISOString().split("T")[0]}
									getChange={() => updateReport()}
								/>
							</Grid>
						</Grid>
					</div>
					<div className="box-reports">
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								<Inputs
									control={control}
									name="professional_id"
									type="select"
									inputRef={professionalRef}
									handleKeyDown={(e) => handleKeyDown(e, null)}
									value="0"
									className="select-person"
									style={{ width: "100%" }}
									options={professionals}
									getChange={() => updateReport()}
								/>
							</Grid>
						</Grid>
					</div>

					<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
						<div className="box-reports numbers">
							<Text variant="body" align="center" style={{ color: "#393762" }}>
								Receita (R$)
							</Text>
							<Text variant="big" align="center" style={{ color: "#393762" }}>
								{data.sales_total.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</Text>
						</div>
						<div className="box-reports numbers">
							<Text variant="body" align="center" style={{ color: "#393762" }}>
								Clientes atendidos
							</Text>
							<Text variant="big" align="center" style={{ color: "#393762" }}>
								{data.clients_total}
							</Text>
						</div>
						<div className="box-reports numbers">
							<Text variant="body" align="center" style={{ color: "#393762" }}>
								Agendamentos manuais
							</Text>
							<Text variant="big" align="center" style={{ color: "#393762" }}>
								{data.manualBookings_total}
							</Text>
						</div>
						<div className="box-reports numbers">
							<Text variant="body" align="center" style={{ color: "#393762" }}>
								Agendamentos online
							</Text>
							<Text variant="big" align="center" style={{ color: "#393762" }}>
								{data.onlineBookings_total}
							</Text>
						</div>
					</div>

					<div className="dashboard-content" id="sales-chart" />
					<div className="dashboard-content" id="numbers-chart" />
					<div className="dashboard-content" id="clients-chart" />
				</div>
			</Container>
		</>
	);
};

export default Reports;
