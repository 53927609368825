import { Button } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { moneyMask } from "../../utils/utils";
import Text from "../texts";

import "./index.scss";

const ServiceChip = ({
	serviceName,
	serviceStartTime,
	serviceEndTime,
	serviceDuration,
	servicePrice,
	serviceProfessional,
	edit = false,
	onClick = () => {},
}) => {
	const { business } = useSelector((store) => store.business);

	return (
		<Button
			type="button"
			variant="text"
			endIcon={edit ? <ArrowForwardIos /> : null}
			className="selectBlock"
			onClick={onClick}>
			{serviceName ? (
				<div className="serviceBox">
					<div className="blockName">
						<Text variant="body1">{serviceName}</Text>
						{serviceProfessional && (
							<>
								<Text className="grayText" variant="body1">
									Com {serviceProfessional}
								</Text>
								<Text variant="body1">{`${serviceStartTime}-${serviceEndTime}`}</Text>
							</>
						)}
					</div>

					<div className="blockData">
						<Text className="grayText" variant="body1">
							{serviceDuration} min
						</Text>
						<Text variant="body1">{moneyMask(servicePrice, business?.currency)}</Text>
					</div>
				</div>
			) : (
				<Text variant="body1">Selecione um serviço</Text>
			)}
		</Button>
	);
};

export default ServiceChip;
