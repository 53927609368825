import React, { useEffect, useState } from 'react';
import Text from '../../texts';
import './index.scss';
import { moneyMask } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import EventModal from '../../../containers/CalendarTimeline/eventModal';
import { isMobile } from "utils/isMobile";
import GradientLoading from '../../../components/gradientLoading';
import { Box } from '@material-ui/core';

const CompletedBookingComponent = ({ data, completedData }) => {
  const [openEventDetails, setOpenEventDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const { business } = useSelector((store) => store.business);
  
  if (!data) return null;

  const handleOpenEventModalDetails = () => {
    setOpenEventDetails(true);
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 500,
        }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
          <GradientLoading />
        </Box>
      </div>
    );
  }

  function parseDateTime(dateString) {
    if (dateString === null) {
      return {
        dayOfWeek: '---',
        date: '--',
        time: '-'
      };
    }
  
    const date = new Date(dateString);
    const diasSemana = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
    const dayOfWeek = diasSemana[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
  
    return {
      dayOfWeek,
      date: `${day}/${month}`,
      time: `${hour}:${minute}`
    };
  }  

  const { dayOfWeek, date: formattedDate, time } = parseDateTime(data.startTime);

  return (
    <>
      <div className='blockContainer' style={{ cursor: 'pointer' }} onClick={handleOpenEventModalDetails}>
        <div className='wrapInfoClient'>
          <div className='containerDate'>
            <Text variant={'body1'}>{dayOfWeek}</Text>
            <Text variant={'body1'}>{formattedDate}</Text>
            <Text variant={'body1'}>{time}</Text>
          </div>
          <div className='clientInfoContainer'>
            <div>
              <Text variant={isMobile() ? 'body1' : ''}>{data?.client?.name}</Text>
            </div>
            <div>
              <Text variant={isMobile() ? 'body1' : ''}>{data?.service?.name}</Text>
            </div>
          </div>
        </div>
        <div className='containerPayment'>
          <div className='statusContainerPayment'>
            <Text variant={'body1'} style={{ color: '#fff' }}>Pago</Text>
          </div>
          <Text variant={isMobile() ? 'body1' : ''} style={{ color: '#3689ea' }}>
            {moneyMask(data?.price, business?.currency)}
          </Text>
        </div>
      </div>
      {openEventDetails && (
        <EventModal
          event={completedData}
          bookingId={completedData.id}
          onClose={() => setOpenEventDetails(false)}
        />
      )}
    </>
  );
};

export default CompletedBookingComponent;
