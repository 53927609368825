import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "utils/isMobile";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#f9f9f9",
		borderRadius: "8px",
		padding: "1rem",
		boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
		marginTop: theme.spacing(4),
		width: isMobile() ? "100%" : "80%",
	},
	formContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignContent: "stretch",
		width: "100%",
		gap: "16px",
		'&& input[type="text"]': {
			backgroundColor: "white !important",
		},
	},
	formGroup: {
		marginBottom: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
	},
	label: {
		marginBottom: theme.spacing(1),
		fontSize: "16px",
		flexGrow: 1,
		color: "#393762",
		fontWeight: "bold",
	},
	input: {
		padding: theme.spacing(1.5),
		border: "1px solid #ddd",
		borderRadius: "17px",
		fontSize: "16px",
		width: "100%",
		boxSizing: "border-box",
	},
	smallText: {
		marginTop: theme.spacing(1),
		color: "#888",
		fontSize: "12px",
	},
	SelectionService: {
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "#d9d9d9",
			borderRadius: "20px",
			padding: "10px",
		},
	},
	SelectedService: {
		cursor: "pointer",
		backgroundColor: "#d9d9d9",
		borderRadius: "20px",
		padding: "10px",
	},
	biometryStatus: {
		textAlign: "center",
		fontSize: "1em",
		border: "1px solid #393762",
		padding: "1rem",
		borderRadius: "10px",
		marginBottom: "1rem",
		cursor: "pointer",
		"&.biometry-success": {
			borderColor: "#008000",
			backgroundColor: "rgba(0, 128, 0, 0.05)",
			color: "#008000",
		},
		"&.biometry-error": {
			borderColor: "#FF0000",
			backgroundColor: "rgba(255, 0, 0, 0.05)",
			color: "#FF0000",
		},
		"&.biometry-warning": {
			borderColor: "#FFA500",
			backgroundColor: "rgba(255, 165, 0, 0.05)",
			color: "#FFA500",
		},
		"&.biometry-info": {
			borderColor: "#0000FF",
			backgroundColor: "rgba(0, 0, 255, 0.05)",
			color: "#0000FF",
		},
	},
}));

export default useStyles;
