import React from "react";

import BusinessProfile from "containers/businessProfile";
import ListClients from "containers/listClients";
import OpeningHours from "containers/OpeningHours";
import Professionals from "containers/professionals";
import Services from "containers/services";
import WithdrawBalance from "containers/withdrawBalance";
import { PritPayProvider } from "context/pritpay.context";
import { PaymentProvider } from "context/payment.context";
import PritPayView from "views/pritPay";
import ReportsPage from "views/reports";

import SalesHistory from "../../salesHistory";
import SchedulePlanPages from "./schedule-plan";

export default function SchedulePages({ type }) {
	if (type === "businessProfile") return <BusinessProfile />;
	if (type === "openingHours") return <OpeningHours />;
	if (type === "listClients") return <ListClients />;
	if (type === "services") return <Services />;
	if (type === "salesHistory") return <SalesHistory />;
	if (type === "reports") return <ReportsPage inside={true} />;
	if (type === "profilePritPlan")
		return (
			<PaymentProvider>
				<SchedulePlanPages />
			</PaymentProvider>
		);
	if (type === "professionals") return <Professionals />;
	if (type === "pritPay") {
		return (
			<PritPayProvider>
				<PritPayView />
			</PritPayProvider>
		);
	}
	if (type === "withdrawBalance") return <WithdrawBalance />;

	return null;
}
