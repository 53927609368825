import { Close } from "@material-ui/icons";
import { Dialog as DialogDefault } from "@mui/material";
import React from "react";

import { Button, Container, Text } from "components";

const Dialog = ({
	isOpen,
	handleFirstBtn,
	handleSecondBtn,
	title,
	lblBtnFirst,
	lblBtnSecond,
	content,
	noHeight = true,
	fullScreen = true,
}) => {
	const handleConfirmLogout = () => {
		handleFirstBtn();
	};

	return (
		<DialogDefault
			onClose={handleSecondBtn}
			open={isOpen}
			fullScreen={fullScreen}
			PaperProps={{
				style: { backgroundColor: "transparent" },
			}}>
			<Container style={{ gap: "16px" }} transparent noHeight={noHeight}>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
					<Text style={{ color: "#616161" }}>{title}</Text>
					<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={() => handleSecondBtn()}></Close>
				</div>

				{content && (
					<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%", padding: "1rem 0" }}>
						<Text style={{ color: "#616161" }}>{content}</Text>
					</div>
				)}

				<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
					<Button
						type="button"
						variant="contained"
						className="btn-color-primary btn-default"
						title={lblBtnFirst ?? "Sim"}
						handleClick={() => handleConfirmLogout()}
						fullWidth
					/>

					{lblBtnSecond && (
						<Button
							type="button"
							variant="outlined"
							className="btn-outlined-secondary btn-default"
							title={lblBtnSecond ?? "Não"}
							handleClick={() => handleSecondBtn()}
							fullWidth
						/>
					)}
				</div>
			</Container>
		</DialogDefault>
	);
};

export default Dialog;
