import { Button } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";

import { moneyMask, treatDate, treatTime } from "utils/utils";

import Text from "../texts";

import "./index.scss";

const BookingCard = ({ booking, edit = false, onClick = () => {} }) => {
	const { business } = useSelector((store) => store.business);

	return (
		<Button
			type="button"
			variant="text"
			endIcon={edit ? <ArrowForwardIos /> : null}
			className="bookingCard"
			onClick={onClick}>
			<div className="bookingBoxDate" style={{ backgroundColor: booking?.repeat ? "#fff" : "#3688EB" }}>
				<Text variant="body1" style={{ color: booking?.repeat ? "#666" : "#fff" }}>
					{treatDate(booking?.date)}
				</Text>
				<Text variant="body1" style={{ color: booking?.repeat ? "#666" : "#fff" }}>
					{`${treatTime(booking?.start_time)} - ${treatTime(booking?.end_time)}`}
				</Text>
			</div>

			<div className="bookingBoxService">
				<Text variant="body1">{booking?.service?.service?.name}</Text>
				{booking?.professional && (
					<Text className="grayText" variant="body1">
						Com {booking?.professional?.name}
					</Text>
				)}
			</div>

			<div className="bookingBoxPrice">
				<Text className="grayText" variant="body1">
					{booking?.service?.duration} min
				</Text>
				<Text variant="body1">{moneyMask(booking?.service?.price, business?.currency)}</Text>
			</div>
		</Button>
	);
};

export default BookingCard;
