import React from "react";
import { FormLabel, MenuItem, Select } from "@material-ui/core";

const InputSelect = ({ label, value, options = [], variant = "outlined", handleChange, onBlur, style, className }) => {
	return (
		<>
			{label && <FormLabel className="input-label">{label}</FormLabel>}
			<Select
				value={value}
				onChange={handleChange}
				onBlur={onBlur}
				className={["input-select", className]}
				style={style}
				variant={variant}>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</>
	);
};

export default InputSelect;
