import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import React from "react";

import { Text } from "components";
import { useBusiness } from "context/business.context";

import useStyles from "../styles";

const MenuItem = ({ title, icon, action, hasSubMenu = false, page }) => {
	const { openMenu } = useBusiness();
	const classes = useStyles();

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				padding: "0.4rem",
				width: "95%",
			}}
			onClick={action}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Text style={{ marginTop: "0.2rem" }}>{icon}</Text>
				<Text
					style={{
						fontSize: "16px",
						color: "darkgray",
						textAlign: "left",
					}}>
					{title}
				</Text>
			</div>
			{hasSubMenu && <ExpandMoreIcon className={clsx(classes.submenuIcon, { opened: openMenu === page })} />}
		</div>
	);
};

export default MenuItem;
