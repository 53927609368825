export const getSectors = {
  "cb91c954-75f8-41e7-83e8-6a538275ceeb": 'Beleza e Estetica',
  "fd587bee-1704-47dc-886c-b89f79b09f40": 'Saúde',
  "7a1b72d2-c6a3-4735-aec6-53848362d7c5": 'Pet',
  "93966f1a-0ba4-45bf-b594-4d3ba68f7b81": 'Auto',
  "74ba0558-bc6b-4108-8632-35d5a2d188e1": 'Educação',
  "525188e5-1c86-43e0-8683-771b6fe9fa39": 'Gourmet',
  "0bb74848-82bb-4519-afd0-bb647cb943ed": 'Outros',
  "918849a5-d341-48de-aa0a-04c412d77a82": 'Negócios',
  "37aafdd3-5a2e-4942-a2b8-662d02fa3647": 'Residência',
  "1c536e11-5f92-4f83-983f-762a8180758f": 'Esportes e Lazer',
  "ab67c954-75f8-41e7-83e8-6a538275caac": 'Business',
};

export const getCountryName = (b) => {
  if (b?.phone.startsWith('+1')) {
    return 'Estados Unidos';
  } else if (b?.phone?.startsWith('+31')) {
    return 'Holanda';
  } else if (b?.phone?.startsWith('+33')) {
    return 'França';
  } else if (b?.phone?.startsWith('+34')) {
    return 'Espanha';
  } else if (b?.phone?.startsWith('+39')) {
    return 'Itália';
  } else if (b?.phone?.startsWith('+44')) {
    return 'Reino Unido';
  } else if (b?.phone?.startsWith('+51')) {
    return 'Peru';
  } else if (b?.phone?.startsWith('+52')) {
    return 'México';
  } else if (b?.phone?.startsWith('+54')) {
    return 'Argentina';
  } else if (b?.phone?.startsWith('+56')) {
    return 'Chile';
  } else if (b?.phone?.startsWith('+57')) {
    return 'Colômbia';
  } else if (b?.phone?.startsWith('+244')) {
    return 'Angola';
  } else if (b?.phone?.startsWith('+245')) {
    return 'Guiné-Bissau';
  } else if (b?.phone?.startsWith('+351')) {
    return 'Portugal';
  } else {
    return 'Brasil';
  }
};
