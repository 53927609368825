import mixpanel from "mixpanel-browser";
import _ from 'lodash';
import api from '../services/api';
import { getAppEventName } from './validators';
import { getSectors, getCountryName } from './info';

const mixpanelTokenOld =  process.env.REACT_APP_MIXPANEL_TOKEN;
const mixpanelTokenNew =  process.env.REACT_APP_MIXPANEL_TOKEN_NEW;

export const mixpanelInitialize = () => {
  try {
    mixpanel.init(mixpanelTokenOld, { debug: true }, 'old');
    mixpanel.init(mixpanelTokenNew, { debug: true }, 'new');
  } catch (err) {
    console.log(err);
  }
};

const getOS = () => {
  const userAgent = navigator.userAgent;
  if (/Windows NT/.test(userAgent)) {
    return "windows";
  } else if (/Mac OS X|Macintosh/.test(userAgent)) {
    return "ios";
  } else if (/Android/.test(userAgent)) {
    return "android";
  } else if (/Linux/.test(userAgent)) {
    return "linux";
  } else if (/iPhone|iPad/.test(userAgent)) {
    return "ios";
  } else if (/CrOS/.test(userAgent)) {
    return "chrome";
  }
  return "Sistema operacional desconhecido";
};

// chamada api para armazenar os eventos
const apiCallEvents = (page = '', user = {}, business = {}) => {
  try {
    api.post('/panel_event/',{
      message: page, business_id: business?.id || '', user_id: user?.id || '',
    });
  } catch (err) {
    console.log(err);
  }
};

// projeto velho e novo setar props do user e no registro de eventos
export const identifyUser = (business = {}, user = {}, event = '', professionals = []) => {
  if (!_.isEmpty(user)) {
    try {
      mixpanel.old.identify(user.phone || user?.phoneUnverified || '');
      mixpanel.old.people.unset("$So");
      mixpanel.old.people.unset("$SO");
      mixpanel.old.people.unset("$so");
      mixpanel.old.people.unset("So");
      mixpanel.old.people.unset("so");
      mixpanel.old.people.set({
        "$name": user?.name || 'Sem nome',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneUnverified || 'Sem telefone',
        "SO": getOS(),
        "$Último acesso": new Date().toString(),
        "$Admin": isOwner,
        '$Modelo do Celular': getOS(),
        '$Cadastrou empresa': _.isEmpty(business) ? 'não': 'sim',
        "$Nome da Empresa": business?.name || 'Empresa sem nome',
        "$Categoria": business?.categories?.map(item => item.name).join(', ') || 'Sem categoria',
        "$Setor": getSectors[business?.sectorId] || 'Sem setor',
        "$businessId": business?.id || 'Sem business',
      });
      mixpanel.old.register({
        "$name": user?.name || 'Sem nome',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneUnverified || 'Sem  telefone',
        "SO": getOS(),
        "$Último acesso": new Date().toString(),
        "$Admin": isOwner,
        '$Modelo do Celular': getOS(),
        '$Cadastrou empresa': _.isEmpty(business) ? 'não': 'sim',
        "$Nome da Empresa": business?.name || 'Empresa sem nome',
        "$Categoria": business?.categories?.map(item => item.name).join(', ') || 'Sem categoria',
        "$Setor": getSectors[business?.sectorId] || 'Sem setor',
        "$businessId": business?.id || 'Sem business',
      });
  
      mixpanel.new.identify(`u-${user?.id || ''}`);
      const isOwner = !!(business?.ownerId === user.id);
      const isProfessional = !!(professionals.find(i => i.phone === user.phone));
      mixpanel.new.people.unset("$So");
      mixpanel.new.people.unset("$SO");
      mixpanel.new.people.unset("$so");
      mixpanel.new.people.unset("So");
      mixpanel.new.people.unset("so");
      mixpanel.new.people.set({
        "$name": user?.name || 'Sem nome',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneUnverified || 'Sem telefone',
        "$userEntity": true,
        "$owner": isOwner,
        "$professional": isProfessional,
        "$businessId": business?.id || 'Sem business',
        "SO": getOS(),
        "$Último acesso": new Date().toString(),
        "$Baixou qual app": 'Prit',
        "$Admin": isOwner,
        '$Modelo do Celular': getOS(),
        '$Cadastrou empresa': _.isEmpty(business) ? 'não': 'sim',
      });
      mixpanel.new.register({
        "$name": user?.name || 'Sem nome',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneUnverified || 'Sem telefone',
        "$userEntity": true,
        "$owner": isOwner,
        "$professional": isProfessional,
        "$businessId": business?.id || 'Sem business',
        "SO": getOS(),
        "$Último acesso": new Date().toString(),
        "$Baixou qual app": 'Prit',
        "$Admin": isOwner,
        '$Modelo do Celular': getOS(),
        '$Cadastrou empresa': _.isEmpty(business) ? 'não': 'sim',
      });
      if (!_.isEmpty(event)) {
        mixpanel.new.track(`${event} - ${getAppEventName()}`);
        mixpanel.old.track(`${event} - ${getAppEventName()}`);
        apiCallEvents(`${event} - ${getAppEventName()}`, user, business);
      }
    } catch (err) {
      console.log(err);
    }
  }
};

// projeto novo setar props do business e no registro de eventos
export const identifyBusiness = (business = {}, user = {}, event = '') => {
  if (!_.isEmpty(business)) {
    try {
      mixpanel.new.identify(`b-${business?.id || ''}`);
      if (!_.isEmpty(user) && business?.ownerId === user?.id) {
        mixpanel.new.people.unset("$So");
        mixpanel.new.people.unset("$SO");
        mixpanel.new.people.unset("$so");
        mixpanel.new.people.unset("So");
        mixpanel.new.people.unset("so");
        mixpanel.new.people.set({
          "$name": business?.name || 'Sem nome business',
          "$email": user?.email || 'Sem email',
          "$phone": user?.phone || user?.phoneUnverified || 'Sem telefone',
          "$businessId": business?.id || '',
          "$Nome da Empresa": business?.name || 'Empresa sem nome',
          "$Categoria": business?.categories?.map(item => item.name).join(', ') || 'Sem categoria',
          "$Setor": getSectors[business?.sectorId] || 'Beleza e Estetica',
          '$Modelo do Celular': getOS(),
          "SO": getOS(),
          "$Admin": true,
          "$Último acesso": new Date().toString(),
          "$businessEntity": true,
          "$País": getCountryName(business),
        });
      } 
      mixpanel.new.register({
        "$name": business?.name || 'Sem nome business',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneUnverified || 'Sem telefone',
        "$businessId": business?.id || '',      
        "$Nome da Empresa": business?.name || 'Empresa sem nome',
        "$Categoria": business?.categories?.map(item => item?.name).join(', ') || 'Sem categoria',
        "$Setor": getSectors[business?.sectorId] || 'Beleza e Estetica',
        '$Modelo do Celular': getOS(),
        "SO": getOS(),
        "$Admin": true,
        "$Último acesso": new Date().toString(),
        "$Baixou qual app": 'Prit',
        "$businessEntity": true,
        "$País": getCountryName(business),
      });
      if (!_.isEmpty(event)) {
        mixpanel.new.track(`${event} - ${getAppEventName()}`);
        apiCallEvents(`${event} - ${getAppEventName()}`, user, business);
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const mixpanelTrackEvent = (event = '', sendUser = {}, sendBusiness = {}, sendApi = false) => {
  try {
    // projeto antigo
    mixpanel.old.track(`${event} - ${getAppEventName()}`);

    // projeto novo user
    if (!_.isEmpty(sendUser)) {
      mixpanel.new.identify(`u-${sendUser?.id || ''}`);
      mixpanel.new.track(`${event} - ${getAppEventName()}`);
    }

    // projeto novo business
    if (!_.isEmpty(sendBusiness)) {
      mixpanel.new.identify(`b-${sendBusiness?.id || ''}`);
      mixpanel.new.track(`${event} - ${getAppEventName()}`);
    }

    // projeto novo etapa sem usuario ou business
    if (_.isEmpty(sendUser) && _.isEmpty(sendBusiness)) {
      mixpanel.new.track(`${event} - ${getAppEventName()}`);
    }

    // projeto novo chamada nossa api
    if (sendApi) {
      apiCallEvents(`${event} - ${getAppEventName()}`, sendUser || {}, sendBusiness || {});
    }
  } catch (err) {
    console.log(err);
  }
};