import api from "./api";

const getBookingTransaction = (bookingTransactionId) => {
	return api.get("booking_transaction/" + bookingTransactionId);
};

const postBookingTransaction = (body) => {
	return api.post("booking_transaction/transactions", body);
};

const getAllBookingTransactionList = (data, config) => {
	return api.get(`booking_transaction/list`, data);
};

const bookingTransactionsByClient = (clientId, config) => {
	return api.get(`booking_transaction?client_id[]=${clientId}&status[]=active`,	
	config);
};

export const getBookingTransactionFindById = ( id, config) => {
	return api.get(`booking_transaction/${id}`, config);
};

const putBookingTransaction = (body) => {
	return api.patch("booking_transaction/" + body.id, body);
};

const postScheduleBreak = (body) => {
	return api.post("schedule_break", body);
};

const deleteScheduleBreak = (breakID) => {
	return api.delete(`schedule_break/${breakID}`);
};

const bookingTransactionService = {
	getBookingTransaction,
	postBookingTransaction,
	putBookingTransaction,
	postScheduleBreak,
	deleteScheduleBreak,
	getAllBookingTransactionList
};

export default bookingTransactionService;
